import React, { createContext, useContext, useMemo } from 'react';

const LookupContext = createContext(false);

export const useDisabledLookupContext = () => useContext(LookupContext);

const DisabledLookupContextProvider = ({ children }) => {
  const disabled = useMemo(
    () =>
      ['dubai', 'assets', 'dubaiCRMConfig'].includes(
        process.env.REACT_APP_CONFIG
      ),
    []
  );
  return (
    <LookupContext.Provider value={disabled}>{children}</LookupContext.Provider>
  );
};

export default DisabledLookupContextProvider;
