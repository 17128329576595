import React, { useRef } from "react";
import {
  AutocompleteComponent,
  DatePickerComponent,
  PermissionsComponent,
  SelectComponet,
  SwitchComponent,
} from "../../../../../../../Components";
import { useTranslation } from "react-i18next";
import { UsersManagementPermissions } from "../../../../../../../Permissions";
import { getErrorByName } from "../../../../../../../Helper";
import { UserOperationTypeEnum } from "../../../../../../../Enums";
import moment from "moment";

export const DialogBottomFields = ({
  translationPath,
  parentTranslationPath,
  setSelected,
  selected,
  data,
  schema,
  isSubmitted,
  functions,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  return (
    <>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Line-manager*`)}
          </span>
        </div>
        <div className="w-75 mt-2 mb-1 pt-1">
          <AutocompleteComponent
            idRef="LineManagerRef"
            inputPlaceholder={t(`${translationPath}select`)}
            wrapperClasses="w-100 mb-2"
            selectedValues={selected.reportsTo}
            data={data.systemUsers || []}
            displayLabel={(option) =>
              (option && option.fullName) || (option && option.firstName) || ""
            }
            multiple={false}
            withoutSearchButton
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, "reportsTo").message}
            error={getErrorByName(schema, "reportsTo").error}
            onOpen={() => {
              if (data.systemUsers && data.systemUsers.length == 0)
                functions.getSystemUsers();
            }}
            onChange={(event, newValue) => {
              setSelected({ id: "reportsTo", value: newValue });
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                functions.getSystemUsers(value);
              }, 1200);
            }}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}reraExpiryDate`)}
          </span>
        </div>
        <div className="w-75 mt-2 mb-1 pt-1">
          <DatePickerComponent
            idRef="reraExpiryDateRef"
            placeholder="DD/MM/YYYY"
            value={selected.reraExpiryDate || null}
            onDateChanged={(newValue) => {
              setSelected({
                id: "reraExpiryDate",
                value:
                  (newValue &&
                    moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                  null,
              });
            }}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Operation-type`)}
          </span>
        </div>
        <div className="w-75 mt-2 mb-1 pt-1">
          <AutocompleteComponent
            idRef="OperationTypeRef"
            inputPlaceholder={t(`${translationPath}select`)}
            wrapperClasses="w-100 mb-2"
            selectedValues={selected.operationType}
            data={Object.values(UserOperationTypeEnum)}
            displayLabel={(option) => option.title || ""}
            multiple={false}
            withoutSearchButton
            onChange={(_, newValue) => {
              setSelected({ id: "operationType", value: newValue });
            }}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}unitSaleType`)}
          </span>
        </div>
        <div className="w-75 mt-2 mb-1">
          <AutocompleteComponent
            idRef="unitSaleTypeRef"
            inputPlaceholder={t(`${translationPath}select`)}
            wrapperClasses="w-100 mb-2"
            selectedValues={selected.unitSaleType}
            data={data.unitSaleTypes || []}
            displayLabel={(option) => option.lookupItemName || ""}
            multiple={false}
            withoutSearchButton
            onOpen={() => {
              if (data.unitSaleTypes && data.unitSaleTypes.length == 0)
                functions.getUnitSaleTypes();
            }}
            onChange={(_, newValue) => {
              setSelected({ id: "unitSaleType", value: newValue });
            }}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Specialist-for`)}
          </span>
        </div>
        <div className="w-75 mt-2 mb-1 pt-1">
          <AutocompleteComponent
            idRef="developer-specialist-forRef"
            wrapperClasses="w-min-unset mb-2"
            selectedValues={selected.userDevelopers || []}
            labelValue={t(`${translationPath}developer`)}
            multiple
            data={data.developers || []}
            displayLabel={(option) => option?.developerName || ""}
            renderOption={(option) => option?.developerName || ""}
            chipsLabel={(option) => option?.developerName || ""}
            onOpen={() => {
              if (data.developers && data.developers.length == 0)
                functions.getAllDevelopers();
            }}
            onChange={(_, newValues) => {
              setSelected({ id: "userDevelopers", value: newValues });
            }}
            // onInputChange={(e) => {
            //   const value = e?.target?.value;
            //   if (searchTimer.current) clearTimeout(searchTimer.current);
            //   searchTimer.current = setTimeout(() => {
            //     functions.getAllDevelopers(value);
            //   }, 700);
            // }}
            onInputKeyUp={(e) => {
              const value = e?.target?.value;
              if (value) {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  functions.getAllDevelopers(value);
                }, 700);
              }
            }}
            withoutSearchButton
            parentTranslationPath={parentTranslationPath}
                  filterOptions={(options) => {
                    const isFind = (id) =>
                      selected.userDevelopers?.findIndex(
                        (w) => w.developerId === id
                      ) === -1 || false;
                    return options.filter((w) => isFind(w.developerId));
                  }}
                  getOptionSelected={(option) =>
                    (selected &&
                      selected.userDevelopers &&
                      selected.userDevelopers.findIndex(
                        (item) => item?.developerId === option?.developerId
                      ) !== -1) ||
                    false
                  }
                  filterSelectedOptions
          />
          <AutocompleteComponent
            idRef="property-specialist-forRef"
            wrapperClasses="w-min-unset mb-2"
            selectedValues={selected.userProperties}
            labelValue={t(`${translationPath}property`)}
            getOptionSelected={(option, value) =>
              option?.propertyId === value.propertyId}
            multiple
            data={data.properties || []}
            displayLabel={(option) => 
             
               option?.property?.property_name || option.name || ""
            
            }
            chipsLabel={option => option?.property?.property_name || option.name || ""}
            onOpen={() => {
              if (data.properties && data.properties.length == 0)
                functions.getAllProperties();
            }}
            onChange={(_, newValue) => {
              setSelected({
                id: 'userProperties',
                value: newValue?.map((property) => ({
                  propertyId: property.propertyId,
                  name: property.property.property_name,
                })),
              });
            }}
            onInputChange={(e) => {
              const value = e?.target?.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                functions.getAllProperties(value);
              }, 700);
            }}
            withoutSearchButton
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      </div>
      <PermissionsComponent
        permissionsList={Object.values(UsersManagementPermissions)}
        permissionsId={[UsersManagementPermissions.EditGroup.permissionsId]}
      >
        <div className="d-flex fj-start b-bottom">
          <div className="w-33 mt-2 pt-1">
            <span className="fw-simi-bold">{t(`${translationPath}group`)}</span>
          </div>
          <div className="w-75 mt-2 mb-1 pt-1">
            <AutocompleteComponent
              idRef="userGroupRef"
              inputPlaceholder={t(`${translationPath}select`)}
              selectedValues={selected.userGroup || []}
              wrapperClasses="w-100 mb-2"
              data={data.userGroups || []}
              displayLabel={(option) => t(`${option.name || ""}`)}
              withoutSearchButton
              multiple={false}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelected({
                    id: "edit",
                    value: {
                      ...selected,
                      userGroup: newValue,
                      policy: {
                        policyDataId: newValue.policyId,
                        policyDataName: newValue.policyName,
                      },
                      roles: newValue.userGroupRoles || [],
                    },
                  });
                } else {
                  setSelected({
                    id: "edit",
                    value: {
                      ...selected,
                      userGroup: null,
                      policy: [],
                      roles: null,
                    },
                  });
                }
              }}
              onOpen={() => {
                if (data.userGroups && data.userGroups.length == 0)
                  functions.getUserGroups();
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  functions.getUserGroups(value);
                }, 1200);
              }}
            />
          </div>
        </div>
      </PermissionsComponent>
      <PermissionsComponent
        permissionsList={Object.values(UsersManagementPermissions)}
        permissionsId={[UsersManagementPermissions.EditRoles.permissionsId]}
      >
        <div className="d-flex fj-start b-bottom">
          <div className="w-33 mt-2 pt-1">
            <span className="fw-simi-bold">{t(`${translationPath}roles`)}</span>
          </div>
          <div className="w-75 mt-2 mb-1 pt-1">
            <AutocompleteComponent
              idRef="rolesRef"
              inputPlaceholder={t(`${translationPath}select`)}
              selectedValues={selected.roles || []}
              wrapperClasses="w-100 mb-2"
              data={data.systemRoles || []}
              chipsLabel={(option) => option.rolesName || ""}
              displayLabel={(option) => t(`${option.rolesName || ""}`)}
              withoutSearchButton
              multiple
              getOptionSelected={(option) =>
                selected?.roles?.findIndex(
                  (item) => item.rolesId === option.rolesId
                ) !== -1 || ""
              }
              onChange={(event, newValue) => {
                setSelected({
                  id: "edit",
                  value: {
                    ...selected,
                    roles: newValue,
                    userGroup: null,
                  },
                });
              }}
              onOpen={() => {
                if (data.systemRoles && data.systemRoles.length == 0)
                  functions.getSystemRoles();
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  functions.getSystemRoles(value);
                }, 1200);
              }}
            />
          </div>
        </div>
      </PermissionsComponent>
      <PermissionsComponent
        permissionsList={Object.values(UsersManagementPermissions)}
        permissionsId={[UsersManagementPermissions.EditPolicy.permissionsId]}
      >
        <div className="d-flex fj-start b-bottom">
          <div className="w-33 mt-2 pt-1">
            <span className="fw-simi-bold">
              {t(`${translationPath}policy`)}
            </span>
          </div>
          <div className="w-75 mt-2 mb-1 pt-1">
            <AutocompleteComponent
              idRef="policysRef"
              inputPlaceholder={t(`${translationPath}select`)}
              selectedValues={selected.policy}
              wrapperClasses="w-100 mb-2"
              data={data.systemPolicies || []}
              displayLabel={(option) => option?.policyDataName || ""}
              withoutSearchButton
              multiple={false}
              onChange={(event, newValue) => {
                setSelected({
                  id: "edit",
                  value: {
                    ...selected,
                    policy: newValue,
                    userGroup: null,
                  },
                });
              }}
              onOpen={() => {
                if (data.systemPolicies && data.systemPolicies.length == 0)
                  functions.getSystemPolicies();
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  functions.getSystemPolicies(value);
                }, 1200);
              }}
            />
          </div>
        </div>
      </PermissionsComponent>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}required-login`)}
          </span>
        </div>
        <div className="w-75 mt-2 mb-1 pt-1">
          <div>
            <SwitchComponent
              idRef="CRMRef"
              themeClass="thick-theme"
              isChecked={selected.crmLogin || false}
              labelValue="CRM"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChangeHandler={(event, isChecked) => {
                setSelected({ id: "crmLogin", value: isChecked });
              }}
            />
          </div>
          <div>
            <SwitchComponent
              idRef="MyleadsRef"
              themeClass="thick-theme"
              isChecked={selected.myLeadsLogin || false}
              labelValue="Myleads"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChangeHandler={(event, isChecked) => {
                setSelected({ id: "myLeadsLogin", value: isChecked });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
