import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { FBTextField, FBTextFieldLabelPosition, PageTitle } from "../../../../Components";
import MultiStepForm from "./MultiStepForm";
import { useTranslate } from "../../../../Hooks";

// Styles
import useStyles from "./styles";

function AddIndividualForm() {
  const styles = useStyles();

  const [value, setValue] = useState("");

  const { translate } = useTranslate("NewContactsView");

  return (
    <Box>
      <PageTitle
        title={translate("add-individual-contact-page-title")}
        subTitle={translate("add-individual-contact-page-subtitle")}
        hideDivider
      />
      
      <MultiStepForm />

      <hr />

      <FBTextField
        label="First name"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Enter your name"
        required
        labelPosition={FBTextFieldLabelPosition.SIDE}
        helperText="helper text for the input field"
        fieldCustomClasses={styles.fieldCustomClasses}
      />

      <hr />

      <FBTextField
        label="First name"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Enter your name"
        required
        labelPosition={FBTextFieldLabelPosition.SIDE}
        error
        helperText="This is an error message."
        fieldCustomClasses={styles.fieldCustomClasses}
      />
    </Box>
  );
}

export default AddIndividualForm;
