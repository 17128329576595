import React from 'react';
import Styles from './MiddleSectionDuplicated.module.scss';
import LocationIcon from './Icons/LocationIcon';
import UnitSizeIcon from '../../../../../Contexts/UnitValidationContext/Icons/UnitSizeIcon';
import BedroomsIcon from '../../../../../Contexts/UnitValidationContext/Icons/BedroomIcon';
import BathroomsIcon from '../../../../../Contexts/UnitValidationContext/Icons/BathroomsIcon';

function MiddleSectionDuplicated({ data }) {
  return (
    <div className={Styles.Content_Card}>
      <div className={Styles.Header}>
        <p className={Styles.Header_Title}>Details</p>
      </div>

      <div className='d-flex-center gap-32'>
        <div className={Styles.Info_box}>
          <UnitSizeIcon
            width='32'
            height='32'
            fill={'#98A2B3'}
          />
          <span className={Styles.Text_Icon}>{`${data.size || '-'} sqft`}</span>
        </div>
        <div className={Styles.Info_box}>
          <BedroomsIcon
            width='32'
            height='32'
            fill={'#98A2B3'}
          />
          <span className={Styles.Text_Icon}>{`${
            data.bedrooms || '-'
          } Bedrooms`}</span>
        </div>
        <div className={Styles.Info_box}>
          <BathroomsIcon
            width='32'
            height='32'
            fill={'#98A2B3'}
          />
          <span className={Styles.Text_Icon}>{`${
            data.bathrooms || '-'
          } Bathrooms`}</span>
        </div>
      </div>
      <div className={Styles.Location_Wrapper}>
        <LocationIcon
          width='20'
          height='20'
          fill={'#344054'}
        />
        <span className={Styles.Location_Text}>
          {`${data.city ? `${data.city}` : ''}`}
          {`${data.community ? `, ${data.community}` : ''}`}
          {`${data.subCommunity ? `, ${data.subCommunity}` : ''}`}
        </span>
      </div>
      <div className={Styles.View_Type_Wrapper}>
        <p className={Styles.Header_Title}>View</p>

        <div className={Styles.Capsules_container}>
          {[...(data?.primaryView || []), data?.secondaryView || ''].map(
            (view, index) => (
              <div
                key={`view-${index}`}
                className={Styles.Outlined_capsule__gray}
              >
                <span>{view}</span>
              </div>
            )
          )}
        </div>
      </div>
      <div className={Styles.View_Type_Wrapper}>
        <p className={Styles.Header_Title}>What this place offers</p>
        <div className={Styles.Capsules_container}>
          {[
            ...(data?.amenities || []),
            ...(data?.fittingAndFixtures || []),
          ].map((view, index) => (
            <div
              key={`view-${index}`}
              className={Styles.Outlined_capsule__gray}
            >
              <span>{view}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MiddleSectionDuplicated;
