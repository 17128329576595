import React, { Children } from 'react';
import { CheckboxesComponent } from '../../../../../Components';
import { Link, Typography } from '@material-ui/core';

export function DeveloperTransactionStages(props) {
  return (
    <div className='d-flex px-5'>
      <ul className='stages-breadcrumb v1'>
        {Children.toArray(
          props.stages.map((stage, i) => (
            <li
              className={`breadcrumb-level unit-stage 
  
                    ${i < props.activeStageIndex ? 'completed' : ''}
                    
                    `}
              onMouseEnter={() => props.setVisibleList(stage.name)}
              onMouseLeave={() => props.setVisibleList('')}
            >
              <a
                onClick={(e) => e.preventDefault()}
                className={`${props.activeStageIndex === i ? 'active' : ''} ${
                  !stage.isContainMissingItems ? '' : ''
                } unit-stage`}
              >
                {stage.name}
              </a>

              {props.visibleList === stage.name &&
                stage.tableDeveloperTransactionStepsItems?.length > 0 && (
                  <div className='absolute-list'>
                    <ul>
                      {Children.toArray(
                        stage.tableDeveloperTransactionStepsItems?.map(
                          (item) => {
                            const {
                              isForContact,
                              isExist,
                              itemName,
                              isRequiredActivityCreation,
                              documentTypeId,
                            } = item;
                            const tab = !documentTypeId
                              ? 'activities'
                              : 'documents';
                            return (
                              <li>
                                <CheckboxesComponent
                                  singleChecked={isExist}
                                  label={
                                    isExist ? (
                                      <Typography variant='caption'>
                                        {isForContact
                                          ? itemName + ' (for contact)'
                                          : itemName}
                                      </Typography>
                                    ) : (
                                      <Link
                                        className='px-0'
                                        target='_blank'
                                        href={
                                          isForContact
                                            ? `/home/Contacts-CRM/contact-profile-edit?formType=1&id=${props.state.contacts?.[0]?.contactId}&tab=${tab}`
                                            : `/home/units-sales/unit-profile-edit?formType=1&id=${props.state.unitId}&operationType=430&tab=${tab}`
                                        }
                                      >
                                        {isForContact
                                          ? itemName + ' (for contact)'
                                          : itemName}
                                      </Link>
                                    )
                                  }
                                  isDisabled
                                />
                              </li>
                            );
                          }
                        )
                      )}
                    </ul>
                  </div>
                )}
            </li>
          ))
        )}
        <li
          className={
            props.activeStageIndex !== -1
              ? 'finish-disabled breadcrumb-level'
              : 'breadcrumb-level pointer'
          }
        >
          <a
            onClick={(e) => {
              e.preventDefault();

              if (props.activeStageIndex !== -1) {
                return;
              }

              props.saveHandler();
            }}
            className={props.activeStageIndex === -1 ? 'active' : ''}
          >
            {props.t('finish')}
          </a>
        </li>
      </ul>
    </div>
  );
}
