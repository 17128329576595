import { config } from '../config';
import { HttpServices } from '../Helper';


export const GetAllSaleTransactionsWithAmlAPI = async (body) => {
  
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/Unit/GetAllSaleTransactionsWithAml`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllLeaseTransactionsWithAmlAPI = async (body) => {
  
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/Unit/GetAllLeaseTransactionsWithAml`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
