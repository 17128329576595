import React from "react";

const BedroomsIcon = ({ fill, ...restProps }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.673 4.041c-.76.129-1.514.569-1.975 1.15-.1.126-.262.391-.36.589-.331.67-.314.487-.329 3.66l-.013 2.82-.247.096c-1.028.398-1.791 1.285-2.006 2.332-.093.457-.092 3.158.001 3.475.272.914 1.063 1.517 1.992 1.517H3v.785c0 .908.028 1.044.259 1.276.429.428 1.097.313 1.394-.241.078-.146.087-.233.1-.99l.014-.83h14.466l.014.83c.013.757.022.844.1.99.297.554.965.669 1.394.241.231-.232.259-.368.259-1.276v-.785l.25-.001a2.066 2.066 0 0 0 2.009-1.541c.09-.348.089-3.006-.002-3.45-.215-1.047-.978-1.934-2.006-2.332l-.247-.096-.013-2.82c-.015-3.172.002-2.99-.329-3.66-.401-.812-1.09-1.392-1.983-1.667l-.299-.092-6.26-.006c-3.443-.004-6.344.008-6.447.026m12.675 1.805c.32.12.686.486.806.806.085.228.086.263.086 2.85v2.618h-.56v-.302c0-1.313-.854-2.518-2.11-2.977-.468-.17-.883-.213-1.884-.191-.709.015-.921.034-1.172.102a3.342 3.342 0 0 0-1.346.743l-.171.158-.169-.16a2.57 2.57 0 0 0-.73-.507c-.534-.267-.774-.313-1.761-.335-1.027-.024-1.431.018-1.922.195-1.237.448-2.095 1.665-2.095 2.972v.302h-.56V9.502c0-2.587.001-2.622.086-2.85.117-.312.486-.685.794-.803l.22-.085 6.128-.002 6.129-.002.231.086m-8.06 4.681c.289.135.547.392.693.691.09.183.118.305.131.568l.018.334H7.07l.018-.35c.032-.624.323-1.034.908-1.277.215-.09.259-.093 1.144-.083.903.01.924.012 1.148.117m5.797-.002c.305.144.517.345.677.644.116.217.136.299.151.601l.017.35h-4.05v-.254c0-.627.336-1.132.913-1.372.219-.091.259-.094 1.147-.084.9.01.925.012 1.145.115m4.684 3.506c.295.146.527.379.677.68.093.186.094.21.105 1.632.012 1.43.011 1.443-.074 1.52-.084.076-.244.077-9.477.077-9.179 0-9.394-.002-9.476-.076-.082-.075-.084-.104-.084-1.46 0-1.201.009-1.409.068-1.574a1.52 1.52 0 0 1 .927-.888c.158-.049 1.404-.056 8.626-.049l8.441.007.267.131"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
};

export default BedroomsIcon;
