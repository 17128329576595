import React from 'react';
import { DialogComponent } from '../../../../Components';
import { DialogContentText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PaymentPlansLocalePath } from '../i18n/PaymentPlansLocale';

const DeleteConfirmationDialog = ({ onClose, isOpen, onConfirm }) => {
  const { t } = useTranslation(PaymentPlansLocalePath);
  const saveHandler = (e) => {
    e.preventDefault();
    onConfirm();
  };
  if (!isOpen) {
    return null;
  }
  return (
    <DialogComponent
      isOpen
      titleText={'delete_payment_plan'}
      dialogContent={
        <DialogContentText>{t('confirmation_text')}</DialogContentText>
      }
      maxWidth={'sm'}
      onCancelClicked={onClose}
      onCloseClicked={onClose}
      onSaveClicked={saveHandler}
      parentTranslationPath={PaymentPlansLocalePath}
      saveText={'confirm'}
    />
  );
};

export default DeleteConfirmationDialog;
