import React from "react";
import { PageTitle } from "../../../../Components";
import { Box } from "@material-ui/core";

// Styles
import useStyles from "./styles";

function AddCorporateForm() {
  const styles = useStyles();

  return (
    <Box>
      <PageTitle
        title={"Add new corporate contact"}
        subTitle={
          "Please fill in the form below to add a new corporate contact"
        }
        hideDivider
      />
    </Box>
  );
}

export default AddCorporateForm;
