import React, { useState } from "react";
import { Box, Button, Typography, TextField, makeStyles } from "@material-ui/core";
import { CustomStepper } from "../../../../Components";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Styles using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: "32px",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  stepLabel: {
    fontWeight: 600,
    fontSize: "14px",
    color: theme.palette.text.secondary,
    "&$activeLabel": {
      color: theme.palette.text.brand_secondary,
    },
    "&$completedLabel": {
      color: theme.palette.text.secondary, // completed color
    },
    textAlign: "center",
  },
  activeLabel: {},
  completedLabel: {},
}));

// Define the steps
const steps = ["Main Information", "Residence address", "Confirmation"];

// Validation schemas for each step
const validationSchemas = [
  Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
  }),
  Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
  }),
  Yup.object(), // No validation on confirmation step
];

// Initial form values
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

function MultiStepForm() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = async (values, actions) => {
    if (isLastStep) {
      // Simulate form submission
      await new Promise((resolve) => setTimeout(resolve, 1000));
      alert(JSON.stringify(values, null, 2));
      actions.setSubmitting(false);
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Field
              name="firstName"
              as={TextField}
              label="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Field
              name="lastName"
              as={TextField}
              label="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </>
        );
      case 1:
        return (
          <>
            <Field
              name="email"
              as={TextField}
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Field
              name="phone"
              as={TextField}
              label="Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </>
        );
      case 2:
        return (
          <Typography variant="h6">
            Review your information and submit.
          </Typography>
        );
      default:
        return <div>Not Found</div>;
    }
  };

  return (
    <Box className={classes.formContainer}>
      <CustomStepper
        activeStep={activeStep}
        steps={steps}
      />

      {activeStep === steps.length ? (
        <Box mt={5}>
          <Typography variant="h5" align="center">
            Thank you for submitting the form!
          </Typography>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemas[activeStep]}
          onSubmit={handleNext}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Box mt={3}>{renderStepContent(activeStep)}</Box>

              {/* Display validation errors */}
              {Object.keys(errors).length > 0 && (
                <Box mt={1}>
                  {Object.keys(errors).map(
                    (key) =>
                      touched[key] && (
                        <Typography
                          variant="body2"
                          className={classes.errorText}
                          key={key}
                        >
                          {errors[key]}
                        </Typography>
                      )
                  )}
                </Box>
              )}

              <Box className={classes.buttonContainer}>
                {activeStep > 0 && (
                  <Button
                    onClick={handleBack}
                    variant="contained"
                    color="default"
                  >
                    Back
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isLastStep ? "Submit" : "Next"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default MultiStepForm;
