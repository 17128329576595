export const PropertiesPermissionsCRM = {
  ViewAndSearchProperties: {
    permissionsId: "4a86f5c2-b8c1-4cc1-32ba-08d91a092746",
    permissionsName: "View and search properties",
    componentsId: null,
    components: null,
  },
  AddNewProperty: {
    permissionsId: "e12065cb-97a2-4205-32bb-08d91a092746",
    permissionsName: "Add new property",
    componentsId: null,
    components: null,
  },
  ImportProperty: {
    permissionsId: "fb4ed586-2b78-4811-32bc-08d91a092746",
    permissionsName: "Import property",
    componentsId: null,
    components: null,
  },
  ViewPropertyDetails: {
    permissionsId: "97ca4144-1a75-47a5-32bd-08d91a092746",
    permissionsName: "View  property details",
    componentsId: null,
    components: null,
  },
  EditPropertyDetails: {
    permissionsId: "f91a6413-f7ff-4c48-32be-08d91a092746",
    permissionsName: "Edit property details",
    componentsId: null,
    components: null,
  },
  ViewPropertyImages: {
    permissionsId: "5f225355-19b8-4926-32bf-08d91a092746",
    permissionsName: "View property images",
    componentsId: null,
    components: null,
  },
  ViewFinancialCompany: {
    permissionsId: "e4fd60b2-28f9-4475-32c0-08d91a092746",
    permissionsName: "View financial company",
    componentsId: null,
    components: null,
  },
  AddFinanceCompanyForPropertry: {
    permissionsId: "b88a9c32-face-4e24-32c1-08d91a092746",
    permissionsName: "Add finance company for propertry ",
    componentsId: null,
    components: null,
  },
  ViewPropertyMarketingInfo: {
    permissionsId: "dfb16f78-e9b7-4ded-32c2-08d91a092746",
    permissionsName: "View property marketing info",
    componentsId: null,
    components: null,
  },
  EditPropertyMarketingInfo: {
    permissionsId: "ac91a991-1a9b-43f6-32c3-08d91a092746",
    permissionsName: "Edit property marketing  info",
    componentsId: null,
    components: null,
  },
  ViewPropertySpecifications: {
    permissionsId: "127f406d-a6e4-496e-32c4-08d91a092746",
    permissionsName: "View property specifications",
    componentsId: null,
    components: null,
  },
  ViewPropertyMPI: {
    permissionsId: "127f406d-a6e4-496e-32c4-08d91a092746",
    permissionsName: "View property MPI",
    componentsId: null,
    components: null,
  },
  EditPropertySpecifications: {
    permissionsId: "c139b564-afe6-4a3f-32c5-08d91a092746",
    permissionsName: "Edit property specifications",
    componentsId: null,
    components: null,
  },
  ViewPropertyLocationonMap: {
    permissionsId: "4b5d20a2-d644-402a-32c6-08d91a092746",
    permissionsName: "View property location on map",
    componentsId: null,
    components: null,
  },
  ViewPropertyDocuments: {
    permissionsId: "a664d10b-bb87-4fb6-32c7-08d91a092746",
    permissionsName: "View property documents",
    componentsId: null,
    components: null,
  },
  ViewOperatingCostRecordsForProperty: {
    permissionsId: "2e95868a-73a8-408f-32c8-08d91a092746",
    permissionsName: "View operating cost records for property",
    componentsId: null,
    components: null,
  },
  AddOperatingCostForProperty: {
    permissionsId: "e65531ff-dde6-4964-32c9-08d91a092746",
    permissionsName: "Add operating cost for property",
    componentsId: null,
    components: null,
  },

  GetAllUnitsForProperty: {
    permissionsId: "8e4164e8-6cea-44f9-a887-08da6e2b177d",
    permissionsName: "Get All Units For Property",
    componentsId: null,
    components: null,
  },
  EditPropertyImageCategory: {
    components: null,
    componentsId: null,
    permissionsName: "Edit Property Image Category",
    permissionsId: "a8c817a4-2f2b-4f5c-1cd1-08dabfc53c80",
  },
  AddPropertyCampaign: {
    permissionsId: "7f166c61-44a1-4102-44f3-08db5f99228b",
    permissionsName: "Add Property Campaign",
    description: null,
    componentsId: null,
    components: null,
  },
  EditPropertyCampaign: {
    permissionsId: "02486754-d163-455d-44f4-08db5f99228b",
    permissionsName: "Edit Property Campaign",
    description: null,
    componentsId: null,
    components: null,
  },
  DeletePropertyCampaign: {
    permissionsId: "f9b9e260-372e-460d-44f5-08db5f99228b",
    permissionsName: "Delete Property Campaign",
    description: null,
    componentsId: null,
    components: null,
  },
  AddPropertyPaymentPlan: {
    permissionsId: "cc6b59e7-fffb-4eca-2e51-08db604be055",
    permissionsName: "Add Property Payment Plan",
    description: null,
    componentsId: null,
    components: null,
  },
  EditPropertyPaymentPlan: {
    permissionsId: "43e6022a-1f54-4435-2e52-08db604be055",
    permissionsName: "Edit Property Payment Plan",
    description: null,
    componentsId: null,
    components: null,
  },
  DeletePropertyPaymentPlan: {
    permissionsId: "f543d6de-e931-4fe4-2e53-08db604be055",
    permissionsName: "Delete Property Payment Plan",
    description: null,
    componentsId: null,
    components: null,
  },
  AddDocumentsForProperties: {
    permissionsId: "7c369d04-e49e-4d74-2e5d-08db604be055",
    permissionsName: "Add Documents For Properties",
    description: null,
    componentsId: null,
    components: null,
  },
  EditDocumentsForProperties: {
    permissionsId: "57badd38-b591-4b2a-2e5e-08db604be055",
    permissionsName: "Edit Documents For Properties",
    description: null,
    componentsId: null,
    components: null,
  },
  DeleteDocumentsFromProperties: {
    permissionsId: "2305d9c2-850f-4a2f-2e5f-08db604be055",
    permissionsName: "Delete Documents From Properties",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewUnitModel: {
    permissionsId: "1ac81a05-fba6-4756-2b1c-08dbca2fdea8",
    permissionsName: "View Unit Model",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewPropertyPaymentPlans: {
    permissionsId: "28f18b58-a6ae-4d70-703d-08dbf65badbd",
    permissionsName: "View Property Payment Plans",
    description: null,
    componentsId: null,
    components: null,
  },
  PublishedOnWeb: {
    permissionsId: "7021c1e8-a792-491d-7046-08dbf65badbd",
    permissionsName: "Published On Web",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewTasksForProperty: {
    permissionsId: "75a04e9e-db93-4f7d-19b2-08dc313da57c",
    permissionsName: "View Tasks For Property",
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewTask: {
    permissionsId: "7de2a99a-d815-4b7f-19b3-08dc313da57c",
    permissionsName: "Add New Task",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewPropertyVicinities: {
    permissionsId: "e0626446-2b2f-45be-f460-08dc3da1d8ed",
    permissionsName: "View Property Vicinities",
    description: null,
    componentsId: null,
    components: null,
  },
  AddPropertyVicinity: {
    permissionsId: "321a4afe-1116-4bb7-f461-08dc3da1d8ed",
    permissionsName: "Add Property Vicinity",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewPropertyMPI: {
    permissionsId: "40041fb6-ec55-49ef-f469-08dc3da1d8ed",
    permissionsName: "View Property MPI",
    description: null,
    componentsId: null,
    components: null,
  },
  SharePropertyDocuments: {
    permissionsId: "778f90c5-4162-439d-c4b0-08dc683b0c43",
    permissionsName: "Share Property Documents",
    description: null,
    componentsId: null,
    components: null,
  },
  PropertyDuplications: {
    permissionsId: "d938d3ab-fbee-45e1-c4b4-08dc683b0c43",
    permissionsName: "Property Duplications",
    description: null,
    componentsId: null,
    components: null,
  },
  BulkUpdateAction: {
    permissionsId: "9287d65b-50ce-4cff-c4cc-08dc683b0c43",
    permissionsName: "Bulk Update Action",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewAgentCoursesTab: {
    permissionsId: "c6210746-f676-48cb-0086-08dd03ecea2b",
    permissionsName: "View Agent Courses Tab",
    description: null,
    componentsId: null,
    components: null,
  },
};
