import React from "react";
import clsx from "clsx";
import { Step, StepLabel, Stepper, StepConnector } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useIsAr, useSelectedTheme } from "../../../Hooks";

// Icons
import { Tick } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function CustomStepper({ activeStep, steps }) {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<CustomConnector />}
      style={{ background: "transparent", padding: 0 }}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(props) => {
              const { active, completed } = props;
              
              return (
                <div
                  className={clsx(styles.root, {
                    [styles.active]: active,
                    [styles.completed]: completed,
                  })}
                >
                  {completed && (
                    <Tick
                      width="12"
                      height="10.5"
                      fill={palette.foreground.white}
                    />
                  )}
                </div>
              );
            }}
            classes={{
              label: styles.stepLabel,
              active: styles.activeLabel,
              completed: styles.completedLabel,
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default CustomStepper;

const CustomConnector = withStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    alternativeLabel: {
      top: 10,
      ...(isAr
        ? {
            right: "calc(-50%)",
            left: "calc(50%)",
          }
        : {
            left: "calc(-50%)",
            right: "calc(50%)",
          }),
    },
    active: {
      "& $line": {
        backgroundColor: theme.palette.foreground.brandPrimary, // active color
      },
    },
    completed: {
      "& $line": {
        backgroundColor: theme.palette.foreground.brandPrimary, // active color
      },
    },
    line: {
      height: 2,
      border: 0,
      backgroundColor: theme.palette.background.quarterary, // default color
      borderRadius: 0,
    },
  };
})(StepConnector);
