import React from "react";

function SpreadsheetIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.75 4C4.75 2.20508 6.20508 0.75 8 0.75H24C24.1212 0.75 24.2375 0.798159 24.3232 0.883885L35.1161 11.6768C35.2018 11.7625 35.25 11.8788 35.25 12V36C35.25 37.7949 33.7949 39.25 32 39.25H8C6.20507 39.25 4.75 37.7949 4.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M24 0.5V8C24 10.2091 25.7909 12 28 12H35.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M11.9 24.9H28.1M11.9 24.9V21.3C11.9 20.3059 12.7059 19.5 13.7 19.5H17.3M11.9 24.9V28.5C11.9 29.4941 12.7059 30.3 13.7 30.3H17.3M28.1 24.9V28.5C28.1 29.4941 27.2941 30.3 26.3 30.3H17.3M28.1 24.9V21.3C28.1 20.3059 27.2941 19.5 26.3 19.5H17.3M17.3 19.5V30.3"
        stroke="#079455"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export default SpreadsheetIcon;
