import { SalesTransactionsWithAMLView , SalesTransactionsWithAMLProfile } from '../../Views/Home';

export const SalesTransactionsWithAMLRoutes = [
  {
    path: '/view',
    name: 'SalesTransactionsView:sales-transactions-with-aml',
    component: SalesTransactionsWithAMLView,
    layout: '/home/sales-transactions-with-AML',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SalesTransactionsView:sales-transactions-with-aml',
        isDisabled: true,
        route: '/home/sales-transactions-with-AML/view',
        groupName: 'accounts',
      },
    ],
  },
  {
    path: '/transaction-profile',
    name: 'SalesTransactionsProfileView:transaction-profile',
    component: SalesTransactionsWithAMLProfile,
    layout: '/home/sales-transactions-with-AML',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SalesTransactionsView:sales-transactions-with-aml',
        isDisabled: false,
        route: '/home/sales-transactions-with-AML/view',
        groupName: 'accounts',
      },
      {
        name: 'SalesTransactionsProfileView:transaction-profile',
        isDisabled: true,
        route: '/home/sales-transactions-with-AML/transaction-profile',
        groupName: 'accounts',
      },
    ],
  },
];
