import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactFlow, {
  ReactFlowProvider,
  MiniMap,
  Controls,
  Background,
  addEdge,
  Handle,
} from "react-flow-renderer";
import "react-flow-renderer/dist/style.css";
import { GetSubActions, GetTrigerSubActions, GetTriggerDetails, GetTriggers, UpdateTrigger } from "../../../Services";
import CustomNode from "./WorkFlowUtilities/CustomNode";
import Sidebar from "./WorkFlowUtilities/Sidebar";
import CustomEdge from "./WorkFlowUtilities/CustomEdge";
import { GetParams, GlobalHistory, showSuccess } from "../../../Helper";
import { Button, ButtonBase } from "@material-ui/core";
import { Spinner } from "../../../Components";

export const WorkFlowView = () => {
  const parentTranslationPath = 'WorkFlow';
  const translationPath = '';

  const { t } = useTranslation(parentTranslationPath);
  const [TrigerSubActions, SetTrigerSubActions] = useState([]);
  const [Triggers, SetTriggers] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [idCount, setIdCount] = useState(2);
  const [TriggerDetails, SetTriggerDetails] = useState(null);

  const [subActions, setSubActions] = useState([]);
  const Params = +GetParams('trigerId')
  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  const handleDeleteNode = (nodeId) => {
    const findAllChildren = (nodeId, edges) => {
      let children = edges
        .filter((edge) => edge.source === nodeId)
        .map((edge) => edge.target);

      children.forEach((childId) => {
        children = [...children, ...findAllChildren(childId, edges)];
      });

      return children;
    };

    const childNodeIds = findAllChildren(nodeId, edges);

    setNodes((nds) =>
      nds.filter((node) => ![nodeId, ...childNodeIds].includes(node.id))
    );
    setEdges((eds) =>
      eds.filter(
        (edge) =>
          ![nodeId, ...childNodeIds].includes(edge.source) &&
          ![nodeId, ...childNodeIds].includes(edge.target)
      )
    );
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = event.target.getBoundingClientRect();
      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const triggerData = JSON.parse(event.dataTransfer.getData("trigger")); // Retrieve the trigger data

      const newNode = {
        id: `${idCount}`,
        type: "Action",
        position,
        data: {
          triggerData: triggerData,
          trigerId: triggerData.trigerId,
          label: triggerData.trigerName || "New Lead", // Use trigger data
          description:
            triggerData.description || "Triggers when Lead is created", // Use trigger description
        },
      };

      setNodes((nds) => nds.concat(newNode));
      setIdCount(idCount + 1); // Increase the count for new nodes
      GetTrigerSubActionsAPI(triggerData.trigerId);
    },
    [idCount]
  );

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDragStart = (event, trigger) => {
    event.dataTransfer.setData("trigger", JSON.stringify(trigger)); // Store trigger data
  };

  const handleAddNewNode = (parentId, direction = "down", actionName = "Default Action", FullData) => {
    addNode(parentId, direction, "Action", actionName, FullData); // Pass actionName to addNode
  };

  const handleAddConditionNode = (parentId, direction = "down", data, FullData, LeadFormData) => {
    const actionName = data.actionName || data.conditionAction || "Default Condition";
  
    // Pass LeadFormData to the addNode function
    addNode(parentId, direction, "Condition", actionName, FullData, LeadFormData);
  };
  
  const addNode = (parentId, direction, nodeType, actionName, FullData, LeadFormData) => {
    console.log('LeadFormData?.conditions:', LeadFormData?.conditions);
  
    const parentNode = nodes.find((node) => node.id === parentId);
  
    if (!parentNode) {
      console.warn(`Parent node with ID ${parentId} not found.`);
      return;
    }
  
    const newPosition = calculatePosition(parentNode, direction);
  
    const newNodeId = `${idCount}-${Date.now()}`; // Unique ID for the new node
  
    // Generate dynamic conditions from LeadFormData
    const dynamicConditions = (LeadFormData?.conditions || []).map((condition) => ({
      FormId: 127,
      SearchKey: condition.searchKey || null,
      Value: condition.value
        ? typeof condition.value === 'object'
          ? JSON.stringify(condition.value)
          : condition.value
        : null,
      Operator: condition.operator || 1,
      Operand: condition.operand || "AND",
    }));
  
    if (dynamicConditions.length === 0) {
      console.warn('No conditions found in LeadFormData.');
    }
  
    const newNode = {
      id: newNodeId,
      actionId: FullData?.actionId || null,
      type: nodeType,
      data: {
        label: actionName,
        description: nodeType === "Condition" ? "Condition Node" : "Action Node",
        parentData: parentNode?.data,
        actionId: FullData?.actionId || null,
        conditions: dynamicConditions, // Add dynamic conditions here
      },
      position: newPosition,
    };
  
    setNodes((nds) => [...nds, newNode]);
  
    // Add a connection edge from the parent node
    const edgeLabel = direction === "down-left" ? "YES" : direction === "down-right" ? "NO" : null;
  
    const newEdge = {
      id: `e${parentId}-${newNodeId}`, // Ensure unique ID for the edge
      source: parentId,
      target: newNodeId,
      type: "smoothstep",
      data: { conditionType: edgeLabel?.toLowerCase() },
      label: edgeLabel, // Add the label
    };
  
    setEdges((eds) => [...eds, newEdge]);
    setIdCount(idCount + 1);
  };
  
  


  // Calculate initial position based on direction


  // Adjust position to avoid overlap with existing nodes
  const adjustForOverlap = (position) => {
    const tolerance = 100; // Minimum distance to avoid overlap
    let adjustedPosition = { ...position };

    // Loop through all nodes to check for overlaps
    nodes.forEach((node) => {
      const distanceX = Math.abs(node.position.x - adjustedPosition.x);
      const distanceY = Math.abs(node.position.y - adjustedPosition.y);

      if (distanceX < tolerance && distanceY < tolerance) {
        // Adjust position to avoid overlap
        adjustedPosition.x += tolerance * (node.position.x > adjustedPosition.x ? -1 : 1);
        adjustedPosition.y += tolerance;
      }
    });

    return adjustedPosition;
  };


  const GetTrigerSubActionsAPI = async (triger) => {
    const response = await GetTrigerSubActions(triger);
    if (!(response && response.status && response.status !== 200)) {

      SetTrigerSubActions(response); return response;
    } else {
      SetTrigerSubActions([]);
    }

  };
  // const convertToFormattedJsonString = (data) => {
  //   const jsonString = JSON.stringify(data, null, 2);  // Format with indentation

  //   // Replace spaces with \r\n for a more readable format (if you need specific line breaks)
  //   return jsonString.replace(/\n/g, '\r\n');
  // };

  const saveHandler = (TriggerDetails, edges, nodes) => {
    UpdateTriggerAPI(
      {
        trigerId: TriggerDetails.trigerId,
        workflowJson: TriggerDetails.workflowJson,
        diagramJson: JSON.stringify({
          edges: edges,
          nodes: nodes
        })
      }
    )
  };


  const GetTriggersAPI = async () => {
    const response = await GetTriggers({
      pageIndex: 1,
      pageSize: 1000,
      isExist: false
    });
    if (!(response && response.status && response.status !== 200)) {
      SetTriggers(response);
    } else {
      SetTriggers([]);
    }
  };

  const GetSubActionsAPI = async (actionId, actionType, actionValue, anchor) => {
    const response = await GetSubActions({
      actionId: actionId ? +actionId : null,
      actionType,
      actionValue,
    });

    if (response) {
      setSubActions(response);
    } else {
      console.warn('Failed to fetch sub-actions');
      setSubActions([]);
    }
    return response;
  };

  useEffect(() => {
    GetTriggersAPI();
  }, []);


  const [isLoading, setIsLoading] = useState(false);



  const UpdateTriggerAPI = async ({ trigerId, workflowJson, diagramJson }) => {
    setIsLoading(true);
    const response = await UpdateTrigger({
      trigerId: 1 || trigerId,
      workflowJson: workflowJson,
      diagramJson: diagramJson,
    });

    if (response) {
      setIsLoading(false);

    } else {
      setIsLoading(false);
    }
    showSuccess('Update Flow Successfully')
    setIsLoading(false);
  };


  const calculatePosition = (parentNode, direction) => {
    const horizontalGap = 300; // Adjust as needed
    const verticalGap = 200; // Adjust as needed

    let newPosition = {
      x: direction === "down-left"
        ? parentNode.position.x - horizontalGap
        : direction === "down-right"
          ? parentNode.position.x + horizontalGap
          : parentNode.position.x,
      y: parentNode.position.y + verticalGap,
    };

    // Check for overlap with existing nodes
    let overlap = nodes.some(
      (node) =>
        Math.abs(node.position.x - newPosition.x) < 100 &&
        Math.abs(node.position.y - newPosition.y) < 100
    );

    // Adjust position if overlap is detected
    while (overlap) {
      newPosition.x += 50; // Shift slightly to the right
      overlap = nodes.some(
        (node) =>
          Math.abs(node.position.x - newPosition.x) < 100 &&
          Math.abs(node.position.y - newPosition.y) < 100
      );
    }

    return newPosition;
  };



  const GetTriggerDetailsAPI = async (trigerId) => {
    const response = await GetTriggerDetails(trigerId);
    if (!(response && response.status && response.status !== 200)) {
      SetTriggerDetails(response);

    } else {
      SetTriggerDetails([]);
    }
  };



  useEffect(() => {
    if (TriggerDetails) {
      const response = JSON.parse(TriggerDetails.diagramJson);

      const updatedNodes = response.nodes || [];
      const updatedEdges = response.edges || [];

      // Ensure the first node connects to the second node if applicable
      if (updatedNodes.length > 1) {
        const firstNode = updatedNodes[0];
        const secondNode = updatedNodes[1];

        const existingEdge = updatedEdges.find(
          (edge) => edge.source === firstNode.id && edge.target === secondNode.id
        );

        if (!existingEdge) {
          // Create a new edge from the first node to the second node
          const newEdge = {
            id: `e${firstNode.id}-${secondNode.id}`,
            source: firstNode.id,
            target: secondNode.id,
            type: "smoothstep",
            label: null, // No label for the direct connection
          };

          updatedEdges.push(newEdge);
        }
      }

      setNodes(updatedNodes);
      setEdges(updatedEdges);
    }
  }, [TriggerDetails]);



  useEffect(() => {
    if (Params)
      GetTriggerDetailsAPI(Params);
  }, []);
  useEffect(() => {
    if (Params)
      GetTrigerSubActionsAPI(Params);
  }, []);

  const nodeTypes = useMemo(
    () => ({
      Action: (props) => (
        <CustomNode
          {...props}
          handleAddNewNode={handleAddNewNode}
          Trigers={TrigerSubActions}
          GetTrigerSubActionsAPI={(item) => GetTrigerSubActionsAPI(item)}
          handleAddConditionNode={handleAddConditionNode}
          handleDeleteNode={handleDeleteNode}
          nodeType="Action"
          edges={edges}
        />
      ),
      Condition: (props) => (
        <CustomNode
          {...props}
          handleAddNewNode={handleAddNewNode}
          Trigers={TrigerSubActions}
          GetTrigerSubActionsAPI={(item) => GetTrigerSubActionsAPI(item)}
          handleAddConditionNode={handleAddConditionNode}
          handleDeleteNode={handleDeleteNode}
          nodeType="Condition"
          edges={edges}
        />
      ),
    }),
    [
      TrigerSubActions,
      edges,
      handleAddNewNode,
      handleAddConditionNode,
      handleDeleteNode,
    ]
  );

  function generateWorkflowJson(nodes, edges) {
    if (!nodes || nodes.length === 0) {
      console.error("Nodes array is empty or undefined.");
      return null;
    }
  
    // Get TrigerId and TrigerName from the first node
    const firstNode = nodes[0];
    const triggerId = firstNode?.data?.triggerData?.trigerId || null;
    const triggerName = firstNode?.data?.triggerData?.trigerName || "Unnamed Trigger";
  
    // Create a mapping of node IDs to their index-based sequence number
    const nodeSequence = {};
    nodes.forEach((node, index) => {
      nodeSequence[node.id] = index;
    });
  
    // Map nodes to the desired Workflow format
    const workflow = nodes.slice(1).map((node) => {
      const nodeId = node.id;
      const sequenceId = nodeSequence[nodeId];
  
      // Find the edge where this node is the target (i.e., it has a parent)
      const incomingEdge = edges.find(edge => edge.target === nodeId);
      const previousActionId = incomingEdge ? nodeSequence[incomingEdge.source] : null;
      const parentNode = incomingEdge ? nodes.find(n => n.id === incomingEdge.source) : null;
  
      // Determine PreviousActionStatusRequired
      let previousActionStatusRequired = null;
      if (parentNode && parentNode.type === "Action") {
        previousActionStatusRequired = "Done";
      } else if (incomingEdge && parentNode && parentNode.type === "Condition") {
        previousActionStatusRequired = incomingEdge.data?.conditionType === "yes" ? "True" : "False";
      }
  
      // Safely access conditions from the node data
      const conditions = node?.data?.conditions?.map((condition) => ({
        FormId: condition.FormId || null,
        SearchKey: condition.SearchKey || null,
        Value: condition.Value ? JSON.parse(condition.Value) : null,
        Operator: condition.Operator || 1,
        Operand: condition.Operand || "AND",
      })) || [];
      
  
      // Create the Workflow entry
      const workflowItem = {
        id: sequenceId,
        Name: node?.data?.label || "Unnamed Node",
        Type: node?.type || "Unknown Type",
        PreviousActionId: previousActionId,
        PreviousActionStatusRequired: previousActionStatusRequired,
        FormValues: node.type === "Condition" ? { 
          ConditionId: node.actionId || sequenceId,
          Conditions: conditions,
        } : {},
        Params: {},
      };
  
      return workflowItem;
    });
  
    // Construct the final JSON structure
    const finalJson = {
      TrigerId: triggerId,
      TrigerName: triggerName,
      Workflow: workflow,
    };
  
    return finalJson;
  }
  

  // Example usage:
  console.log(JSON.stringify(generateWorkflowJson(nodes, edges), null, 2));


  const edgeTypes = useMemo(() => ({
    custom: CustomEdge,
  }), []);

  return (
    <div className="WorkFlowView-MAIN view-wrapper QA-view-wrapper">
      <Spinner isActive={isLoading} />
      <div className="flex mas">
        {Params && <div className="mars-5">

          <ButtonBase
            onClick={() => {
              Params && GlobalHistory.push(`/home/MainWorkFlowView`) ||
                GlobalHistory.push(`/home/ExistingWorkFlowView`);
            }}
          >        <span className='mdi mdi-arrow-left-thick' /> Back to Main Page</ButtonBase>

          {Params && <h2 style={{ width: "300px" }}>Work Flows<br />{TriggerDetails && TriggerDetails.trigerName || null}</h2>}

        </div>
          ||


          <ButtonBase
            onClick={() => {
              Params && GlobalHistory.push(`/home/MainWorkFlowView`) ||
                GlobalHistory.push(`/home/MainWorkFlowView`);
            }}
          >        <span className='mdi mdi-arrow-left-thick' /> Back to Main Page</ButtonBase>


        }
        <div className="w-25 px-4 d-flex-center fj-end">
          <Button
            className="btns theme-propx solid"
            id="btn-save"
            onClick={() => saveHandler(TriggerDetails, edges, nodes)}
          >
            {t(`${translationPath}save-changes`)}
          </Button>
        </div>
      </div>


      <div style={{ height: "600px", width: "100%" }}>
        <ReactFlowProvider>
          <div className="dndflow">
            {!Params &&
              <div
                className={
                  (nodes && nodes.length > 0 && "Item-Drag-Work DISBELDp ") ||
                  "Item-Drag-Work"
                }
              >
                <Sidebar
                  onDragStart={onDragStart}
                  initialNodes={nodes}
                  Triggers={Triggers}
                />
              </div>}
            <div
              style={{ height: "600px", width: "100%" }}
              onDrop={onDrop}
              onDragOver={onDragOver}
            >
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onConnect={onConnect}
                edgeTypes={edgeTypes}
                nodeTypes={nodeTypes}
                fitView
                fitViewOptions={{ padding: 0.2 }}
                attributionPosition="top-right"
              >
                <MiniMap />
                <Controls />
              </ReactFlow>
            </div>
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  );
};