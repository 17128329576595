export const LeadOwnerLeadsPermissions = {
  SendToRotation: {
    permissionsId: "307d0ba6-7f57-4692-85bd-043f5938084b",
    permissionsName: "Send To Rotation",
    description: null,
    componentsId: null,
    components: null,
  },

  CloneLead: {
    permissionsId: "fd19ece0-329f-47ea-84a1-0cdd7ffc307b",
    permissionsName: "Clone Lead",
    description: null,
    componentsId: null,
    components: null,
  },

  GetActivitiesForLead: {
    permissionsId: "fb723cc0-468e-4055-84a5-40b63f7b3905",
    permissionsName: "Get Activities For Lead",
    description: null,
    componentsId: null,
    components: null,
  },

  EditLeadDetails: {
    permissionsId: "2ff49a5c-c29e-42c1-be43-5c18b41dfc3a",
    permissionsName: "Edit lead details",
    description: null,
    componentsId: null,
    components: null,
  },

  GetAgentLeadAssigmentHistory: {
    permissionsId: "a735f313-ecdd-49c7-88c1-7d7952895d66",
    permissionsName: "Get Agent Lead Assigment History",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewMatchingUnits: {
    permissionsId: "2ccd61c0-8a49-41b7-bcde-9e4d558ea371",
    permissionsName: "View Matching Units",
    description: null,
    componentsId: null,
    components: null,
  },

  CloseLead: {
    permissionsId: "7b94c363-932f-4270-abcb-9e6942344663",
    permissionsName: "Close Lead",
    description: null,
    componentsId: null,
    components: null,
  },

  ReassignLead: {
    permissionsId: "4f26ad01-9050-47cf-a254-c2f337b02b05",
    permissionsName: "Reassign Lead",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewLeadDetails: {
    permissionsId: "f23ee2d0-b2cf-4964-82de-c4008675fd1f",
    permissionsName: "View lead details",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewAndSearchInLeadOwnerLeads: {
    permissionsId: "af0c047a-5516-4a7e-ae7b-c598e2a1a9a4",
    permissionsName: "View and search in Lead Owner Leads",
    description: null,
    componentsId: null,
    components: null,
  },

  ImportLead: {
    permissionsId: "9210c8c6-1d28-46c3-9fb8-e529fcc42d6e",
    permissionsName: "Import Lead",
    description: null,
    componentsId: null,
    components: null,
  },

  AddNewLead: {
    permissionsId: "11e63cd6-5aa2-461a-9569-e8258ea8e61e",
    permissionsName: "Add new lead",
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewActivity: {
    permissionsId: "71459543-b775-4f3b-2876-08dbaf79a1bb",
    permissionsName: "Add New Activity",
    description: null,
    componentsId: null,
    components: null,
  },
  ReassignSellerLead: {
    permissionsId: "ad6821bd-c287-4a78-8875-08dc0129f7d6",
    permissionsName: "Reassign Seller Lead",
    description: null,
    componentsId: null,
    components: null,
  },
  SendToTheLeadPool: {
    permissionsId: "de0e250c-1ece-42b4-c4c6-08dc683b0c43",
    permissionsName: "Send To The Leads Pool",
    description: null,
    componentsId: null,
    components: null,
  },
  SendToTheLeadPoolAssets: {
    permissionsId: "6f66726c-5159-4db7-5071-08dce2e2dc64",
    permissionsName: "Send Leads To The Lead Pool (Assets)",
    description: null,
    componentsId: null,
    components: null,
  },
  EditContactName: {
    permissionsId: "1031bfe8-8523-41d5-ec33-08dd2fb527c1",
    permissionsName: "Edit Contact Name",
    description: null,
    componentsId: null,
    components: null,
  },
};
