import React from "react";

function SignatureIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M18.285 1.419a3.707 3.707 0 0 0-1.714.863c-.668.589-11.953 11.927-12.12 12.178-.281.422-.392.686-1.58 3.772-1.281 3.327-1.278 3.317-1.101 3.648a.786.786 0 0 0 .66.43c.221.015.351-.031 3.295-1.164 3.117-1.2 3.391-1.315 3.815-1.597.251-.167 11.589-11.452 12.178-12.12.36-.409.627-.887.787-1.409.102-.331.111-.413.111-1.02 0-.604-.01-.69-.109-1.012-.38-1.237-1.27-2.126-2.495-2.493-.442-.132-1.311-.17-1.727-.076m1.193 1.643c.672.153 1.272.739 1.449 1.415.135.517.057 1.101-.204 1.532-.096.158-1.831 1.919-5.929 6.017L9 17.82l-1.41-1.41L6.18 15l5.794-5.794c4.098-4.098 5.859-5.833 6.017-5.929.423-.257.962-.334 1.487-.215M6.418 17.598c.593.594 1.07 1.086 1.06 1.095-.039.036-3.49 1.349-3.505 1.334-.02-.02 1.319-3.507 1.347-3.507.011 0 .506.485 1.098 1.078m6.396 2.6a.86.86 0 0 0-.521.39.82.82 0 0 0 .298 1.121l.189.111h8.44l.189-.111a.82.82 0 0 0 0-1.418l-.189-.111-4.14-.006c-2.277-.004-4.197.007-4.266.024"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default SignatureIcon;
