import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    descriptionText: {
      color: theme.palette.text.placeholder,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    dateRanges: {
      color: theme.palette.button.secondary_fg,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
    },
    dateRangeBtn: {
      background: theme.palette.button.secondary_bg,
      borderRadius: "8px",
      display: "flex",
      gap: "8px",
      padding: "10px 16px",
      border: `1px solid ${theme.palette.button.secondary_border}`,
    },
    activeDateRangeBtn: {
      boxShadow: theme.shadows[12],
      border: `1px solid ${theme.palette.border.brand}`,
    },
    footerDateWrapper: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    footerDate: {
      padding: "10px 14px",
      borderRadius: "8px",
      border: `1px solid ${theme.palette.border.primary}`,
      color: theme.palette.text.primary,
      display: "inline-block",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
    separator: {
      lineHeight: "24px",
      fontWeight: 400,
      fontSize: "16px",
    },
    actions: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    closeIcon: {
      color: theme.palette.foreground.secondary,
    }
  }
});
