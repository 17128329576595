import React, { useState, useRef, useCallback, useEffect } from "react";
import { FormControl } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SelectComponet } from "../../../../Controls";

export const TableFilterSelectOptionsComponent = ({
  idRef,
  filterKey,
  displayPath,
  searchableKey,
  filterValues,
  onFilterValuesChanged,
  translationPath,
  parentTranslationPath,
  optionFilterName,
  valueSelected,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  multiple 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [selectKey, setSelectKey] = useState(Math.random());
  const [selectedValue, setSelectedValue] = useState(!multiple ? valueSelected || null : valueSelected || []);
  const handleChange = (value) => {
    if (onFilterValuesChanged) {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        const localFilterValues = (filterValues && { ...filterValues }) || {};
        localFilterValues[filterKey] = {
          value,
          displayPath,
          searchableKey,
        };
        onFilterValuesChanged(localFilterValues);
      }, 700);
    }
  };

  const onSelectChanged = useCallback(
    (selectedValue) => {
      setSelectedValue(selectedValue);
      handleChange(selectedValue);
    },
    [handleChange]
  );

  useEffect(() => {
    if (isClearFiltersClicked) {
      setSelectedValue(null);
      setSelectKey(Math.random());
      setIsClearFiltersClicked(false);
    }
  }, [isClearFiltersClicked, setIsClearFiltersClicked]);

  useEffect(() => {
    if (filterValues && filterValues[filterKey]) {
      setSelectedValue(filterValues[filterKey].value);
    }
  }, [filterValues, filterKey]);

  return (
    <div>
      <FormControl
        variant="outlined"
        className="reminder-select makeStyles-formControl-5"
      >
        <SelectComponet
          key={selectKey}
          data={optionFilterName}
          value={selectedValue || null}
          parentTranslationPath={parentTranslationPath}
          emptyItem={{
            key: null,
            text: `${t("Shared:select-filter-by")}`,
            isDisabled: false,
          }}
          onSelectChanged={onSelectChanged}
          themeClass="theme-action-buttons"
          idRef={idRef}
          valueInput="key"
          keyValue="actionsbuttons"
          translationPathForData={translationPath}
          textInput="value"
          multiple={multiple || false}
        />
      </FormControl>
    </div>
  );
};
