import { useRef } from 'react';

export const useDebouncedAction = (
  action,
  delay = 700,
  disableFirstFetch = false
) => {
  const timerRef = useRef();
  const isFirstFetch = useRef(true);
  const debouncedAction = (...payload) => {
    if (disableFirstFetch && isFirstFetch.current) {
      isFirstFetch.current = false;
      return;
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => action(...payload), delay);
  };
  return debouncedAction;
};
