import React from "react";

function VideoCreatorIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.92 4.04c-.77.053-1.248.165-1.74.411A3.776 3.776 0 0 0 1.124 7.16C1.018 7.702 1 8.421 1 12c0 4.212.016 4.561.245 5.32.332 1.102 1.333 2.103 2.435 2.435.771.232 1.072.245 5.82.245s5.049-.013 5.82-.245c.908-.273 1.765-1.006 2.216-1.897.333-.656.464-1.441.464-2.778v-.659l1.19 1.184c.655.652 1.284 1.258 1.4 1.347.413.319.909.41 1.384.253.554-.182.88-.581.985-1.205.031-.181.041-1.45.032-4.16-.011-3.589-.017-3.916-.082-4.106-.256-.755-1.06-1.181-1.803-.958-.352.106-.607.32-1.856 1.56L18 9.576v-.658c0-1.336-.131-2.12-.465-2.777a3.871 3.871 0 0 0-2.215-1.895c-.654-.215-.668-.216-5.48-.227-2.453-.005-4.667.004-4.92.021m9.572 2.052c.401.095.617.216.908.508.296.295.414.509.512.928.062.266.068.671.068 4.472 0 4.557.004 4.476-.228 4.952-.126.259-.562.686-.834.816-.444.213-.411.212-5.418.212-4.999 0-4.97.001-5.418-.209-.27-.128-.705-.554-.834-.819-.232-.476-.228-.395-.228-4.952 0-3.801.006-4.206.068-4.472.087-.373.206-.61.432-.861.363-.405.761-.576 1.46-.628.22-.016 2.389-.026 4.82-.021 3.929.007 4.45.016 4.692.074M21 12.01v2.57l-1.29-1.29L18.42 12l1.28-1.28c.704-.704 1.284-1.28 1.29-1.28.005 0 .01 1.156.01 2.57"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}
export default VideoCreatorIcon;
