import { config } from '../config';
import { HttpServices } from '../Helper';

export const CreatePaymentPlan = async (body) => {
  try {
    const response = await HttpServices.post(
      `${config.server_address}/CrmDfm/PropertyPaymentPlan/CreatePaymentPlan`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const GetAllPaymentPlans = async (body) => {
  try {
    const response = await HttpServices.post(
      `${config.server_address}/CrmDfm/PropertyPaymentPlan/GetAllPaymentPlans`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const UpdatePaymentPlan = async (body) => {
  try {
    const response = await HttpServices.put(
      `${config.server_address}/CrmDfm/PropertyPaymentPlan/UpdatePaymentPlan/${body.id}`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const DeletePaymentPlan = async (id) => {
  try {
    const response = await HttpServices.delete(
      `${config.server_address}/CrmDfm/PropertyPaymentPlan/DeletePaymentPlan/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const GetPredefinedPaymentPlans = async ({
  pageIndex,
  pageSize,
  propertyId,
}) => {
  try {
    const response = await HttpServices.get(
      `${config.server_address}/CrmDfm/PropertyPaymentPlan/GetPredefinedPaymentPlans/${pageIndex}/${pageSize}/${propertyId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
