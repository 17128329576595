import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  label: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    color: theme.palette.text.secondary,
    marginLeft: "0px",
  },
  containerSwitchItem: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  root: {
    // Base styles for MuiSwitch-root (medium size)
    borderRadius: "9999px",
    display: "inline-flex",
    position: "relative",
    flexShrink: 0,
    overflow: "hidden",
    zIndex: 0,
    padding: "0px",
    width: "44px",
    height: "24px",

    //foucs
    "&:focus": {
      "& .MuiSwitch-track": {
        backgroundColor: theme.palette.background.secondary_hover,
        boxShadow: `0px 0px 0px 4px rgba(152, 162, 179, 0.20)`,
      },
      "& .MuiSwitch-thumb": {
        boxShadow: `0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)`,
      },
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          boxShadow: `0px 0px 0px 4px rgba(140, 115, 75, 0.24)`,
        },
      },
    },

    //hover
    "&:hover": {
      "& .MuiSwitch-track": {
        backgroundColor: theme.palette.border.tertiary,
      },
      "& .MuiSwitch-thumb": {
        boxShadow: `0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)`,
      },
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.utility.brand_700,
        },
      },
    },

    "& .MuiSwitch-switchBase": {
      top: "2px",
      left: "2px",
      padding: "0px",
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: `${theme.palette.background.brand_solid}`,
        },
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.text.white,
      },
    },

    "& .MuiSwitch-track": {
      opacity: 1,
      transition: "background-color 0.3s ease",
    },

    // Size variations (small size)
    "&.MuiSwitch-sizeSmall": {
      width: "36px",
      height: "20px",
      "& .MuiSwitch-thumb": {
        width: "16px",
        height: "16px",
        boxShadow:
          "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)", // Customize thumb shadow
      },
      "& .MuiSwitch-track": {
        borderRadius: "12px",
      },
    },

    // Default state
    "&.default": {
      "& .MuiSwitch-track": {
        backgroundColor: theme.palette.background.secondary_hover,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: "#fff",
      },
    },

    // Disabled state
    "&.disabled": {
      "& .MuiSwitch-track": {
        backgroundColor: theme.palette.background.tertiary,
        opacity: 1,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.background.gray_50,
        boxShadow:
          "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
      },
    },
  },
}));
