import React from 'react';

const LabeledField = ({
  component,
  label,
  hideDivider = false,
  className = '',
}) => {
  return (
    <>
      {!hideDivider && <hr className='hr' />}
      <div className={'labeled-field '.concat(className)}>
        <label className='labeled-field__label'>{label}</label>
        <div className='labeled-field__component'>{component}</div>
      </div>
    </>
  );
};

export default LabeledField;
