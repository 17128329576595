import React from "react";

function FolderIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.75 4C4.75 2.20508 6.20508 0.75 8 0.75H24C24.1212 0.75 24.2375 0.798159 24.3232 0.883885L35.1161 11.6768C35.2018 11.7625 35.25 11.8788 35.25 12V36C35.25 37.7949 33.7949 39.25 32 39.25H8C6.20507 39.25 4.75 37.7949 4.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M24 0.5V8C24 10.2091 25.7909 12 28 12H35.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M20.75 20.25L19.9133 18.5767C19.6726 18.0951 19.5521 17.8543 19.3725 17.6784C19.2137 17.5228 19.0222 17.4045 18.8121 17.332C18.5744 17.25 18.3052 17.25 17.7667 17.25H14.9C14.0599 17.25 13.6399 17.25 13.319 17.4135C13.0368 17.5573 12.8073 17.7868 12.6635 18.069C12.5 18.3899 12.5 18.8099 12.5 19.65V20.25M12.5 20.25H23.9C25.1601 20.25 25.7902 20.25 26.2715 20.4952C26.6948 20.711 27.039 21.0552 27.2548 21.4785C27.5 21.9598 27.5 22.5899 27.5 23.85V27.15C27.5 28.4101 27.5 29.0402 27.2548 29.5215C27.039 29.9448 26.6948 30.289 26.2715 30.5048C25.7902 30.75 25.1601 30.75 23.9 30.75H16.1C14.8399 30.75 14.2098 30.75 13.7285 30.5048C13.3052 30.289 12.961 29.9448 12.7452 29.5215C12.5 29.0402 12.5 28.4101 12.5 27.15V20.25Z"
        stroke="#7F6944"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default FolderIcon;
