import React from "react";
import { Box } from "@material-ui/core";
import moment from "moment";

import { CustomBadge, StatusBadges } from "../../../Components";

// Icons
import { DotIcon } from "../../../assets/icons";

export const historyTableHeaderData = (isDarkMode = false) => [
  {
    id: 1,
    formFieldId: 1,
    minWidth: 175,
    headerName: "Created by",
    field: "createdBy",
    sortable: true,
    filterable: true,
    fieldType: "text",
    displayPath: "createdBy",
    isDefaultFilterColumn: true,
    fieldKey: "createdBy",
    formFieldTitle: "createdBy",
    disableColumnMenu: true,
    searchKey: "createdBy",

    renderCell: (item) => {
      return (
        <span
          style={{
            padding: "16px 24px",
            alignContent: "center",
          }}
        >
          {item?.row?.createdBy}
        </span>
      );
    },
  },
  {
    id: 2,
    formFieldId: 2,
    minWidth: 175,
    headerName: "Adding date",
    field: "addingDate",
    displayPath: "addingDate",
    isHiddenFilter: false,
    filterable: true,
    fieldType: "datePicker",
    disableColumnMenu: true,
    fieldKey: "addingDate",
    isDate: true,
    sortable: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Date",
    renderCell: (item) => {
      return (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {item?.row?.addingDate && (
            <label style={{ lineHeight: "20px", fontWeight: 500 }}>
              {moment(item.row.addingDate).format("DD MMM YYYY")}
            </label>
          )}
        </Box>
      );
    },
  },
  {
    id: 3,
    formFieldId: 3,
    minWidth: 175,
    headerName: "Expiry date",
    field: "expiryDate",
    displayPath: "expiryDate",
    isHiddenFilter: false,
    filterable: true,
    fieldType: "datePicker",
    disableColumnMenu: true,
    fieldKey: "expiryDate",
    isDate: true,
    sortable: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Date",
    renderCell: (item) => {
      return (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {item?.row?.expiryDate && (
            <label style={{ lineHeight: "20px", fontWeight: 500 }}>
              {moment(item.row.expiryDate).format("DD MMM YYYY")}
            </label>
          )}
        </Box>
      );
    },
  },
  {
    id: 4,
    formFieldId: 4,
    minWidth: 175,
    headerName: "Risk rating",
    field: "riskRatingDurationTypeInfo.enumName",
    fieldType: "text",
    fieldKey: "riskRatingDurationTypeInfo.enumName",
    sortable: true,
    displayPath: "riskRatingDurationTypeInfo.enumName",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Risk rating",
    renderCell: (item) => {
      const risk = { High: "#F04438", Medium: "#F79009", Low: "#17B26A" };
      const fill = risk[item?.row?.riskRatingDurationTypeInfo?.enumName];

      return (
        item?.row?.riskRatingDurationTypeInfo?.enumName && (
          <CustomBadge
            label={item?.row?.riskRatingDurationTypeInfo?.enumName}
            BackgroundColor={"transparent"}
            BorderColor={"transparent"}
            Color={isDarkMode ? "#F5F5F6" : "#101828"}
            Style={{ fontWeight: 600 }}
            LeftIcon={<DotIcon width="14" height="14" fill={fill} />}
          />
        )
      );
    },
  },
  {
    id: 5,
    formFieldId: 5,
    minWidth: 175,
    headerName: "KYC status",
    field: "kycStatusInfo.enumName",
    fieldType: "text",

    fieldKey: "kycStatusInfo.enumName",
    sortable: true,
    displayPath: "kycStatusInfo.enumName",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "KYC status",
    renderCell: (item) => {
      return (
        item?.row?.kycStatusInfo?.enumName && (
          <StatusBadges Status={item?.row?.kycStatusInfo?.enumName} />
        )
      );
    },
  },
  {
    id: 6,
    formFieldId: 6,
    minWidth: 175,
    headerName: "Post status",
    field: "postStatusInfo.enumName",
    fieldType: "text",
    fieldKey: "postStatusInfo.enumName",
    sortable: true,
    displayPath: "postStatusInfo.enumName",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Post status",
    renderCell: (item) => {
      return (
        item?.row?.postStatusInfo?.enumName && (
          <StatusBadges Status={item?.row?.postStatusInfo?.enumName} />
        )
      );
    },
  },
  {
    id: 7,
    formFieldId: 7,
    minWidth: 175,
    headerName: "Agent status",
    field: "agentPostStatusInfo.enumName",
    fieldType: "text",
    fieldKey: "agentPostStatusInfo.enumName",
    sortable: true,
    displayPath: "agentPostStatusInfo.enumName",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Agent status",
    renderCell: (item) => {
      return (
        item?.row?.agentPostStatusInfo?.enumName && (
          <StatusBadges Status={item?.row?.agentPostStatusInfo?.enumName} />
        )
      );
    },
  },
  {
    id: 8,
    formFieldId: 8,
    minWidth: 200,
    headerName: "Reason of change",
    field: "reason",
    sortable: true,
    filterable: true,
    fieldType: "text",
    displayPath: "remarks",
    isDefaultFilterColumn: true,
    fieldKey: "reason",
    formFieldTitle: "reason",
    disableColumnMenu: true,
    searchKey: "reason",

    renderCell: (item) => {
      return (
        <span
          style={{
            alignContent: "center",
          }}
        >
          {item?.row?.reason}
        </span>
      );
    },
  },
  {
    minWidth: 170,
    id: 9,
    formFieldId: 9,
    headerName: "Manual KYC",
    field: "isManualKYC",
    displayPath: "isManualKYC",
    fieldKey: "isManualKYC",
    isHiddenFilter: false,
    disableColumnMenu: true,
    filterable: true,
    sortable: true,
    data: { enum: ["Yes", "No"] },
    fieldType: "select",
    isDefaultFilterColumn: true,
    formFieldTitle: "isManualKYC",
    renderCell: (item) => (
      <span>{!!item?.row?.isManualKYC ? "Yes" : "No"}</span>
    ),
  },
];
