import {  LeasingTransactionsWithAMLProfile ,  LeasingTransactionsWithAML } from '../../Views/Home';

export const LeasingTransactionsWithAMLRoutes = [
  {
    path: '/view',
    name: 'LeasingTransactionsView:leasing-transactions-with-aml',
    component: LeasingTransactionsWithAML,
    layout: '/home/leasing-transactions-with-AML',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeasingTransactionsView:leasing-transactions',
        isDisabled: true,
        route: '/home/leasing-transactions-with-AML/view',
        groupName: 'accounts',
      },
    ],
  },
  {
    path: '/transaction-profile',
    name: 'LeasingTransactionsProfileView:transaction-profile',
    component: LeasingTransactionsWithAMLProfile,
    layout: '/home/leasing-transactions-with-AML',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeasingTransactionsView:leasing-transactions-with-aml',
        isDisabled: false,
        route: '/home/leasing-transactions-with-AML/view',
        groupName: 'accounts',
      },
      {
        name: 'LeasingTransactionsProfileView:transaction-profile',
        isDisabled: true,
        route: '/home/leasing-transactions-with-AML/transaction-profile',
        groupName: 'accounts',
      },
    ],
  },
];
