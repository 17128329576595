import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerCloseLeadsDialog:{
        display:'flex',
        flexDirection:'column',
        gap:'16px'
    },containerRowItem:{
        display:'flex',
        flexDirection:'row',
        gap:'32px'
    },titleItem:{
        maxWidth:'260px',
        minWidth:'160px',
        color:theme.palette.text.secondary,
        fontSize:'14px',
        fontWeight:'600',
        lineHeight:'20px'
    }
  } 
})