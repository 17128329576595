import React from "react";

function Document({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <rect x="1" y="18" width="23" height="16" rx="2" fill="#7F6944" />
      <path
        d="M9.82466 25.0781C9.77495 24.9053 9.70511 24.7526 9.61515 24.62C9.52518 24.4851 9.4151 24.3714 9.28489 24.2791C9.15705 24.1844 9.01027 24.1122 8.84455 24.0625C8.6812 24.0128 8.50009 23.9879 8.30123 23.9879C7.92954 23.9879 7.60284 24.0803 7.32111 24.2649C7.04176 24.4496 6.82395 24.7183 6.6677 25.071C6.51145 25.4214 6.43333 25.8499 6.43333 26.3565C6.43333 26.8632 6.51027 27.294 6.66415 27.6491C6.81803 28.0043 7.03584 28.2753 7.31756 28.4624C7.59928 28.647 7.93191 28.7393 8.31543 28.7393C8.66344 28.7393 8.96055 28.6778 9.20676 28.5547C9.45534 28.4292 9.64474 28.2528 9.77495 28.0256C9.90752 27.7983 9.97381 27.5296 9.97381 27.2195L10.2863 27.2656H8.41131V26.108H11.4546V27.0241C11.4546 27.6634 11.3197 28.2126 11.0498 28.6719C10.7799 29.1288 10.4082 29.4815 9.93475 29.7301C9.46126 29.9763 8.91912 30.0994 8.30833 30.0994C7.62651 30.0994 7.02755 29.9491 6.51145 29.6484C5.99535 29.3454 5.59289 28.9157 5.30407 28.3594C5.01761 27.8007 4.87438 27.1378 4.87438 26.3707C4.87438 25.7812 4.95961 25.2557 5.13006 24.794C5.30288 24.33 5.54436 23.937 5.85449 23.6151C6.16462 23.2931 6.52566 23.0481 6.93759 22.88C7.34952 22.7119 7.79578 22.6278 8.27637 22.6278C8.6883 22.6278 9.07182 22.6882 9.42694 22.8089C9.78205 22.9273 10.0969 23.0954 10.3715 23.3132C10.6485 23.531 10.8746 23.7902 11.0498 24.0909C11.225 24.3892 11.3374 24.7183 11.3872 25.0781H9.82466ZM14.1473 22.7273V30H12.6096V22.7273H14.1473ZM15.4124 30V22.7273H20.2277V23.995H16.95V25.728H19.9081V26.9957H16.95V30H15.4124Z"
        fill="white"
      />
    </svg>
  );
}

export default Document;
