import { makeStyles, Chip } from '@material-ui/core';
import React from 'react';

const colors = {
  good: '#ecfdf3',
  neutral: '#fffaeb',
  negative: '#fef3f2',
};

const useStyles = makeStyles({
  chip: {
    padding: '5px 12px',
    minWidth: '8rem',
    textAlign: 'center',
    height: 'fit-content',
    fontWeight: 900,
    fontSize: '0.875rem',
    borderRadius: '25px',
  },
  positive: {
    backgroundColor: colors.good,
    color: '#027a48',
  },
  negative: {
    backgroundColor: colors.negative,
    color : "#b42318"
  },
  neutral: {
    backgroundColor: colors.neutral,
    color: '#b54708',
  },
  notEvaluated: {
    backgroundColor: '#bcb8b8',
  },
});

function EvaluationChip({ sentimentEvaluation, size }) {
  const classes = useStyles();
  const chipClass =
  `${classes.chip} ${classes[sentimentEvaluation?.toLowerCase() ?? 'notEvaluated']}`;
  return (
    <Chip
      color='primary'
      label={sentimentEvaluation ?? 'Not Evaluated'}
      className={chipClass}
      size={size}
    />
  );
}

export default EvaluationChip;
