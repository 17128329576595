import React, { useCallback, useReducer, useRef } from "react";
import { PropTypes } from 'prop-types';
import moment from "moment";
import { useSelector } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import clsx from "clsx";

import {
  AutocompleteComponent,
  CheckboxesComponent,
  Inputs,
  RadiosGroupComponent,
  CustomAutocomplete,
  CustomInput,
  CustomIconButton
} from "../../../../../../../../../../../Components";
import {
  useLayoutDetection,
  useIsDesktop
} from "../../../../../../../../../../../Hooks";
import { DynamicTableComponent } from "../DynamicTableComponent/DynamicTableComponent";
import { showWarn } from "../../../../../../../../../../../Helper";
import { PlusIcon } from "../../../../../../../../../../../assets/icons";

import "./FormTablesGenerator.scss";
import "./NewFormTablesGenerator.scss";

export const FormTablesGenerator = ({
  formMap,
  formText,
  optionsData,
  selected,
  onSelectedChange,
  getLookupsByName,
  getContactOptions,
  classes,
  isClientForm,
  isIndividual = false,
}) => {
  const defaultColNo = 2;
  const {isNewLayout, hasNewLayout} = useLayoutDetection();
  const { isDesktop } = useIsDesktop();
  const { isDarkMode } = useSelector((state) => state.theme);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [currentNewRow, setCurrentNewRow] = useReducer(reducer, {
    companyPartnerNamesAndNationalities: {},
    companyStakeholdersInformation: {},
    companySignatories: {},
  });

  const mapToIdAndName = (obj) => ({
    id: obj.lookupsId,
    name: obj.lookupItemName,
  });

  const onCurrentNewRowChanged = ({ key, selectValue, isContactOptions }, tableKey) => {
    if(isContactOptions) {
      const autofilledRow = {
        ...(currentNewRow[tableKey] || {}),
        contactName: selectValue,
        contactId: selectValue?.contactsId,
        passportNumber: selectValue?.contact?.passport_no || null,
        nationality: selectValue?.contact?.nationality?.lookupItemName || null,
        contactMobile: selectValue?.contact?.mobile?.phone || null,
      }
      setCurrentNewRow({ id: tableKey, value: autofilledRow });
    }
    else {
      const rowWithChanges = {
        ...(currentNewRow[tableKey] || {}),
        [key]: selectValue,
      }
      setCurrentNewRow({ id: tableKey, value: rowWithChanges });
    }
  }

  const addRowHandler = (formSection) => {
    const addedRecord = currentNewRow[formSection.tableDataKey];
    const isEmptyRecord = !addedRecord?.contactName;

    if(isEmptyRecord) {
      showWarn("Please select a contact and fill record fields .");
      return; 
    }
    
    let localNewTableData = [...(selected[formSection.tableDataKey] || [])];
      localNewTableData.push(addedRecord);
      onSelectedChange(formSection.tableDataKey, localNewTableData);
      setCurrentNewRow({ id: formSection.tableDataKey, value: {} });
  };

  const getParsedValue = (value) => {
    let parsedValue = null;
    try {
      parsedValue = JSON.parse(value);
    } catch (_) {
      parsedValue = value;
    }
    return parsedValue;
  };

  const getYesNoValue = (val) => {
    if (typeof val === "boolean" && val == true)
      return "Yes"
    else if (typeof val === "boolean" && val == false)
      return "No"
  }

  const getDateValue = (isDate, val) => {
    if (isDate) return val? moment(val).format("LL") : null;
  }
  
  const getFieldTextValue = (field) => selected[field.input] &&
  typeof selected[field.input] === "object"
  ? selected[field.input].lookupItemName || ""
  : (getYesNoValue(selected[field.input]) ||
  // getDateValue(field.isDate, selected[field.input]) ||
  selected[field.input] ||
  "")
  
  const returnFieldInput = (field) => {
    switch (field.fieldType) {
      case "textInput":
        return isNewLayout && hasNewLayout ? (
            <CustomInput
            hasSearchIcon={false}
            style={{ maxWidth: "100%" }}
            placeholder="Enter value"
            value={selected[field.input]}
            isDisabled={field.disabled || field.forClientOnly || field.forAgentOnly}
            type={field.isNumber? "number" : "text"}
            onInputChanged={(event) => onSelectedChange(field.input, event.target.value)}
            onChange={(event) =>
              onSelectedChange(field.input, event.target.value)
            }
          />
        ):(
          <Inputs
            idRef={`${field.input}Ref`}
            inputPlaceholder="enter"
            value={selected[field.input]}
            isDisabled={field.disabled || field.forClientOnly || field.forAgentOnly}
            type={field.isNumber? "number" : "text"}
            onInputChanged={(event) => onSelectedChange(field.input, event.target.value)}
          />
        );
      case "selectInput":
        return isNewLayout && hasNewLayout ? (
            <CustomAutocomplete
            options={optionsData[field.optionsKey]?.map((field) => ({
              id: field?.lookupItemId,
              name: field?.lookupItemName,
            }))}
            defaultValue={
              selected?.[field?.input]?.lookupsId
                ? mapToIdAndName(selected?.[field?.input])
                : null
            }
            getOptionLabel={(option) => option?.name || ""}
            getOptionValue={(option) => option?.id || ""}
            placeholder={`Select value`}
            value={selected[field.input] || null}
            onOpen={() => {
              if (
                optionsData[field.optionsKey] &&
                optionsData[field.optionsKey].length == 0
              ) {
                getLookupsByName(field.lookupName, field.optionsKey);
              }
            }}
            onChange={(_, newValue) => onSelectedChange(field.input, newValue)}
          />
        ):(
          <AutocompleteComponent
            idRef={`${field.input}Ref`}
            inputPlaceholder={`select`}
            wrapperClasses="w-min-unset"
            selectedValues={selected[field.input] || null}
            data={optionsData[field.optionsKey] || []}
            displayLabel={(option) => option.lookupItemName || ""}
            multiple={false}
            isDisabled={field.disabled || field.forClientOnly}
            withoutSearchButton
            onOpen={() => {
              if (
                optionsData[field.optionsKey] &&
                optionsData[field.optionsKey].length == 0
              ) getLookupsByName(field.lookupName, field.optionsKey);
            }}
            onChange={(_, newValue) => onSelectedChange(field.input, newValue)}
          />
        );
      case "groupCheckbox":
        return (
          <CheckboxesComponent
            idRef={`${field.input}Ref`}
            data={selected[field.input] || []}
            isRow
            onSelectedCheckboxChanged={(clickedOption) => {
              let localCheckedOptions = [...(selected[field.input] || [])];
              const checkedOptionIndex = localCheckedOptions.findIndex(
                (item) => clickedOption.key === item.key
              );
              const changedClickedOption = {
                ...clickedOption,
                value: !clickedOption.value,
              };
              if (checkedOptionIndex !== -1) {
                localCheckedOptions.splice(
                  checkedOptionIndex,
                  1,
                  changedClickedOption
                );
              }
              onSelectedChange(field.input, localCheckedOptions);
            }}
            checked={(clickedOption) => clickedOption.value}
            labelValue=""
            labelInput="label"
          />
        );
      case "singleCheckbox":
        return (
          <CheckboxesComponent
          idRef={`${field.input}Ref`}
          label={field.boxLabel || ''}
          singleChecked={null}
          onSelectedCheckboxClicked={()=>{}}
        />
        );
      case "radioButton":
        return (
          <RadiosGroupComponent
            idRef={`${field.input}Ref`}
            data={optionsData[field.optionsKey] || []}
            valueInput="value"
            labelInput="key"
            value={selected[field.input]}
            onSelectedRadioChanged={(event) =>
              onSelectedChange(field.input, getParsedValue(event.target.value))
            }
            isDisabled={field.disabled || field.forClientOnly}
          />
        );
      case "readOnly":
        return (
          <span className="readOnly_input">
            {getFieldTextValue(field)}
          </span>
        );
      case "signature":
        return (
          <span className="signature_input">{field.placeholder || ""}</span>
        );
      default:
        return null;
    }
  };

  const getRadioBtnCheckedVal = ({field, radioKey}) => `${getFieldTextValue(field) === radioKey}`;
  
  const returnClientFormFields = (field) => {
    switch (field.fieldType) {
      case "groupCheckbox":
        return (
          <CheckboxesComponent
            idRef={`${field.input}Ref`}
            data={selected[field.input] || []}
            isRow
            onSelectedCheckboxChanged={(clickedOption) => {
              let localCheckedOptions = [...(selected[field.input] || [])];
              const checkedOptionIndex = localCheckedOptions.findIndex(
                (item) => clickedOption.key === item.key
              );
              const changedClickedOption = {
                ...clickedOption,
                value: !clickedOption.value,
              };
              if (checkedOptionIndex !== -1) {
                localCheckedOptions.splice(
                  checkedOptionIndex,
                  1,
                  changedClickedOption
                );
              }
              onSelectedChange(field.input, localCheckedOptions);
            }}
            checked={(clickedOption) => clickedOption.value}
            labelValue=""
            labelInput="label"
          />
        );
      case "singleCheckbox":
        return <div className="single_checkbox">
        <input type="checkbox" id="agreement_checkbox" name="agreement_checkbox" value=""/>
        <label for="agreement_checkbox">{field.boxLabel}</label>
        </div>
      case "textInput":
        return (
          <Inputs
            idRef={`${field.input}Ref`}
            inputPlaceholder="enter"
            value={getFieldTextValue(field)}
            isDisabled={field.forAgentOnly}
          />
        );
      case "radioButton":
        return !field.disabled && optionsData[field.optionsKey] ?
          (<form className="radio-btn-group">
            {optionsData[field.optionsKey].map((item) => (
              <>
                <input
                  type="radio"
                  id={`${field.input}_${item.key}`}
                  name={field.input}
                  value={item.key}
                  data-checked={getRadioBtnCheckedVal({
                    field,
                    radioKey: item.key,
                  })}
                />
                <label for={item.key}>{item.key}</label>
              </>
            ))}
          </form>) : (
            <Inputs
              idRef={`${field.input}Ref`}
              inputPlaceholder="enter"
              value={getFieldTextValue(field)}
            />
        );
      case "readOnly":
        return (
          <span className="readOnly_input">
            {getFieldTextValue(field)}
          </span>
        );
      case "signature":
        return (
          <>{field.forClientOnly ? <>
            <div className="signature_input">
              <canvas className="signature-pad" width="500" height="120" id={`${field.input}Ref`}></canvas>
              <ButtonBase className="signature_button">Clear</ButtonBase>
            </div>
            <input aria-invalid="false" id="signature_text_Ref" className={` signature_text ${field.input}_id `}
              placeholder="Type here, if you want to sign with your name ." rows="1" type="text"
              value=""  disabled={field.forAgentOnly}></input>
          </> : <span className="signature_placeholder">{field.placeholder || ""}</span>
          }</>
        );
      default:
        return null;
    }
  };

  const renderCellValue = (formSection, secondFieldIndex) =>
    formSection.sectionColNo > defaultColNo &&
    formSection.sectionFields[secondFieldIndex] ? (
      <>
        <td className="padding_12">{formSection.sectionFields[secondFieldIndex].label || ""}</td>
        <td>
          <div
             className={clsx("Cell_Value", {
              ["Gray_Cell_Value"]:
                isNewLayout &&
                hasNewLayout &&
                formSection.sectionFields[secondFieldIndex]?.fieldType === "readOnly",
            })}
          >
            {isClientForm
              ? returnClientFormFields(
                  formSection.sectionFields[secondFieldIndex]
                )
              : returnFieldInput(formSection.sectionFields[secondFieldIndex])}
          </div>
        </td>
      </>
    ) : null;

  return (
    <div
      className={
        hasNewLayout && isNewLayout ? "newLayoutClass" : "oldLayoutClass"
      }
    >
      <div
        id="KycForm"
        className={`KycForm ${classes || ""} ${
          isIndividual && hasNewLayout && isNewLayout ? "individualKycForm" : ""
        }`}
      >
        {Object.values(formMap).map((formSection) => (
          <table className="Form_Table" key={formSection.sectionLabel || ""}>
            <thead className="Spaced_Table_Header Space_After">
              <tr>
                <th>
                  <span>
                    <span className="Header_Title">
                      {formSection.sectionLabel || ""}
                    </span>
                    <span className="Header_Sub_Title">
                      {formSection.sectionSubLabel || ""}
                    </span>
                  </span>
                  {formSection.canAddRecord ? (
                    isNewLayout && hasNewLayout ? (
                      <div
                        className="add_Record"
                        onClick={() => addRowHandler(formSection)}
                      >
                        <CustomIconButton
                          variant="text"
                          size="none"
                          boxShadow="none"
                          color="tertiaryColor"
                          hideHoverBg
                        >
                          <PlusIcon
                            width="20"
                            height="20"
                            //
                            fill={!isDarkMode ? "#635235" : "#CECFD2"}
                          />
                        </CustomIconButton>

                        <div className="selectedCount">Add Record</div>
                      </div>
                    ) : (
                      <ButtonBase
                        className="Header_Button"
                        onClick={() => addRowHandler(formSection)}
                      >
                        <span className="mdi mdi-plus-circle-outline"></span>
                        <span>Add Record</span>
                      </ButtonBase>
                    )
                  ) : null}
                </th>
              </tr>
            </thead>
            <tbody>
              <table
                className={`Nested_Table  ${
                  formSection.sectionColNo === defaultColNo
                    ? "tow-col-table"
                    : ""
                } ${formSection.sectionKey}`}
              >
                <tbody>
                  {formSection.beforeText ? (
                    <tr>
                      <td
                        className="Row_Text"
                        colspan={`${formSection.sectionColNo || defaultColNo}`}
                      >
                        <p>
                          {formText[formSection.sectionKey]
                            ? formText[formSection.sectionKey]?.beforeText
                            : ""}
                        </p>
                      </td>
                    </tr>
                  ) : null}
                  {formSection.sectionFields
                    ? formSection.sectionFields.map((field, fieldIndex) => {
                        const numberOfColumns =
                          formSection.sectionColNo || defaultColNo;
                        const firstFieldIndex =
                          fieldIndex * (numberOfColumns / 2);
                        const secondFieldIndex =
                          fieldIndex * (numberOfColumns / 2) + 1;

                        return (
                          <>
                          <tr key={`field-${field.input}`}>
                            {formSection.sectionFields[firstFieldIndex] ? (
                              <>
                                <td className="padding_12">
                                  {formSection.sectionFields[firstFieldIndex]
                                    .label || ""}
                                </td>
                                <td>
                                  <div className="Cell_Value">
                                    {isClientForm
                                      ? returnClientFormFields(
                                          formSection.sectionFields[
                                            firstFieldIndex
                                          ]
                                        )
                                      : returnFieldInput(
                                          formSection.sectionFields[
                                            firstFieldIndex
                                          ]
                                        )}
                                  </div>
                                </td>
                              </>
                            ) : null}
                            {(isDesktop && isNewLayout && hasNewLayout || !(isNewLayout && hasNewLayout)) && renderCellValue(formSection,secondFieldIndex)}
                          </tr>
                            {!isDesktop && isNewLayout && hasNewLayout && renderCellValue(formSection,secondFieldIndex)}
                          </>
                        );
                      })
                    : null}
                  {formSection.canAddRecord ? (
                    <DynamicTableComponent
                      headerData={formSection.headerData || []}
                      data={selected[formSection.tableDataKey] || []}
                      tableKey={formSection.tableDataKey}
                      onCurrentNewRowChanged={(props) =>
                        onCurrentNewRowChanged(props, formSection.tableDataKey)
                      }
                      currentNewRow={currentNewRow}
                      fieldProps={{
                        optionsData,
                        onSelectedChange,
                        getLookupsByName,
                        getContactOptions,
                      }}
                      isClientForm={isClientForm}
                    />
                  ) : null}
                  {formSection.afterText ? (
                    <tr>
                      <td
                        className="Row_Text"
                        colspan={`${formSection.sectionColNo || defaultColNo}`}
                      >
                        <p>
                          {formText[formSection.sectionKey]
                            ? formText[formSection.sectionKey]?.afterText
                            : ""}
                        </p>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </tbody>
          </table>
        ))}
      </div>
    </div>
  );
};

FormTablesGenerator.propTypes = {
  formMap: PropTypes.object,
  formText: PropTypes.object,
  optionsData: PropTypes.object,
  selected: PropTypes.object,
  onSelectedChange: PropTypes.func,
  getLookupsByName: PropTypes.func,
  getContactOptions: PropTypes.func,
  isClientForm: PropTypes.bool,
}

FormTablesGenerator.defaultProps = {
    formMap:{},
    formText:{},
    optionsData:{},
    selected:{},
    onSelectedChange:()=>{},
    getLookupsByName:()=>{},
    getContactOptions:()=>{},
    isClientForm: false,
};