import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    iframeWrapper: {
      width: "100%",
      padding: "20px 0px 20px 0px",
      height: "515px",
      width: "100%",
    },
    imageWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.gray_50,
      padding: "20px 0px 20px 0px",
      height: "515px",
      width: "100%",
    },
    vedioWapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px 0px 20px 0px",
      height: "515px",
      width: "100%",
    },
    iframeStyle: {
      border: "none",
    },
  };
});
