import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogComponent,
  Inputs,
  LoadableImageComponant,
  LoadableVideoComponant,
  Spinner,
} from '../../../../../Components';
import { getMimeTypeHandler } from '../../../../../Utils';
import { getDownloadableLink, showError } from '../../../../../Helper';
import { TemplatesTypesEnum } from '../../../../../Enums';
import GoogleDocIframe from './Utilities/GoogleDocIframe';
import { useTranslation } from 'react-i18next';
import { LearningHubLocalePath } from '../../../LearningHub/i18n/LearningHub';
//fetch using fetch api?
export const MediaPreviewDialog = ({
  activeItem,
  isOpen,
  isOpenChanged,
  parentTranslationPath,
  translationPath,
  maintitleText,
}) => {
  const [mimeType, setMimeType] = useState(null);
  const { t } = useTranslation(LearningHubLocalePath);
  const iframeRef = useRef(null);
  const [fileRead, setFileRead] = useState(null);
  useEffect(() => {
    if (
      activeItem?.templateFileName &&
      /\.txt+$/.test(activeItem?.templateFileName)
    ) {
      const txtFile = new XMLHttpRequest();
      txtFile.open(
        'GET',
        getDownloadableLink(activeItem?.templateFileId),
        true
      );
      txtFile.onload = () => {
        if (txtFile.readyState === 4)
          if (txtFile.status === 200)
            setFileRead(txtFile.responseText.split('\n'));
      };
      txtFile.send(null);
    } else if (
      activeItem?.templateFileName &&
      /\.html+$/.test(activeItem?.templateFileName)
    ) {
      const txtFile = new XMLHttpRequest();
      txtFile.open(
        'GET',
        getDownloadableLink(activeItem?.templateFileId),
        true
      );
      // const blob = new Blob([getDownloadableLink(activeItem?.templateFileId)]);
      // oFReader.readAsBinaryString(blob);
      // oFReader.onload = (oFREvent) => {

      //   setFileRead(oFREvent.target.result);
      // };
      txtFile.onload = () => {
        if (txtFile.readyState === 4)
          if (txtFile.status === 200)
            iframeRef.current.innerHTML = txtFile.response;
      };
      txtFile.send(null);
    }
  }, [activeItem?.templateFileId, activeItem?.templateFileName]);

  useEffect(() => {
    const verify = async () => {
      try {
        const result = (
          await (
            await fetch(getDownloadableLink(activeItem.templateFileId))
          ).blob()
        ).type
          .split('/')
          .pop();
        setMimeType(result);
        return result;
      } catch (error) {
        showError(t('something_went_wrong'));
      }
    };
    verify();
  }, [activeItem]);
  
  if (!mimeType) {
    return <Spinner isActive isAbsolute />;
  }
  return (
    <DialogComponent
      titleText={maintitleText}
      maxWidth='lg'
      dialogContent={
        <div className='templates-preview-dialog'>
          {((!activeItem?.templateFileId || !activeItem?.templateFileName) && (
            <Inputs
              idRef='templateSMSRef'
              labelValue={
                (activeItem?.templateTypeId === TemplatesTypesEnum.SMS.key &&
                  'sms-text') ||
                (activeItem?.templateTypeId ===
                  TemplatesTypesEnum.Whatsapp.key &&
                  'whatsapp-text') ||
                ''
              }
              value={activeItem?.templateText || ''}
              multiline
              rows={6}
              isDisabled
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          )) ||
            (getMimeTypeHandler(activeItem?.templateFileName).isImage && (
              <div className='template-image-wrapper'>
                <LoadableImageComponant
                  classes='template-image'
                  alt={activeItem?.templateFileName}
                  src={getDownloadableLink(activeItem?.templateFileId)}
                />
              </div>
            )) ||
            (getMimeTypeHandler(activeItem?.templateFileName).isVideo && (
              <div className='template-image-wrapper'>
                <LoadableVideoComponant
                  classes='template-video'
                  alt={activeItem?.templateFileName}
                  // fileId={activeItem?.templateFileId}
                  src={getDownloadableLink(activeItem?.templateFileId)}
                />
              </div>
            )) ||
            (/\.txt+$/.test(activeItem?.templateFileName) && (
              <Inputs
                idRef='fileTextRef'
                labelValue='file-text'
                value={fileRead || ''}
                multiline
                rows={6}
                isDisabled
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            )) ||
            (/\.html+$/.test(activeItem?.templateFileName) && (
              <div className='template-iframe-wrapper' ref={iframeRef} />
            )) ||
            ((getMimeTypeHandler(activeItem?.templateFileName).isPdf ||
              mimeType === 'pdf') && (
              <GoogleDocIframe
                url={getDownloadableLink(activeItem?.templateFileId)}
              />
            )) || (
              <iframe
                title={activeItem?.templateFileName}
                width='100%'
                height='100%'
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${getDownloadableLink(
                  activeItem?.templateFileId
                )}`}
                style={{ minHeight: 500, border: 'none' }}
              />
            ) || (
              <div className='template-iframe-wrapper' ref={iframeRef}></div>
            )}
        </div>
      }
      saveType='button'
      isOpen={isOpen}
      onCancelClicked={isOpenChanged}
      onCloseClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
MediaPreviewDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  maintitleText: PropTypes.string,
};
MediaPreviewDialog.defaultProps = {
  activeItem: null,
  maintitleText: 'preview-template',
};
