import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    flex: {
      display: "flex",
      gap: "4px",
      height: "83px",
      padding: "16px",
      alignItems: "center",
      background: theme.palette.background.paper,
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    },
    actionsContainer: (props) => ({
      position: "fixed",
      right: isAr ? "unset" : props?.isDesktop ? "41px" : "25px",
      zIndex: 50,
    }),
    button: {
      minWidth: "fit-content",
      padding: "10px",
      borderRadius: "6px",
    },
    tooltip: {
      ...(isAr
        ? {
            minWidth: "100px",
            fontFamily: "Inter",
          }
        : {}),
      position: "absolute",
      top: "-38px",
      margin: "0 auto",
      maxWidth: "fit-content",
      backgroundColor: theme.palette.background.primary_solid,
      color: theme.palette.text.white,
      padding: "8px 12px",
      borderRadius: "8px",
      fontSize: "12px",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      zIndex: 1000,
      opacity: 0,
      transform: "translateY(20px)",
      transition: "opacity 0.3s ease, transform 0.3s ease",
      visibility: "hidden",
    },
    tooltipVisible: {
      visibility: "visible",
      opacity: 1,
      transform: "translateY(0)",
      whiteSpace: "nowrap",
    },
  };
});
