import React from 'react';
import { ContactValidationProvider } from './ContactValidationContext/ContactValidationContext';
import { PropertyValidationProvider } from './PropertyValidationContext/PropertyValidationContext';
import { UnitValidationProvider } from './UnitValidationContext/UnitValidationContext';
import DisabledLookupContextProvider from './DisabledLookupContext';

const ContextProvider = ({ children }) => {
  return (
    <ContactValidationProvider>
      <PropertyValidationProvider>
        <DisabledLookupContextProvider>
          <UnitValidationProvider>{children}</UnitValidationProvider>
        </DisabledLookupContextProvider>
      </PropertyValidationProvider>
    </ContactValidationProvider>
  );
};

export default ContextProvider;
