import React, { memo, useState } from "react";
import LayoutStyles from "../../UnitOverviewComponent.module.scss";
import Styles from "./MiddleSection.module.scss";
import Button from "@material-ui/core/Button";
import bathIcon from '../../../../../../../../../../assets/images/icons/bath.svg';
import bedIcon from '../../../../../../../../../../assets/images/Propxicon/side-bed.svg';
import markerPin from '../../../../../../../../../../assets/images/Propxicon/marker-pin.svg';
import areaIcon from '../../../../../../../../../../assets/images/icons/area.svg';

const MiddleSection = memo(({ data }) => {

  const [isShowAll, setIsShowAll] = useState(false);
  return (
    <div className={LayoutStyles.Content_Box}>
      <div className="d-flex-v-center-h-between">
        <p>{data.marketingTitle || null}</p>
        {/* <Button
          className={`btns theme-propx solid ${LayoutStyles.blueBtnTheme}`}
          id="btn-save"
          onClick={() => "saveHandler()"}
        >
          {`Floor Plan`}
        </Button> */}
      </div>
      <div className={Styles.Header}>
        <p className={Styles.Header_Title}>Details</p>
        <Button
          className={`btns theme-propx outlined ${Styles.Icon_Button}`}
          id="btn-save"
          onClick={() => setIsShowAll(prevState => !prevState)}
        >
          {isShowAll ?
            <span className="mdi mdi-chevron-down"></span> :
            <span className="mdi mdi-chevron-up"></span>
          }
        </Button>
      </div>

      <div className="d-flex-center gap-32">
        <div className={Styles.Info_box}>
          <span><img src={areaIcon} alt="area-Icon" /></span>
          <p>
            <span>{`${data.size || "-"} sqft`}</span>
          </p>
        </div>
        <div className={Styles.Info_box}>
          <span><img src={bedIcon} alt="bed-Icon" /></span>
          <p>
            <span>{`${data.bedroom || "-"} Bedrooms`}</span>
          </p>
        </div>
        <div className={Styles.Info_box}>
          <span><img src={bathIcon} alt="bath-Icon" /></span>
          <p>
            <span>{`${data.bathroom || "-"} Bathrooms`}</span>
          </p>
        </div>
        <div className={Styles.Info_box}>
          <span className="mdi mdi-car"></span>
          <p>
            <span>{`${data.numberOfParkingSpaces || "-"} Parking Spaces`}</span>
          </p>
        </div>
        <div className={Styles.Info_box}>
          <span className="mdi mdi-currency-usd"></span>
          <p>
            <span>{`${data.pricePerSqft || "-"} AED/sqft`}</span>
          </p>
        </div>
      </div>
      {(data.country || data.community || data.subCommunity) ?
        <div className={Styles.Location_Text}>
          <span><img src={markerPin} alt="markerPin-Icon" /></span>
          <span>
            {`${data.country ? `${data.country}` : ''}`}
            {`${data.community ? `, ${data.community}` : ''}`}
            {`${data.subCommunity ? `, ${data.subCommunity}` : ''}`}
          </span>
        </div> : null}

      {isShowAll ? <div className={Styles.Marketing_Desc}>
        {data.marketingDescription || null}
      </div> : null}
    </div>
  );
});

export default MiddleSection;
