import React from 'react';
import { TableFilterOperatorsEnum, TableFilterTypesEnum } from '../../../Enums';
import ShowMore from '../../../SharedComponents/ShowMore/ShowMore';
import { PaymentPlansLocalePath } from './i18n/PaymentPlansLocale';

export const paymentPlanHeaderData = [
  {
    label: 'plan_name',
    input: 'paymentPlanName',
    filterType: TableFilterTypesEnum.textInput.key,
    withoutOperators: true,
  },
  {
    label: 'down_payment',
    input: 'downPayment',
    filterType: TableFilterTypesEnum.numberInput.key,
    operators: [
      {
        key: TableFilterOperatorsEnum.equal.key,
      },
      {
        key: TableFilterOperatorsEnum.lessThan.key,
      },
      {
        key: TableFilterOperatorsEnum.greaterThan.key,
      },
    ],
    textInputType: 'number',
    inputPlaceholder: '%',
  },
  {
    label: 'monthly_installment',
    input: 'monthlyInstallment',
    component: (row) => (
      <ShowMore
        text={row.monthlyInstallment}
        title={'installments'}
        parentTranslationPath={PaymentPlansLocalePath}
      />
    ),
  },
  {
    label: 'handover_payment',
    input: 'handoverPayment',
    searchableKey: 'handoverInstallment',
    filterType: TableFilterTypesEnum.numberInput.key,
    operators: [
      {
        key: TableFilterOperatorsEnum.equal.key,
      },
      {
        key: TableFilterOperatorsEnum.lessThan.key,
      },
      {
        key: TableFilterOperatorsEnum.greaterThan.key,
      },
    ],
    textInputType: 'number',
    inputPlaceholder: '%',
  },
  {
    label: 'post_handover',
    input: 'postHandover',
  },
  {
    label: 'specific_developer',
    input: 'specificDeveloper',
    component: (row) =>
      row.paymentPlanDevelopers
        ?.map((developer) => developer.developerName)
        .join(', ') || 'General',
    filterType: TableFilterTypesEnum.textInput.key,
    withoutOperators: true,
    searchableKey: 'developerName',
  },
  {
    label: 'status',
    input: 'statusName',
    filterType: TableFilterTypesEnum.selectOption.key,
    searchableKey: 'propertyPlanStatus',
    optionFilterList: [
      { key: 1, value: 'Active' },
      { key: 2, value: 'Inactive' },
    ],
  },
];

export const propertyPaymentPlanHeaderData = paymentPlanHeaderData.filter(
  (item) => item.input !== 'specificDeveloper'
);

export const paymentPlansFilterData = paymentPlanHeaderData.map((item) => ({
  ...item,
  displayPath: item.searchableKey ?? item.input,
  searchableKey: item.searchableKey ?? item.input,
  component: undefined,
}));

export const propertyPaymentPlanFilterData = paymentPlansFilterData.filter(
  (item) => item.input !== 'specificDeveloper'
);
