export const LeaseTransactionsWithAMLPermissions = {
  ViewLeasesTransactionsWithAML: {
    permissionsId: "c81870fe-9219-42cd-ec38-08dd2fb527c1",
    permissionsName: "View Leases transactions with AML",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewTransactionDetails: {
    permissionsId: "9b419c49-304a-440f-ec39-08dd2fb527c1",
    permissionsName: "View transaction Details",
    description: null,
    componentsId: null,
    components: null,
  },
  EditTransactions: {
    permissionsId: "7ed3e245-1fc1-4510-ec3a-08dd2fb527c1",
    permissionsName: "Edit transactions",
    description: null,
    componentsId: null,
    components: null,
  },
};
