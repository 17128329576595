

import { GetAllActivityTypes } from '../../../../../../../Services' ;

export async function ActivitiesRule(item, value,setItems , setIsLoading) {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'activities') return;
  setIsLoading(true);

  const rs = await GetAllActivityTypes();
  item.data.enum = [];
  if ((rs && rs.data && rs.data.ErrorId) || !rs ){
    setIsLoading(false);
    return;

  }
  rs.map((element) => {
    item.data.enum.push({
      id: element.activityTypeId,
      name: element.activityTypeName,
    });
  });

  setItems(item.data.enum);
  setIsLoading(false);
}