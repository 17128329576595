import React from 'react';
import { Tables } from '../../../../Components';
import { PaymentPlansLocalePath } from '../i18n/PaymentPlansLocale';

const PaymentPlansTable = ({
  data,
  isLoading,
  pagination,
  onPageIndexChanged,
  onPageSizeChanged,
  actions,
  headerData,
  onFilterValuesChanged,
  filterData,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  filterValues,
  onActionClicked,
}) => {
  return (
    <Tables
      isLoading={isLoading}
      headerData={headerData}
      data={data.result}
      totalItems={data.totalCount}
      parentTranslationPath={PaymentPlansLocalePath}
      itemsPerPage={pagination.pageSize}
      activePage={pagination.pageIndex - 1}
      onPageIndexChanged={onPageIndexChanged}
      onPageSizeChanged={onPageSizeChanged}
      defaultActions={actions}
      actionsOptions={{ onActionClicked }}
      isWithFilter
      filterData={filterData}
      onFilterValuesChanged={onFilterValuesChanged}
      isClearFiltersClicked={isClearFiltersClicked}
      setIsClearFiltersClicked={setIsClearFiltersClicked}
      filterValues={filterValues}
    />
  );
};

export default PaymentPlansTable;
