import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  reducer,
} from "react";
import { ButtonBase } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Spinner,
  Tables,
  Inputs,
  AutocompleteComponent,
  DatePickerComponent,
} from "../../../../../Components";
import { GetParams } from "../../../../../Helper";
import {
  GetDeveloperProjectsAPI,
  GetLookupItemsByLookupTypeName,
} from "../../../../../Services";
import moment from "moment";
import { TableFilterTypesEnum } from "../../../../../Enums";

export const DeveloperProfileProjectOverviewComponent = ({
  id,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);  
  const [isLoading, setIsLoading] = useState(false);
  const [filterValues, setFilterValues] = useState(null);
  const [developerId, setDeveloperId] = useState(+GetParams("id"));
  const searchTimer = useRef(null);
  const [projectsOverview, setProjectsOverview] = useState({
    result: [],
    totalCount: 0,
  });
  const [allStatus, setAllStatus] = useState({ result: [], isLoading: false });
  const [contactLeadsList, setContactLeadsList] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    developerId: +GetParams("id"),
    pageIndex: 0,
    propertyId: null,
    subCommunity: null,
    handoverDate: null,
    launchDate: null,
    status: null,
  });
  const [tableFilterData, setTableFilterData] = useState([]);
  const HeaderDataTable = [
    {
      id: 1,
      label: t(`${translationPath}propertyId`),
      isHiddenFilter: false,
      input: "propertyId",
      isDefaultFilterColumn: true,
      isHiddenFilter: false,
      component: (item) => <span>{item && item.propertyId}</span> || "",
    },
    {
      id: 2,
      label: t(`${translationPath}property-name`),
      input: "propertyName",
      isHiddenFilter: true,
    },
    {
      id: 3,
      label: t(`${translationPath}sub-community-name`),
      input: "subCommunity",
      component: (item) => <span>{item && item.subCommunity}</span> || "",
      isHiddenFilter: false,
    },
    {
      id: 4,
      label: t(`${translationPath}hand-over-date`),
      input: "handoverDate",
      isHiddenFilter: false,
      isDate: true,
      component: (item) =>
        (
          <span>
            {item &&
              item.handOverDate &&
              moment(item.handOverDate).format("YYYY-MM-DD")}
          </span>
        ) || "",
    },
    {
      id: 5,
      label: t(`${translationPath}launch-date`),
      input: "launchDate",
      isHiddenFilter: false,
      isDate: true,
      component: (item) =>
        (
          <span>
            {item &&
              item.launchDate &&
              moment(item.launchDate).format("YYYY-MM-DD")}
          </span>
        ) || "",
    },
    {
      id: 7,
      label: t(`${translationPath}status`),
      input: "status",
      isHiddenFilter: false,
      withSelectFilter: true,
      component: (item) => <span>{item && item.status}</span> || "",
    },
    {
      id: 8,
      label: t(`${translationPath}number-of-agents`),
      input: "numberOfAgents",
      isHiddenFilter: true,
    },
    {
      id: 9,
      label: t(`${translationPath}total-number-of-units`),
      input: "totalNumberOfUnits",
      isHiddenFilter: true,
    },
    {
      id: 10,
      label: t(`${translationPath}number-of-sold-units`),
      input: "numberOfSoldUnits",
      isHiddenFilter: true,
    },
    {
      id: 11,
      label: "number-of-remaining-units",
      input: "numberOfRemainingUnits",
      isHiddenFilter: true,
    },
    {
      id: 12,
      label: "transaction-value",
      input: "transactionValue",
      isHiddenFilter: true,
    },
  ];
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const GetAllDeveloperProjects = useCallback(async (searchObj) => {
    setIsLoading(true);
    const res = await GetDeveloperProjectsAPI(
      searchObj
        ? {
            ...searchObj,
            pageIndex: searchObj.pageIndex + 1,
          }
        : {
            ...filter,
            pageIndex: filter.pageIndex + 1,
          }
    );
    setIsLoading(false);

    if (!(res && res.status && res.status !== 200)) {
      setProjectsOverview({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setProjectsOverview({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, []);

  const GetAllProjectStatus = async () => {
    setAllStatus((item) => ({
      ...item,
      isLoading: true,
    }));
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Property Plan",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setAllStatus({
        result:
          (res && res.result).map((item) => ({
            key: item.lookupItemId,
            value: item.lookupItemName,
          })) || [],
        isLoading: false,
      });
    else
      setAllStatus({
        result: [],
        isLoading: false,
      });
  };

  const onFilterValuesChanged = (newValue) => {
    
    const localFilterDto = filter || {};
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.displayPath])
            localFilterDto[item.displayPath] =
              item?.value && item.value !== "" ? item.value : null;
          else if (item.value)
            localFilterDto[item.displayPath] =
              item?.value && item.value !== "" ? item.value : null;
          return undefined;
        });
    }    
    setFilter(() => ({
      ...filter,
      ...localFilterDto,
      selectedStatus: localFilterDto.status
        ? {
            key: localFilterDto.status,
            value: allStatus.result.find(
              (item) => item.key === localFilterDto.status
            )?.value,
          }
        : null,
    }));
  };

  useEffect(() => {
    GetAllDeveloperProjects();
    GetAllProjectStatus();
  }, [GetAllDeveloperProjects]);

  useEffect(() => {
    setTableFilterData(
      HeaderDataTable.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
      }))
    );
  }, []);

  useEffect(() => {
    if (filterValues && Object.keys(filterValues).length > 0) {
      Object.values(filterValues).map((item, index) => {
        setFilter((i) => ({
          ...i,
          [item.displayPath]: item.value,
        }));
      });
    }
  }, [filterValues]);

  

  return (
    <div className="w-100">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="developers-tab">
        <div className="header-section-Contact-Activity">
          <div className="filter-section px-2">
            <div className="developers-tab-filters-section">
              <div className="d-inline-flex flex-h-between w-100 mb-2">
                <div className="w-25 p-relative px-2">
                  <Inputs
                    idRef="propertyIdRef"
                    type="number"
                    min={0}
                    value={filter.propertyId || ""}
                    inputPlaceholder={t(`${translationPath}enter-property-id`)}
                    onInputChanged={(event) => {
                    let   newValue =  (event.target.value && event.target.value !== "" && +event.target.value) || null;
                      setFilter({
                        ...filter,
                        propertyId: newValue ,  
                      });
                      setFilterValues({
                        ...filterValues,
                        propertyId: {
                          value: newValue,
                          operator: 1,
                          displayPath: "propertyId",
                          searchableKey: "propertyId",
                        },
                      });
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
                <div className="w-25 p-relative px-2">
                  <Inputs
                    idRef="subCommunityRef"
                    value={filter.subCommunity || ""}
                    inputPlaceholder={t(
                      `${translationPath}enter-sub-community`
                    )}
                    onInputChanged={(event) => {
                      let   newValue =  (event.target.value && event.target.value !== "" && event.target.value) || null;
                        setFilter({
                          ...filter,
                          subCommunity: newValue || null,
                        });
                        setFilterValues({
                          ...filterValues,
                          subCommunity: {
                            value: newValue,
                            operator: 1,
                            displayPath: "subCommunity",
                            searchableKey: "subCommunity",
                          },
                        });
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
                <div className="w-50 p-relative px-1">
                  <AutocompleteComponent
                    idRef="statusRef"
                    inputPlaceholder={t(`${translationPath}status`)}
                    selectedValues={filter?.selectedStatus || null}
                    data={allStatus.result || []}
                    multiple={false}
                    displayLabel={(option) => (option && option.value) || ""}
                    chipsLabel={(option) => (option && option.value) || ""}
                    withoutSearchButton
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setFilter((item) => ({
                        ...item,
                        status: newValue && newValue.key,
                        selectedStatus: newValue,
                      }));
                      setFilterValues({
                        ...filterValues,
                        status: {
                          value: newValue?.key || null,
                          operator: 1,
                          displayPath: "status",
                          searchableKey: "status",
                        },
                        selectedStatus: {
                          value: newValue || null,
                          operator: 1,
                          displayPath: "selectedStatus",
                          searchableKey: "selectedStatus",
                        },
                      });
                    }}
                    isLoading={allStatus.isLoading}
                  />
                </div>
              </div>
              <div className="d-inline-flex flex-h-between w-100 mb-2">
                <div className="w-50 p-relative px-1">
                  <DatePickerComponent
                    idRef="RealeaseDateRef"
                    value={filter.launchDate || null}
                    placeholder={t(`${translationPath}launch-date`)}
                    onDateChanged={(event) => {
                      setFilter({
                        ...filter,
                        launchDate: event || null,
                      });
                      setFilterValues({
                        ...filterValues,
                        launchDate: {
                          value: event || null,
                          operator: 1,
                          displayPath: "launchDate",
                          searchableKey: "launchDate",
                        },
                      });
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
                <div className="w-50 p-relative px-1">
                  <DatePickerComponent
                    idRef="RealeaseDateRef"
                    value={filter.handoverDate || null}
                    placeholder={t(`${translationPath}handover-date`)}
                    onDateChanged={(event) => {
                      setFilter({
                        ...filter,
                        handoverDate:
                          (event &&
                            moment(event) &&
                            moment(event)
                              .startOf("day")
                              .format("YYYY-MM-DD")) ||
                          null,
                      });
                      // if(!event &&  filterValues && filterValues.handoverDate){
                      //   setFilterValues({
                      //     ...filterValues,
                      //     value: '', 
                      //     operator: 1,
                      //     displayPath: "handoverDate",
                      //     searchableKey: "handoverDate",
                      //   });
                      //   return ; 

                      // }
                      setFilterValues({
                        ...filterValues,
                        handoverDate: {
                          value:
                            (event &&
                              moment(event) &&
                              moment(event)
                                .startOf("day")
                                .format("YYYY-MM-DD")) ||
                            null,
                          operator: 1,
                          displayPath: "handoverDate",
                          searchableKey: "handoverDate",
                        },
                      });
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100  px-4 d-flex-center fj-end mb-1">
          <span className="">
            <ButtonBase
              onClick={() => {
                GetAllDeveloperProjects({ ...filter, pageIndex: 0 });
                setIsClearFiltersClicked(false);
              }}
              id="filterBtn"
              className={`px-2 btns theme-solid`}
            >
              <span className={`mdi mdi-arrow-expand-left m-1`} />
              {t(`${translationPath}apply-filters`)}
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                const clearFilter = {
                  ...filter,
                  pageIndex: 0,
                  propertyId: null,
                  subCommunity: null,
                  handoverDate: null,
                  launchDate: null,
                  status: null,
                  selectedStatus: null,
                };
                setIsClearFiltersClicked(true);
                setFilter(clearFilter);
                setFilterValues(null);
                GetAllDeveloperProjects(clearFilter);
              }}
              id="onClearedAllFiltersref"
              className="btns px-2 theme-solid bg-danger clear-all-btn"
            >
              <span className="mdi mdi-filter-remove m-1" />
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
          </span>
        </div>
        <div className="w-100 px-3 mt-2">
          <Tables
            data={projectsOverview.result || []}
            headerData={HeaderDataTable}
            filterValues={filterValues}
            onFilterValuesChanged={onFilterValuesChanged}
            filterData={tableFilterData}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            totalItems={(projectsOverview && projectsOverview.totalCount) || 0}
            defaultActions={[]}
            isWithFilter
            isClearFiltersClicked={isClearFiltersClicked}
            setIsClearFiltersClicked={setIsClearFiltersClicked}
            FilterDisabledButton
            optionFilterName={allStatus?.result || []}
            withDateFilter
            
          />
        </div>
      </div>
    </div>
  );
};

DeveloperProfileProjectOverviewComponent.propTypes = {
  id: PropTypes.number.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
