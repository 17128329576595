import React, { memo, useEffect, useMemo, useState } from "react";
import LayoutStyles from "../../UnitOverviewComponent.module.scss";
import Styles from "./SideSection.module.scss";
import Button from "@material-ui/core/Button";
import { Avatar, ButtonBase } from "@material-ui/core";
import { getDownloadableLink, getFirstLastNameLetters, showError, WhatsAppMessage, MaqsamTokenServicesAPI, GetParams } from "../../../../../../../../../../Helper";
import { CopyToClipboardComponents } from "../../../../../../../../../../ReusableComponents/UtilityComponents";
import { ActionsEnum } from "../../../../../../../../../../Enums";
import { CreateInquiryCommunicationActivity } from "../../../../../../../../../../Services";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
// import { ContactsActionDialogsComponent } from "../../../../../../../../ContactsView/ContactsUtilities/ContactsActionDialogsComponent/ContactsActionDialogsComponent";

const SideSection = memo(({ data , unitId, inquiryId, isLease }) => {
  const location = useLocation()
  isLease =
    isLease !== undefined ? isLease : location.pathname.includes('lease');
  
  const [showAll, setShowAll] = useState({
    views: false,
    fixtures: false,
  });

  const [isActionDialogOpen, setIsActionDialogOpen] = useState(false);
  const [activeAgentAction, setActiveAgentAction] = useState(null);

  const showAllHandler = (section) => {
    setShowAll((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const viewsCapsulesMapper = () => {
    if (data.unitVeiw && data.unitVeiw.length > 0) {
      const viewsList = data.unitVeiw.split(",");
      return showAll.views ? viewsList : viewsList.slice(0, 4);
    }
  };

  const fixturesCapsulesMapper = () => {
    if (data.fixtures && data.fixtures.length > 0) {
      const fixturesList = data.fixtures;
      return showAll.fixtures ? fixturesList : fixturesList.slice(0, 4);
    }
  };

  const listingAgentMapper = () => {
    return isLease
      ? data?.rentListingAgent
      : data?.listingAgentDto;
  };

  const views = useMemo(() => viewsCapsulesMapper(), [data, showAll]);
  const fixtures = useMemo(() => fixturesCapsulesMapper(), [data, showAll]);
  const listingAgent = useMemo(() => listingAgentMapper(), [data]);

  const onActionClicked = async (actionEnum) => {
    setActiveAgentAction(actionEnum);

    if (actionEnum?.key === ActionsEnum.whatsappSolid.key) {
      try {
        await CreateInquiryCommunicationActivity({
          isLeaseInquiry: isLease,
          isCall: false,
          inquiryId: inquiryId ??  +GetParams('id'),
          unitId,
        });
        window.open(WhatsAppMessage(listingAgent.whatsAppNumber), '_blank')
      } catch {
        showError(`Failed To Open WhatsApp..`);
      }
    } else if (actionEnum?.key === ActionsEnum.phoneSolid.key) {
      // MaqsamTokenServicesAPI(listingAgent.mobileNumber);
    } else if (actionEnum?.key === 'emailSolid') {
      // setActiveAgentAction(actionEnum);
      // setIsActionDialogOpen(true);
    } 

  }

  return (
    <>
      <div className={Styles.Content_Box}>
        <div>
          <p>View</p>
        </div>
        {views ? (
          <div className={LayoutStyles.Capsules_container}>
            {views.map((item) => (
              <div className={LayoutStyles.Outlined_capsule__gray}>
                <span>{item}</span>
              </div>
            ))}
          </div>
        ) : (
          "No views to preview ."
        )}

        {views && !showAll.views ? (
          <Button
            className="btns theme-propx outlined"
            id="btn-save"
            onClick={() => showAllHandler("views")}
          >
            {`Show all`}
          </Button>
        ) : null}
      </div>
      <div className={Styles.Content_Box}>
        <div>
          <p>What this place offers</p>
        </div>
        {fixtures ? (
          <div className={LayoutStyles.Capsules_container}>
            {fixtures.map((item) => (
              <div className={LayoutStyles.Outlined_capsule__gray}>
                <span>{item}</span>
              </div>
            ))}
          </div>
        ) : (
          "No fixtures to preview ."
        )}

        {fixtures && !showAll.fixtures ? (
          <Button
            className="btns theme-propx outlined"
            id="btn-save"
            onClick={() => showAllHandler("fixtures")}
          >
            {`Show all`}
          </Button>
        ) : null}
      </div>
      {listingAgent ? (
        <div className={Styles.Content_Box}>
          <div>
            <p>Listing agent details</p>
          </div>
          <div className="d-flex-v-center">
            <Avatar className={Styles.Avatar} src={
              listingAgent?.profileImage
                ? getDownloadableLink(listingAgent.profileImage)
                : ""
            }>
              {getFirstLastNameLetters(listingAgent.name || "")}
            </Avatar>
            <div className={Styles.Avatar_Text}>
              <span>{listingAgent.name || "-"}</span>
            </div>
          </div>
          <div className={Styles.Info_Pair}>
            <p>
              <span>Mobile</span>
            </p>
            <p>
              <span>
                {listingAgent.mobileNumber ? (
                  <CopyToClipboardComponents
                    data={listingAgent.mobileNumber}
                    childrenData={listingAgent.mobileNumber}
                  />
                ) : "-"}
              </span>
            </p>
          </div>
          <div className={Styles.Info_Pair}>
            <p>
              <span>Email</span>
            </p>
            <p>
              <span>
                {listingAgent.email ? (
                  <CopyToClipboardComponents
                    data={listingAgent.email}
                    childrenData={listingAgent.email}
                  />
                ) : "-"}
              </span>
            </p>
          </div>
          <div className={Styles.Info_Pair}>
            <p>
              <span>Whatsapp</span>
            </p>
            <p>
              <span>
                {listingAgent.whatsAppNumber ? (
                  <CopyToClipboardComponents
                    data={listingAgent.whatsAppNumber}
                    childrenData={listingAgent.whatsAppNumber}
                  />
                ) : "-"}
              </span>
            </p>
          </div>
          {
            (
              <div className={Styles.Actions_box}>
                {listingAgent.mobileNumber ?
                  (
                    <ButtonBase
                      className={`${Styles.btn_phone} ${ActionsEnum.phoneSolid.buttonClasses}`}
                      onClick={() => onActionClicked(ActionsEnum.phoneSolid)}>
                      <span className={ActionsEnum.phoneSolid.icon} />
                    </ButtonBase>
                  ) : null
                  }
                {/* {listingAgent.email ?
                  (
                    <ButtonBase
                      className={`${Styles.btn_email} ${ActionsEnum.emailSolid.buttonClasses}`}
                      onClick={() => onActionClicked(ActionsEnum.emailSolid)}>
                      <span className={ActionsEnum.emailSolid.icon} />
                    </ButtonBase>
                  ) : null} */}
                {listingAgent.whatsAppNumber ?
                  (
                    <ButtonBase
                      className={`${Styles.btn_whatsapp} ${ActionsEnum.whatsappSolid.buttonClasses}`}
                      onClick={() => onActionClicked(ActionsEnum.whatsappSolid)}>
                      <span className={ActionsEnum.whatsappSolid.icon} />
                    </ButtonBase>
                  ) : null}
              </div>
            )}
        </div>
      ) : null}

      {/* <div className={Styles.Content_Box}>
        <div>
          <p>Developer details</p>
        </div>
        <div className="d-flex-v-center">
          <Avatar
            className={Styles.Avatar}
            src={
              ""
            }
          >
          </Avatar>
          <div className={Styles.Avatar_Text}>
            <span>{"EMAAR" || "-"}</span>
            <span>{"https://www.emaar.com/" || "-"}</span>
          </div>
        </div>

        <div className={Styles.Info_Pair}>
          <p>
            <span>Landline</span>
          </p>
          <p>
            <span>97143661688</span>
            <span></span>
          </p>
        </div>
        <div className={Styles.Info_Pair}>
          <p>
            <span>Email</span>
          </p>
          <p>
            <span>J.alali@propx.com</span>
            <span></span>
          </p>
        </div>
      </div> */}

      {/* { isActionDialogOpen ?
        <ContactsActionDialogsComponent
          isOpen={isActionDialogOpen}
          isOpenChanged={() => setIsActionDialogOpen(false)}
          actionEnum={activeAgentAction?.key}
          item={activeAgentAction}
          translationPath=''
          parentTranslationPath='ContactsView'
        />
        : null} */}
    </>
  );
});

export default SideSection;
