import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  filtersSection: {
    flexWrap: "wrap",
    borderRadius: "8px 8px 0 0",
    border: `1px solid ${theme.palette.border.secondary}`,
    display: "flex",
    padding: "9px 24px",
    gap: "16px",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.background.primary,
    direction: "ltr",

    "& label": {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
    },
  },
  flexRow: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    flexFlow: "wrap",
  },
  flex: {
    display: "flex",
    flexFlow: "wrap",
  },
  actions: {
    display: "flex",
    flexFlow: "wrap",

    [theme.breakpoints.down("426")]: {
      gap: "12px",
    },
  },
  noBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));
