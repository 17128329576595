import React from 'react';
import InstallmentItem from './InstallmentItem';
import { PaymentPlansLocalePath } from '../i18n/PaymentPlansLocale';
import { Tables } from '../../../../Components';
import { TableActions } from '../../../../Enums';
import { isDate } from 'lodash';
import { useTranslation } from 'react-i18next';
import { actionTypes } from '../constants';

const InstallmentsDetails = ({ installments, dispatch }) => {
  const { t } = useTranslation(PaymentPlansLocalePath);
  const onActionClicked = (_enumKey, item) => {
    dispatch({
      value: item.id,
      action: actionTypes.REMOVE,
      nestedField: 'id',
      field: 'installment',
    });
  };
  return (
    <div className='p-3 flex gap-1 flex-col'>
      <InstallmentItem dispatch={dispatch} />
      <Tables
        parentTranslationPath={PaymentPlansLocalePath}
        headerData={[
          {
            label: 'installment %',
            input: 'amountPercentage',
            component: (row) => `${row.amountPercentage * 100}%`,
          },
          {
            label: 'frequency',
            input: 'frequencyId',
            component: (row) =>
              isDate(new Date(row.frequencyValue)) && !+row.frequencyValue
                ? t('by_fixed_date')
                : t('by_month'),
          },
          { label: 'number_of_frequency', input: 'frequencyValue' },
          { label: 'number_of_installment', input: 'numberOfInstallment' },
        ]}
        bodyRowId={'installment'}
        activePage={1}
        defaultActions={[{ enum: TableActions.delete.key }]}
        actionsOptions={{ onActionClicked }}
        data={installments}
      />
    </div>
  );
};

export default InstallmentsDetails;
