import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
    return {
        textAreaInput: {
            width: "100%",
            border: `1px solid ${theme.palette.border.primary}`,
            borderRadius: "8px",
            padding: "12px",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            color: theme.palette.text.placeholder,
            resize: "none",

            "&:focus": {
                borderColor: theme.palette.utility.brand_300,
                boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(140, 115, 75, 0.24)`,
            },
        },
    };
});