import React from "react";

function DownloadCloudIcon01({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M7.217 1.703a7.188 7.188 0 0 0-4.302 2.045A7.127 7.127 0 0 0 .869 8.114c-.168 1.721.374 3.581 1.441 4.953.27.346.588.69.72.777a.838.838 0 0 0 1.158-.245c.07-.105.092-.187.104-.378.021-.313-.041-.457-.33-.771-.959-1.041-1.461-2.311-1.461-3.7 0-2.082 1.158-3.929 3.057-4.878 1.215-.607 2.813-.705 4.114-.252 1.153.401 2.086 1.13 2.784 2.174.291.436.459.61.698.723.227.108.415.148.696.149 1.023.004 2.13.551 2.806 1.385.68.841.967 1.909.795 2.966-.134.826-.489 1.488-1.151 2.146-.323.323-.368.381-.417.551a.818.818 0 0 0 .529 1.019c.167.054.47.029.638-.051.186-.089.859-.773 1.107-1.126a5.423 5.423 0 0 0 .67-4.989c-.684-1.887-2.434-3.286-4.38-3.503-.545-.06-.528-.055-.595-.169-.155-.263-.599-.809-.919-1.13a7.119 7.119 0 0 0-3.966-2.014c-.44-.066-1.356-.091-1.75-.048m2.551 7.501a.905.905 0 0 0-.564.579c-.026.093-.037.98-.037 2.916v2.782l-.992-.987c-.637-.633-1.045-1.014-1.142-1.064-.396-.205-.917-.02-1.122.4-.088.179-.079.513.019.703.107.21 3.494 3.597 3.703 3.704a.88.88 0 0 0 .734 0c.209-.107 3.596-3.494 3.703-3.704a.832.832 0 0 0-.158-.945.831.831 0 0 0-.945-.158c-.097.05-.505.431-1.141 1.064l-.991.985-.009-2.848c-.009-2.841-.009-2.848-.081-2.981-.195-.364-.615-.556-.977-.446"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default DownloadCloudIcon01;
