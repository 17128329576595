import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isEqual } from "lodash"; // Importing lodash for deep equality comparison
import { ContactLayoutContext } from "../../../../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";

import { leadTableHeaderData } from "./leadTableHeaderData";
import {
  BasicTable,
  TableSkeleton,
  CustomPagination,
  CustomTableHeader,
  NoDataFound,
  LeadActionButtons,
} from "../../../../../../Components";
import {
  LeadsAdvanceSearchTest,
  ReassignLeads,
  CloneLeads,
  CloseListOfLeads,
} from "../../../../../../Services";
import {
  LeadsStatusEnum,
  LeadTypeIdEnum,
  AgentRoleEnum,
} from "../../../../../../Enums";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { ContactAddNewLeadDialog } from "../ContactAddNewLeadDialog";
import {
  ContactCloneLeadsDialog,
  ContactCloseLeadsDialog,
  ContactReassignLeadDialog,
  ContactUnqualifiedLeadsDialog,
} from "./UI";

//styles
import useStyles from "./styles";

const ContactLeads = forwardRef(({ contactId, translate }, ref) => {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [leadsData, setLeadsData] = useState({
    result: [],
    totalCount: 0,
  });
  const { setAlertBoxContent } = useVerticalNav();
  const { actionableItems, setActionableItems } =
    useContext(ContactLayoutContext);
  const { isDarkMode } = useSelector((state) => state.theme);
  const [activeItem, setActiveItem] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: 25,
    pageIndex: 1,
    leadStatus: null,
  });
  const [addNewLeadDialog, setAddNewLeadDialog] = useState(false);
  const [inputValue, setInputValue] = useState(pagination.pageIndex);

  const [criteriaFilter, setCriteriaFilter] = useState({
    contactId: [{ searchType: 1, value: contactId }],
  });

  const getAllLeads = async () => {
    setIsLoading(true);

    // Initialize criteriaFilter with the existing state
    const filter = { ...criteriaFilter };

    // Update contactId state if necessary
    filter.contactId = [{ searchType: 1, value: contactId }];

    // Update lead status filter if it exists
    if (pagination?.leadStatus) {
      filter["status.lookupItemName"] = [
        { searchType: 1, value: pagination?.leadStatus },
      ];
    }

    if (actionableItems?.action === "clone") {
      filter["status.lookupItemName"] = [
        { searchType: 1, value: LeadsStatusEnum.Closed.key },
      ];
    } else if (actionableItems?.action) {
      filter["status.lookupItemName"] = [
        { searchType: 1, value: LeadsStatusEnum.Open.key },
      ];
    }

    // Adjust the lead type based on the action type
    if (
      actionableItems?.action === "reassign-seeker-lead" ||
      actionableItems?.action === "clone"
    ) {
      filter["lead_type_id"] = [
        { searchType: 1, value: LeadTypeIdEnum.Seeker.leadTypeId },
      ];
    } else if (actionableItems?.action === "reassign-owner-lead") {
      filter["lead_type_id"] = [
        { searchType: 1, value: LeadTypeIdEnum.Owner.leadTypeId },
      ];
    }

    const body = {
      criteria: filter,
      filterBy: "createdOn",
      orderBy: 2,
    };

    const list = [];
    let res = await LeadsAdvanceSearchTest(
      {
        pageIndex: pagination.pageIndex - 1,
        pageSize: pagination.pageSize,
      },
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      res &&
        res.result &&
        res.result.forEach((value) => {
          const leadJson = JSON.parse(value.leadJson);
          list.push({
            createdOn: value?.createdOn,
            createdBy: value?.createdBy,
            id: value.leadId,
            leadId: value.leadId,
            leadClass: leadJson?.lead?.leadClass,
            leadTypeId: leadJson?.lead?.lead_type_id,
            leadUnitType: value?.leadUnitType,
            matchUnit: value?.matchUnit || 0,
            name: leadJson?.lead?.contact_name?.name,
            noActivities: value?.noActivities,
            numberOfActivities: leadJson?.lead?.Number_Of_Activities || 0,
            referredto: leadJson?.lead?.referredto,
            status: leadJson?.lead?.status,
            rating: leadJson?.lead?.rating,
            media_detail: leadJson?.lead?.media_detail,
            lead_stage: leadJson?.lead?.lead_stage,
            leadJson: leadJson.lead,
          });
        });
      setLeadsData({
        result: list,
        totalCount: res && res.totalCount,
      });
    } else {
      setLeadsData({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  };

  useImperativeHandle(ref, () => ({
    handleAddNewLead: () => {
      setAddNewLeadDialog(true);
    },
  }));

  useEffect(() => {
    if (contactId) {
      getAllLeads();
    }
  }, [contactId, pagination, criteriaFilter, actionableItems?.action]);

  useEffect(() => {
    if (actionableItems?.action === null) {
      setCriteriaFilter({
        contactId: [{ searchType: 1, value: contactId }],
      });
    }
  }, [actionableItems?.action]);

  const handleFilterConfirm = (
    params,
    title,
    inputValue,
    selectedValue,
    inputNameValue,
    selectedNameValue
  ) => {
    const field = params?.field || params?.colDef?.field;

    setCriteriaFilter((prevBody) => {
      // Deep comparison to avoid unnecessary updates and infinite loops
      const updatedFilters = { ...prevBody };

      updatedFilters[field] = [
        { searchType: selectedValue, value: inputValue },
      ];

      const relatedField =
        title === "Creation Date"
          ? "createdBy"
          : title === "Updated Date"
          ? "updatedBy"
          : null;

      if (relatedField) {
        updatedFilters[relatedField] = [
          { searchType: selectedNameValue, value: inputNameValue },
        ];
      }

      // Only update the filter if it has actually changed
      return isEqual(updatedFilters, prevBody) ? prevBody : updatedFilters;
    });
  };

  const item = leadsData?.result?.find(
    (item) =>
      item.leadId ===
      actionableItems?.selectedIds[actionableItems?.selectedIds?.length - 1]
  );

  const reassignHandler = async (reassignItem) => {
    await ReassignLeads({
      leadIds: actionableItems?.selectedIds,
      referredToId: reassignItem.referredToId,
      isCopyTo: reassignItem.isCopyTo,
    });

    setAlertBoxContent({
      display: true,
      variant: "success",
      title: "You Will Be Notified When Lead Reassign Process Is Done",
      onClose: () => {
        setAlertBoxContent(null);
      },
    });

    setActionableItems({
      selectedIds: [],
      action: null,
    });
  };
  const cloneLeads = useCallback(async (items) => {
    const result = await CloneLeads(items);

    if (!(result && result.status && result.status !== 200)) {
      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "You Will Be Notified When Lead Clone Process Is Done",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } else {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Leads clone failed",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    }

    setActionableItems({
      selectedIds: [],
      action: null,
    });
  }, []);

  const closeLeads = useCallback(async (item) => {
    await CloseListOfLeads(item);

    setActionableItems({
      selectedIds: [],
      action: null,
    });

    setAlertBoxContent({
      display: true,
      variant: "success",
      title: "You Will Be Notified When Lead Close Process Is Done",
      onClose: () => {
        setAlertBoxContent(null);
      },
    });
  }, []);

  return (
    <Box>
      <LeadActionButtons
        setCriteriaFilter={setCriteriaFilter}
        contactId={contactId}
      />
      <Box>
        {isLoading ? (
          <TableSkeleton rowsNum={12} />
        ) : leadsData?.result?.length > 0 ? (
          <BasicTable
            pageSize={25}
            rowsData={leadsData?.result || []}
            setActiveItem={setActiveItem}
            rowHeight={72}
            columns={leadTableHeaderData(isDarkMode)?.map((column) => ({
              ...column,
              renderHeader: (params) => (
                <CustomTableHeader
                  params={params}
                  title={column.headerName}
                  handleFilterConfirm={handleFilterConfirm}
                  criteriaFilter={criteriaFilter}
                />
              ),
            }))}
          />
        ) : (
          <NoDataFound title="leads" />
        )}
      </Box>

      <CustomPagination
        hideInMobile
        totalItems={leadsData.totalCount}
        itemsPerPage={pagination.pageSize}
        currentPage={pagination.pageIndex}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, pageIndex: page }))
        }
        onItemsPerPageChange={(items) =>
          setPagination((prev) => ({ ...prev, pageSize: items }))
        }
        isLoading={isLoading}
      />

      {actionableItems?.isUnqualifiedLeadsDialogOpen && (
        <ContactUnqualifiedLeadsDialog
          isOpen={actionableItems?.isUnqualifiedLeadsDialogOpen}
          checkedCardsIds={actionableItems?.selectedIds}
          onSave={(item) => {
            setActionableItems({
              selectedIds: [],
              action: null,
            });
          }}
          onClose={() => {
            setActionableItems((prevState) => ({
              ...prevState,
              isUnqualifiedLeadsDialogOpen: false,
            }));
          }}
        />
      )}

      {actionableItems?.isOpenReassignDialog && (
        <ContactReassignLeadDialog
          isOpen={actionableItems?.isOpenReassignDialog}
          onClose={() => {
            setActionableItems((prevState) => ({
              ...prevState,
              isOpenReassignDialog: false,
            }));
          }}
          userType={
            (item?.leadClass === "Buyer"
              ? AgentRoleEnum.SaleAgent.value
              : item?.leadClass === "Tenant"
              ? AgentRoleEnum.LeaseAgent.value
              : item?.leadClass === "Seller"
              ? AgentRoleEnum.SaleListingAgent.value
              : AgentRoleEnum.LeaseListingAgent.value) || null
          }
          onSave={async (reassignItem) => {
            reassignHandler(reassignItem);
          }}
          translationPath={""}
          parentTranslationPath={""}
        />
      )}

      {actionableItems?.isOpenCloneLeads && (
        <ContactCloneLeadsDialog
          isOpen={actionableItems?.isOpenCloneLeads}
          checkedCardsIds={actionableItems?.selectedIds}
          checkedCards={
            leadsData?.result?.filter(
              (item) =>
                actionableItems?.selectedIds?.includes(item?.leadId) ||
                actionableItems?.selectedIds?.includes(item?.id)
            ) || []
          }
          totalCloneLeads={actionableItems?.selectedIds?.length}
          setIsLoading={() => {}}
          onSave={(item) => {
            cloneLeads(item);
          }}
          onClose={() => {
            setActionableItems((prevState) => ({
              ...prevState,
              isOpenCloneLeads: false,
            }));
          }}
        />
      )}

      {actionableItems?.isOpenCloseLeadsDialog && (
        <ContactCloseLeadsDialog
         isOpen={actionableItems?.isOpenCloseLeadsDialog}
          setIsLoading={() => {}}
          onSave={(item) => {
            const closeLeadsBody = {
              leadsIds: actionableItems?.selectedIds,
              closeReasonId: item.closeReasonId,
              remarks: item.remarks,
            };
            closeLeads(closeLeadsBody);
          }}
          onClose={() => {
            setActionableItems((prevState) => ({
              ...prevState,
              isOpenCloseLeadsDialog: false,
            }));
          }}
        />
      )}
      <ContactAddNewLeadDialog
        addNewLeadDialog={addNewLeadDialog}
        setAddNewLeadDialog={setAddNewLeadDialog}
        translate={translate}
        contactId={contactId}
      />
    </Box>
  );
});

export default ContactLeads;
