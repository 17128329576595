import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@material-ui/core";
import clsx from "clsx";

import { useSelectedTheme, useIsDesktop, useTranslate } from "../../../Hooks";

// Styles
import useStyles from "./styles";

// Icons
import {
  TrendUpIcon01,
  ShareIcon05,
  DownloadCloudIcon01,
  EyeIcon,
  SignatureIcon,
} from "../../../assets/icons";

function KYCHistoryTableActions({
  anchorPosition,
  onShare,
  onDownload,
  onChangeRating,
  onPreview,
  onApproval,
}) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isDesktop } = useIsDesktop();

  const styles = useStyles({ isDesktop });
  const { translate } = useTranslate("SharedV2");

  const [text, setText] = useState("");

  const handleMouseEnter = (tooltipText) => {
    setText(tooltipText);
    setTimeout(() => {
      setText("");
    }, 1000);
  };

  return (
    <Box
      className={styles.actionsContainer}
      style={{
        top: anchorPosition?.y || 0,
      }}
    >
      <Box className={styles.flex}>
        {onShare && (
          <IconButton
            aria-label="Share"
            className={styles.button}
            onClick={onShare}
            onMouseEnter={() => handleMouseEnter(translate("Share"))}
          >
            {text === translate("Share") && (
              <div
                className={clsx(styles.tooltip, {
                  [styles.tooltipVisible]: text,
                })}
              >
                {text}
              </div>
            )}
            <ShareIcon05
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          </IconButton>
        )}

        {onDownload && (
          <IconButton
            aria-label="Download"
            className={styles.button}
            onClick={onDownload}
            onMouseEnter={() => handleMouseEnter(translate("Download"))}
          >
            {text === translate("Download") && (
              <div
                className={clsx(styles.tooltip, {
                  [styles.tooltipVisible]: text,
                })}
              >
                {text}
              </div>
            )}
            <DownloadCloudIcon01
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          </IconButton>
        )}

        {onChangeRating && (
          <IconButton
            aria-label="Risk rating"
            className={styles.button}
            onClick={onChangeRating}
            onMouseEnter={() => handleMouseEnter(translate("Risk_rating"))}
          >
            {text === translate("Risk_rating") && (
              <div
                className={clsx(styles.tooltip, {
                  [styles.tooltipVisible]: text,
                })}
              >
                {text}
              </div>
            )}
            <TrendUpIcon01
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          </IconButton>
        )}

        {onPreview && (
          <IconButton
            aria-label="Preview"
            className={styles.button}
            onClick={onPreview}
            onMouseEnter={() => handleMouseEnter(translate("Preview"))}
          >
            {text === translate("Preview") && (
              <div
                className={clsx(styles.tooltip, {
                  [styles.tooltipVisible]: text,
                })}
              >
                {text}
              </div>
            )}
            <EyeIcon
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          </IconButton>
        )}

        {onApproval && (
          <IconButton
            aria-label="Approval"
            className={styles.button}
            onClick={onApproval}
            onMouseEnter={() => handleMouseEnter(translate("Agent_Approval"))}
          >
            {text === translate("Agent_Approval") && (
              <div
                className={clsx(styles.tooltip, {
                  [styles.tooltipVisible]: text,
                })}
              >
                {text}
              </div>
            )}
            <SignatureIcon
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

KYCHistoryTableActions.propTypes = {
  anchorPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  onShare: PropTypes.func,
  onDownload: PropTypes.func,
  onChangeRating: PropTypes.func,
  onPreview: PropTypes.func,
};

KYCHistoryTableActions.defaultProps = {
  anchorPosition: { x: 0, y: 0 },
  onShare: null,
  onDownload: null,
  onChangeRating: null,
  onPreview: null,
};

export default KYCHistoryTableActions;
