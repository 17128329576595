import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { CustomButton } from "../CustomButton";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useSelectedTheme } from "../../../Hooks";

// Icons
import { CloseXIcon } from "../../../assets/icons";

// styles
import useStyles from "./styles";

function CustomDialog(props) {
  const {
    open,
    onClose,
    onConfirm,
    title,
    subtitle,
    content,
    confirmText,
    cancelText,
    showActions,
    width,
    hideActionsBorder,
    isDisabled,
    headingIcon,
    moreAction,
    paperClassName,
    ...restProps
  } = props;

  const classes = useStyles({ width, hideActionsBorder });

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="custom-dialog-title"
      classes={{ paper: clsx(classes.dialogPaper, {[paperClassName]: !!paperClassName}) }}
      {...restProps}
    >
      <Box className={classes.headingContainer}>
        {headingIcon && headingIcon}
        <Box className={classes.titlesWrapper}>
          {title && (
            <DialogTitle
              className={classes.dialogTitleWrapper}
              id="custom-dialog-title"
            >
              <div className={classes.dialogTitle}>
                {title}

                <CloseXIcon
                  onClick={onClose}
                  style={{ cursor: "pointer" }}
                  width="24"
                  height="24"
                  fill={palette.foreground.quinary}
                />
              </div>
            </DialogTitle>
          )}
          {subtitle && (
            <Typography variant="body2" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>

      {content && (
        <DialogContent className={classes.dialogContent}>
          {typeof content === "string" ? (
            <DialogContentText>{content}</DialogContentText>
          ) : (
            content
          )}
        </DialogContent>
      )}
      {showActions && (
        <DialogActions className={classes.dialogActions}>
          {moreAction && moreAction}
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {cancelText}
          </CustomButton>
          {onConfirm && (
            <CustomButton
              boxShadow="xs"
              size="lg"
              variant="contained"
              color="primary"
              onClick={onConfirm}
              disabled={isDisabled}
            >
              {confirmText}
            </CustomButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  showActions: PropTypes.bool,
  isDisabled: PropTypes.bool,
  headingIcon: PropTypes.node,
  moreAction: PropTypes.node,
  paperClassName: PropTypes.string,
};

CustomDialog.defaultProps = {
  confirmText: "Confirm",
  cancelText: "Cancel",
  showActions: true,
};

export default CustomDialog;
