import React, { useCallback, useReducer, useRef } from "react";
import { AutocompleteComponent, CheckboxesComponent, Inputs } from "../../../Components";
import { useTranslation } from "react-i18next";
import { GetAllLeads, OrganizationUserSearch } from "../../../Services";
import { LeadsClassTypesEnum, LeadsStatusEnum } from "../../../Enums";

const ShareViaEmailForm = ({
  operationType,
  unitOwner,
  leaseUnitOwner,
  selected,
  setSelected,
  parentTranslationPath,
  translationPath,
  disableLeadsRecipient,
  disableUsersRecipient,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const searchTimer = useRef(null);
  const pathName = window.location.pathname.split('/home/')[1].split('/')[0];
  const isSaleModule = (pathName === 'lead-owner-units-sale') || (pathName === 'units-sales') || (pathName === 'zero-matching-sale') 
  || (pathName === 'listing-shortage') || (pathName === 'primary-units') || (pathName === 'resale-units') || operationType == "Sale" || operationType == "SaleAndRent";;
  const isLeaseModule = (pathName === 'lead-owner-units-lease') || (pathName === 'units-lease') ||(pathName ==='zero-matching-lease')  || operationType == "Rent";;

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [data, setData] = useReducer(reducer, {
    leads: [],
    systemUsers: [],
  });

  const getSystemUsers = async (value) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: value || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: "systemUsers",
          value: localValue,
        });
      } else {
        setData({
          id: "systemUsers",
          value: [],
        });
      }
    }
  };

  const getLeadTypeEnum = () => {
    let leadTypeEnum = null;
    if(operationType === "Rent") leadTypeEnum = [LeadsClassTypesEnum.tenant.key];
    else leadTypeEnum = [LeadsClassTypesEnum.buyer.key];
    return leadTypeEnum;
  }
  

  const getLeads = async (value) => {
    const response = await GetAllLeads({
      pageIndex: 1,
      pageSize: 10,
      search: value || "",
      leadStatus: LeadsStatusEnum.Open.status,
      leadTypes: getLeadTypeEnum(),
    });
    if (!(response && response.status && response.status !== 200))
      setData({ id: "leads", value: (response && response.result) || [] });
    else setData({ id: "leads", value: [] });
  };

  const getUnitOwnerName = () => {

    if (isSaleModule && unitOwner) return unitOwner.name || '';
    else if (isLeaseModule && leaseUnitOwner) return leaseUnitOwner.name || '';

    else return '';
  }

  return (
    <div className="dialog-content-wrapper">
      <fieldset className="styled-fieldset d-flex mx-2">
        <legend className="styled-legend">
          {t(`${translationPath}send-to`)}
        </legend>
        <div className="dialog-content-item d-flex-column-center">
          <AutocompleteComponent
            idRef="System-usersRef"
            inputPlaceholder={t(`${translationPath}select`)}
            labelValue={t(`${translationPath}System-users`)}
            selectedValues={selected?.recipient?.Email?.systemUsers || []}
            wrapperClasses="mr-2 my-2"
            data={data.systemUsers || []}
            isDisabled={disableUsersRecipient}
            chipsLabel={(option) => option.fullName || ""}
            withoutSearchButton
            multiple
            displayLabel={(option) => option.fullName || ""}
            renderOption={(option) =>
              ((option.userName || option.fullName) &&
                `${option.fullName} (${option.userName})`) ||
              ""
            }
            onChange={(event, newValue) => {
              const localRecipient = {
                ...(selected.recipient),
              };
              localRecipient.Email.systemUsers = newValue;
              
              setSelected({
                id: "recipient",
                value: localRecipient,
              });
            }}
            onOpen={() => {
              if (data.systemUsers && data.systemUsers.length == 0)
                getSystemUsers();
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getSystemUsers(value);
              }, 1200);
            }}
          />
          <AutocompleteComponent
            idRef="leadsRef"
            inputPlaceholder={t(`${translationPath}select`)}
            labelValue={t(`${translationPath}leads`)}
            selectedValues={selected?.recipient?.Email?.leads || []}
            wrapperClasses="mr-2 my-2"
            data={data.leads || []}
            isDisabled={disableLeadsRecipient}
            chipsLabel={(option) => option?.lead?.contact_name?.name || option?.lead?.company_name || ""}
            withoutSearchButton
            multiple
            displayLabel={(option) => option?.lead?.contact_name?.name || option?.lead?.company_name || ""}
            renderOption={(option) =>
              ((option.userName || option?.lead?.contact_name?.name || option?.lead?.company_name) &&
                `${option?.lead?.contact_name?.name || option?.lead?.company_name}`) ||
              ""
            }
            onChange={(event, newValue) => {
              const localRecipient = {
                ...(selected.recipient),
              };
              localRecipient.Email.leads = newValue;

              setSelected({
                id: "recipient",
                value: localRecipient,
              });
            }}
            onOpen={() => {
              if (data.leads && data.leads.length == 0) getLeads();
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getLeads(value);
              }, 1200);
            }}
          />
          {(unitOwner || leaseUnitOwner) &&
            <CheckboxesComponent
              idRef="unit-owner-IdRef"
              label={`${t(`${translationPath}send-to-unit-owner`)} ( ${getUnitOwnerName() })`}
              themeClass="theme-secondary mt-2"
              singleChecked={selected.isUnitOwnerChecked}
              onSelectedCheckboxChanged={() => {
                setSelected({
                  id: "isUnitOwnerChecked",
                  value: !selected.isUnitOwnerChecked,
                })
              }}
            />}
        </div>
      </fieldset>
    </div>
  );
};

export default ShareViaEmailForm;
