import React from "react";
import {
  FolderIcon,
  VedioIcon,
  JPEGIcon,
  PNGIcon,
  WebpIcon,
  TIFFIcon,
  GIFIcon,
  SVGIcon,
  DOCXIcon,
  TXTIcon,
  CSVIcon,
  XLSXIcon,
  PPTIcon,
  FIGFigmaIcon,
  PSDPhotoshopIcon,
  INDDInDesignIcon,
  AEPAfterEffectsIcon,
  MP3Icon,
  WAVIcon,
  HTMLIcon,
  CSSIcon,
  SQLIcon,
  JSIcon,
  JAVAIcon,
  EXEIcon,
  ZIPIcon,
  EmptyIcon,
  PdfIcon,
} from "../../../assets/upload-icons";

export const GetFileIconbyDocumentName = ({ documentName, height='40', width='40' }) => {
  if (!documentName) {
    return <FolderIcon width={width} height={height} />;
  }
  switch (documentName) {
    case "pdf":
      return <PdfIcon width={width} height={height} />;
    case "xlsx":
    case "xls":
      return <XLSXIcon width={width} height={height} />;
    case "docx":
    case "doc":
      return <DOCXIcon width={width} height={height} />;
    case "txt":
      return <TXTIcon width={width} height={height} />;
    case "csv":
      return <CSVIcon width={width} height={height} />;
    case "ppt":
    case "pptx":
      return <PPTIcon width={width} height={height} />;
    case "fig":
      return <FIGFigmaIcon width={width} height={height} />;
    case "psd":
      return <PSDPhotoshopIcon width={width} height={height} />;
    case "indd":
      return <INDDInDesignIcon width={width} height={height} />;
    case "aep":
      return <AEPAfterEffectsIcon width={width} height={height} />;
    case "mp3":
      return <MP3Icon width={width} height={height} />;
    case "wav":
      return <WAVIcon width={width} height={height} />;
    case "mp4":
    case "mpeg":
    case "avi":
    case "mkv":
      return <VedioIcon width={width} height={height} />;
    case "html":
      return <HTMLIcon width={width} height={height} />;
    case "css":
      return <CSSIcon width={width} height={height} />;
    case "sql":
      return <SQLIcon width={width} height={height} />;
    case "js":
      return <JSIcon width={width} height={height} />;
    case "java":
      return <JAVAIcon width={width} height={height} />;
    case "exe":
      return <EXEIcon width={width} height={height} />;
    case "zip":
    case "rar":
      return <ZIPIcon width={width} height={height} />;
    case "png":
      return <PNGIcon width={width} height={height} />;
    case "jpeg":
    case "jpg":
      return <JPEGIcon width={width} height={height} />;
    case "webp":
      return <WebpIcon width={width} height={height} />;
    case "tiff":
      return <TIFFIcon width={width} height={height} />;
    case "gif":
      return <GIFIcon width={width} height={height} />;
    case "svg":
      return <SVGIcon width={width} height={height} />;
    default:
      return <EmptyIcon width={width} height={height} />;
  }
};

export default GetFileIconbyDocumentName;
