export const LeadsCAllCenterPermissions = {
  ViewAndSearchInCallCenterLeads: {
    permissionsId: "e4598245-3d00-4d3f-8683-08d93098df4e",
    permissionsName: "View and search in Call Center Leads",
    componentsId: null,
    components: null,
  },
  ViewLeadDetails: {
    permissionsId: "fee16441-daf7-4cfc-8684-08d93098df4e",
    permissionsName: "View lead details",
    componentsId: null,
    components: null,
  },
  ViewMatchingUnits: {
    permissionsId: "17138d1b-5a8f-42f8-8685-08d93098df4e",
    permissionsName: "View Matching Units",
    componentsId: null,
    components: null,
  },
  AddNewLead: {
    permissionsId: "684aada6-1ac5-4064-8686-08d93098df4e",
    permissionsName: "Add new lead",
    componentsId: null,
    components: null,
  },
  EditLeadDetails: {
    permissionsId: "fb154d58-4e2b-4f20-8687-08d93098df4e",
    permissionsName: "Edit lead details",
    componentsId: null,
    components: null,
  },
  CloneLead: {
    components: null,
    componentsId: null,
    permissionsId: "7cc403ee-24f8-4ad9-4759-08d9aa7e8df8",
    permissionsName: "Clone Lead",
  },

  GetAgentLeadAssigmentHistory: {
    permissionsId: "8832eb35-eaab-435c-a88a-08da6e2b177d",
    permissionsName: "Get Agent Lead Assigment History",
    componentsId: null,
    components: null,
  },
  ImportLead: {
    permissionsId: "deedb6a5-511f-40af-a88d-08da6e2b177d",
    permissionsName: "Import Lead",
    componentsId: null,
    components: null,
  },
  CloseLead: {
    permissionsId: "514fd6a6-89c7-4260-a890-08da6e2b177d",
    permissionsName: "Close Lead",
    componentsId: null,
    components: null,
  },
  ReassignLead: {
    permissionsId: "2b7b7a32-8f8f-40cd-a894-08da6e2b177d",
    permissionsName: "Reassign Lead",
    componentsId: null,
    components: null,
  },
  GetActivitiesForLead: {
    permissionsId: "fc45b954-b303-4a1a-a897-08da6e2b177d",
    permissionsName: "Get Activities For Lead",
    componentsId: null,
    components: null,
  },

  SendToRotation: {
    permissionsId: "ed297a91-a254-414e-839a-08db0f594284",
    permissionsName: "Send To Rotation",
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewActivity: {
    permissionsId: "05cfd452-e2c8-4f3c-2873-08dbaf79a1bb",
    permissionsName: "Add New Activity",
    description: null,
    componentsId: null,
    components: null,
  },
  ReassignSellerLead: {
    permissionsId: "1c9d84e5-f781-4816-8874-08dc0129f7d6",
    permissionsName: "Reassign Seller Lead",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewTasksForLead: {
    permissionsId: "c7c16ec3-c49d-45a4-19bc-08dc313da57c",
    permissionsName: "View Tasks For Lead",
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewTask: {
    permissionsId: "117f1663-c8a5-44b3-19bd-08dc313da57c",
    permissionsName: "Add New Task",
    description: null,
    componentsId: null,
    components: null,
  },
  SendToTheLeadsPool: {
    permissionsId: "08da9f73-9213-4662-c4c3-08dc683b0c43",
    permissionsName: "Send To The Leads Pool",
    description: null,
    componentsId: null,
    components: null,
  },
  SearchAndMatch: {
    permissionsId: "c6dbe029-7293-4bee-796e-08dcaf947ea8",
    permissionsName: "Search & Match",
    description: null,
    componentsId: null,
    components: null,
  },
  SendToTheLeadsPoolAssets: {
    permissionsId: "96ae78be-45ed-47dd-506f-08dce2e2dc64",
    permissionsName: "Send To The Leads Pool (Assets)",
    description: null,
    componentsId: null,
    components: null,
  },
  EditContactName: {
    permissionsId: "1c2cee40-6e3e-4db9-ec30-08dd2fb527c1",
    permissionsName: "Edit Contact Name",
    description: null,
    componentsId: null,
    components: null,
  },
};
