import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  generateUniqueKey,
  GetParams,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import PaymentPlansView from '../../../../PaymentPlans/PaymentPlansView';
import {
  CreatePaymentPlan,
  GetPredefinedPaymentPlans,
} from '../../../../../../Services/PaymentPlanServices';
import { useTranslation } from 'react-i18next';
import { PaymentPlansLocalePath } from '../../../../PaymentPlans/i18n/PaymentPlansLocale';
import {
  createPropertyPaymentPlanDto,
  formatPaymentPlansForTableFields,
} from '../../../../PaymentPlans/models';
import PaymentPlanCard from './PaymentPlanCard';
import { Spinner } from '../../../../../../Components';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

export const PropertiesPaymetPlanComponent = () => {
  const { t } = useTranslation(PaymentPlansLocalePath);
  const [data, setData] = useState({ result: [], totalCount: 0 });
  const [key, setKey] = useState(generateUniqueKey);
  const [loading, setLoading] = useState(false);
  const propertyId = +GetParams('id');
  const containerRef = useRef();
  const filter = useMemo(
    () => ({
      pageIndex: 1,
      pageSize: 10,
      propertyId,
    }),
    [propertyId]
  );
  const scrollLeft = () =>
    containerRef.current.scrollBy({ left: -400, behavior: 'smooth' });
  const scrollRight = () =>
    containerRef.current.scrollBy({ left: 400, behavior: 'smooth' });
  const getPredefinedPaymentPlans = useCallback(async () => {
    try {
      setLoading(true);
      const res = await GetPredefinedPaymentPlans(filter);
      res.result = formatPaymentPlansForTableFields(res.result);
      setData(res);
    } catch (error) {
      showError(t('something_went_wrong'));
    } finally {
      setLoading(false);
    }
  }, [filter]);
  const createSelectedPaymentPlan = async (selectedPaymentPlan) => {
    try {
      delete selectedPaymentPlan.paymentPlanDevelopers;

      await CreatePaymentPlan(
        createPropertyPaymentPlanDto({
          ...selectedPaymentPlan,
          propertyId,
        })
      );
      getPredefinedPaymentPlans();
      setKey(generateUniqueKey());
      showSuccess(t('selected_successfully'));
    } catch (error) {
      showError(t('something_went_wrong'));
    }
  };
  useEffect(() => {
    getPredefinedPaymentPlans();
  }, [getPredefinedPaymentPlans]);
  return (
    <div>
      <Spinner isActive={loading} />
      <PaymentPlansView
        propertyId={+GetParams('id')}
        key={key}
        onDelete={getPredefinedPaymentPlans}
      >
        <div className='payment-plans-cards-container' ref={containerRef}>
          {data.result.map((paymentPlanItem) => (
            <PaymentPlanCard
              key={paymentPlanItem.propertyPaymentPlanId}
              {...paymentPlanItem}
              onSelect={() => createSelectedPaymentPlan(paymentPlanItem)}
            />
          ))}
        </div>
        <div className='flex fj-end gap-12'>
          <div>
            <IconButton onClick={scrollLeft}>
              <ChevronLeft />
            </IconButton>
          </div>
          <div>
            <IconButton onClick={scrollRight}>
              <ChevronRight />
            </IconButton>
          </div>
        </div>
      </PaymentPlansView>
    </div>
  );
};
