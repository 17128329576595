import React from "react";

const PhoneIcon = ({ fill, ...restProps }) => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.6 1.315c-.462.082-.866.248-1.172.48-.357.27-1.559 1.516-1.76 1.823-.329.505-.513 1.152-.514 1.807-.001.459.042.675.3 1.53a16.449 16.449 0 0 0 4.336 7.127c1.785 1.734 3.724 2.951 6.025 3.781.566.204 1.434.456 1.835.531.787.149 1.763-.076 2.462-.568.307-.216 1.596-1.511 1.777-1.785a3.21 3.21 0 0 0 .258-.525c.11-.293.116-.333.118-.798.002-.451-.006-.516-.1-.803a2.46 2.46 0 0 0-.269-.56c-.204-.303-.759-.896-1.273-1.36-.597-.537-1.069-.733-1.768-.736a2.213 2.213 0 0 0-1.102.252c-.263.126-.687.459-.978.769l-.103.11-.433-.229a11.432 11.432 0 0 1-4.825-4.828 3.458 3.458 0 0 1-.189-.389c0-.017.156-.202.347-.41.564-.615.756-1.07.756-1.791 0-.343-.016-.469-.082-.683-.169-.539-.277-.693-1.02-1.447-.882-.897-1.139-1.081-1.72-1.232-.22-.057-.742-.095-.906-.066m.665 1.731c.153.078 1.02.905 1.219 1.163.199.258.25.489.169.766-.05.172-.107.25-.436.596-.43.451-.542.644-.627 1.075-.08.407-.019.693.27 1.269a13.061 13.061 0 0 0 5.897 5.856c.578.284.981.328 1.465.161.296-.102.418-.187.818-.571.197-.189.413-.367.48-.395.165-.069.52-.065.68.007.185.084 1.284 1.193 1.37 1.383a.838.838 0 0 1-.003.659c-.079.174-1.234 1.352-1.484 1.515-.383.247-.917.311-1.443.171a15.235 15.235 0 0 1-4.997-2.366 16.405 16.405 0 0 1-3.398-3.397 15.224 15.224 0 0 1-2.361-4.989c-.127-.494-.088-.948.114-1.345.071-.138 1.242-1.356 1.442-1.499a.821.821 0 0 1 .825-.059"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
};

export default PhoneIcon;
