import React, { useCallback, useEffect, useState } from "react";
import {
  CustomAutocomplete,
  CustomDialog,
  CustomTextArea,
} from "../../../../../../../../Components";
import { Box, Typography, Divider } from "@material-ui/core";
import { useTranslate } from "../../../../../../../../Hooks";
import { GetLookupItemsByLookupTypeName, UnqualifiedLeadsApi } from "../../../../../../../../Services";
import { LeadUnqualifiedeasonsEnum } from "../../../../../../../../Enums";
import { useVerticalNav } from "../../../../../../../../Contexts/VerticalNavContext";

//styles
import useStyles from "./styles";

function ContactUnqualifiedLeadsDialog({
  isOpen,
  checkedCardsIds,
  onSave,
  onClose,
}) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");
  const { setAlertBoxContent } = useVerticalNav();
  const [isLoading, setIsLoading] = useState(false);
  const [closeLeadReasons, setCloseLeadReasons] = useState([]);

  const [selected, setSelected] = useState({
    closeReason: null,
  });

  const [Dto, setDot] = useState({
    closeReasonId: null,
    remarks: "",
  });

  const getAllCloseReasons = useCallback(async () => {
    setIsLoading(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: LeadUnqualifiedeasonsEnum.lookupTypeName,
      pageSize: 10000,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200))
      setCloseLeadReasons(res && res.result);
    else setCloseLeadReasons([]);

    setIsLoading(false);
  }, []);

  const onSubmitHandler = useCallback( async() => {
    setIsLoading(true);
    const res = await UnqualifiedLeadsApi(
      {
        leadIds: checkedCardsIds,
        reasonId: Dto.closeReasonId,
        remarks: Dto.remarks
      }
    );
    if (!(res && res.status && res.status !== 200)) {
      setAlertBoxContent({
        display: true,
        variant: "success",
        title: translate("Info-add-successfully"),
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      onClose();
      onSave();
    } else {
      setAlertBoxContent({
        display: true,
        variant: "errror",
        title: translate(
          "failed-add-Info"
        ),
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      onClose();
    }
    setIsLoading(false);

  });

  useEffect(() => {
    getAllCloseReasons();
  }, []);

  return (
    <CustomDialog
      open={isOpen}
      onClose={onClose}
      title={translate("Unqualified-lead")}
      subtitle={translate(
        "Specify-the-reason-for-closing-whether-its-a-successful-deal-or-another-outcome"
      )}
      confirmText={translate("Submit")}
      width={"688px"}
      isDisabled={!Dto.closeReasonId || isLoading}
      onConfirm={() => {
        onSubmitHandler();
      }}
      content={
        <Box className={styles.containerContactUnqualifiedLead}>
          <Box className={styles.containerRowItem}>
            <Typography className={styles.titleItem}>
              {translate("Unqualified-reason")}
            </Typography>
            <CustomAutocomplete
              variant="default"
              options={closeLeadReasons || []}
              selectedValues={selected?.closeReason || null}
              getOptionLabel={(option) =>
                (option && option?.lookupItemName) || ""
              }
              renderOption={(option) => (option && option?.lookupItemName) || ""}
              onChange={(e, newValue) => {
                setSelected((item) => ({ ...item, closeReason: newValue }));
                setDot((item) => ({
                  ...item,
                  closeReasonId: newValue && newValue?.lookupItemId,
                }));
              }}
              placeholder="Select"
            />
          </Box>
          <Divider />
          <Box className={styles.containerRowItem}>
            <Typography className={styles.titleItem}>
              {translate("Remarks")}
            </Typography>
            <CustomTextArea
              maxRows={5}
              minRows={5}
              placeholder={translate("Enter-a-remark")}
              onInputChange={(value) => {
                setDot((item) => ({ ...item, remarks: value }));
              }}
            />
          </Box>
        </Box>
      }
    />
  );
}
export default ContactUnqualifiedLeadsDialog;
