import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => {
  return {
    breadCrumbContainer: {
      paddingBottom: "20px",
    },
    mainWrapper: {
      display: "flex",
      width: "1776px",
      height: "960px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      flex: "1 0 0",
      position: "relative",
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "auto",
      },
    },
    mainSection: {
      display: "flex",
      padding: "24px",
      flexDirection: "column",
      alignItems: "center",
      gap: "24px",
      flex: "1 0 0",
      [theme.breakpoints.down("sm")]: {
        padding: "4px",
        alignItems: "normal",
      },
    },
    imageWrapper: {
      backgroundImage: 'url("/images/contacts/backgroundGridLg.svg")',
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      width: "768px",
      height: "768px",
      position: "absolute",
      left: "504px",
      top: "-260px",
    },
    mainContainer: {
      display: "flex",
      padding: "0px  32px",
      flexDirection: "column",
      alignItems: "center",
      gap: "32px",
      [theme.breakpoints.down("sm")]: {
        padding: "0px  2px",
      },
    },
    ChangePasswordWrapper: {
      display: "flex",
      maxWidth: "360px",
      flexDirection: "column",
      alignItems: "center",
      gap: "32px",
      zIndex: 2,
      [theme.breakpoints.down("376")]: {
        width: "100%",
      },
    },
    formHeader: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "24px",
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "38px",
    },
    subTitle: {
      color: theme.palette.text.tertiary,
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    formWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "24px",
    },
    formSectionFields: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "20px",
    },
    inputWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "6px",
      width: "360px",
      [theme.breakpoints.down("376")]: {
        width: "100%",
      },
    },
    inputLabel: {
      color: theme.palette.text.secondary,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
    },
    inputField: {
      display: "flex",
      padding: "10px 14px",
      gap: "8px",
      width: "100%",
    },
    checksWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "12px",
      width: "100%",
    },
    checkText: {
      display: "flex",
      alignItems: "flex-start",
      gap: "8px",
    },
    helperText: {
      color: theme.palette.text.tertiary,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    formAction: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: " 6px",
      width: "100%",
    },
    IconWrapper: {
      display: "flex",
      width: "56px",
      height: "56px",
      padding: "14px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "12px",
      border: `1px solid ${theme.palette.border.secondary}`,
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    },
  };
});
