import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  CustomButton,
  CustomCard,
  CustomDialog,
  CustomInput,
  DropzoneComponent,
  GetFileIconbyDocumentName,
} from "../../../../../../Components";
import { Box, Typography, Divider } from "@material-ui/core";
import {
  useQuery,
  useSelectedTheme,
  useTranslate,
} from "../../../../../../Hooks";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";

import {
  getDownloadableLink,
  getIsValidDataURL,
  getIsValidURL,
} from "../../../../../../Helper";
import { ScopeDocumentEnum } from "../../../../../../Enums";
import { UpdateScopeDocument } from "../../../../../../Services";
import clsx from "clsx";
import {
  AttatchmentIcon,
  DeleteIcon,
  Download01,
  Image01Icon,
  PlusIcon,
  VideoCreatorIcon,
} from "../../../../../../assets/icons";

//styles
import useStyles from "./styles";

function ContactEditAllItemsRelatedCategoryDialog({
  open,
  onClose,
  scopeCategoryDocumentData,
  setUpdatedGetAllScopeDocuments,
  editAllDataOfCategory,
  editImage,
  editDocument,
}) {
  const styles = useStyles();
  const query = useQuery();
  const contactId = query.get("id");
  const {
    theme: { palette },
  } = useSelectedTheme();
  const reducerEditAllRelatedCategoryDocument = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const { setAlertBoxContent } = useVerticalNav();
  const { translate } = useTranslate("NewContactsView");
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [isOnSubmit, setIsOnSubmit] = useState(false);
  const handleFilesAccepted = (files) => {
    setUploadedFiles(files);
  };
  const [categoriesDocumentData, setCategoriesDocumentData] = useReducer(
    reducerEditAllRelatedCategoryDocument,
    [[]]
  );

  const [selectedUrl, setSelectedUrl] = useState({
    value: null,
    index: null,
  });
  const [moreLinkArray, setMoreLinkArray] = useState([]);
  const [newValueUrl, setNewValueUrl] = useState({
    value: null,
    index: null,
  });

  const currentMaxIndexMoreLinkArray =
    moreLinkArray?.reduce((max, doc) => {
      return doc?.index > max ? doc?.index : max;
    }, 0) || 0;

  const [selectedDocToDownload, setSelectedDocToDownload] = useState(null);
  const [selectedCardByUser, setSelectedCardByUser] = useState(0);
  const handleDeleteDoc = ({ index, selectedUrlIndex }) => {
    const updatedData = categoriesDocumentData[0].filter(
      (doc) => doc.index !== index
    );

    if (index === selectedUrlIndex) {
      setSelectedUrl({
        value: null,
        index: null,
      });
    }
    setCategoriesDocumentData({
      id: "edit",
      value: [updatedData],
    });
  };
  const handleAddDoc = () => {
    if (uploadedFileId) {
      const newDocument = {
        scopeDocumentId: null,
        categoryId: scopeCategoryDocumentData?.categoryId,
        categoryName: scopeCategoryDocumentData?.categoryName,
        documentId: uploadedFileId,
        documentName: uploadedFiles[0]?.name,
        documentLink: null,
      };
      const currentMaxIndexcategoriesData =
        categoriesDocumentData[0]?.reduce((max, doc) => {
          return doc.index > max ? doc.index : max;
        }, 0) || 0;

      const updatedDocument = {
        ...newDocument,
        index: currentMaxIndexcategoriesData + 1,
      };

      const updatedData = [
        ...(categoriesDocumentData[0] || []),
        updatedDocument,
      ];

      setCategoriesDocumentData({
        id: "edit",
        value: [updatedData],
      });
    } else {
      return;
    }
  };

  const fetchImages = () => {
    const urls = {};
    categoriesDocumentData[0]
      .filter((document) => document?.documentId !== null)
      .forEach((document) => {
        urls[document.documentId] = getDownloadableLink(document.documentId);
      });
    setImageUrls(urls);
  };

  const handleEditLink = ({ documentLink, index }) => {
    setIsUrlValid(false);
    setSelectedUrl({
      value: documentLink,
      index: index,
    });
  };

  const handleEditValueUrlChange = (value) => {
    setIsUrlValid(false);
    setNewValueUrl({
      value: null,
      index: null,
    });
    setSelectedUrl({
      value: value,
      index: selectedUrl.index,
    });
  };

  const handleAddNewValueUrlChange = (value) => {
    setIsUrlValid(false);
    setSelectedUrl({
      value: null,
      index: null,
    });
    setNewValueUrl({
      value: value,
      index: currentMaxIndexMoreLinkArray + 1,
    });
  };
  const validUrl = () => {
    const linkToValidate = selectedUrl.value;
    if (!linkToValidate) {
      return;
    }
    const IndexSelectedUrl = selectedUrl.index;
    const isValidUrl =
      getIsValidURL(linkToValidate) || getIsValidDataURL(linkToValidate);
    if (isValidUrl) {
      const updatedData = categoriesDocumentData[0].map((item) => {
        if (item.index === IndexSelectedUrl) {
          return { ...item, documentLink: linkToValidate };
        }
        return item;
      });
      setCategoriesDocumentData({
        id: "edit",
        value: [updatedData],
      });
      setIsUrlValid(false);
      setSelectedUrl({
        value: null,
        index: null,
      });
    } else {
      setIsUrlValid(true);
    }
  };
  const updateScopeDocument = useCallback(async () => {
    setIsOnSubmit(true);
    try {
      if (categoriesDocumentData[0]?.length > 0) {
        const updatedCategoryDocument = categoriesDocumentData[0].map(
          (item) => ({
            fileId: item.documentId,
            fileName: item.documentName,
            documentLink: item.documentLink,
          })
        );
        const newLinksAdded = moreLinkArray.map((newLink) => ({
          fileId: null,
          fileName: null,
          documentLink: newLink.value,
        }));
        const combinedFiles = [...updatedCategoryDocument, ...newLinksAdded];
        const body = {
          scopeId: +contactId,
          scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
          categoryFiles: [
            {
              categoryId: scopeCategoryDocumentData?.categoryId,
              files: combinedFiles,
            },
          ],
        };
        const res = await UpdateScopeDocument(body);
        if (!(res && res.status && res.status !== 200)) {
          setAlertBoxContent({
            display: true,
            variant: "success",
            title: translate("updated-Doucoment-successfully!"),
            onClose: () => {
              setAlertBoxContent(null);
            },
          });
          setUpdatedGetAllScopeDocuments(true);
          onClose();
        } else {
          setAlertBoxContent({
            display: true,
            variant: "error",
            title: translate("Failed-to-updated-the-document-Please-try-again"),
            onClose: () => {
              setAlertBoxContent(null);
            },
          });
        }
      } else {
        return;
      }
    } catch (error) {
      console.error("updated-failed:", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: translate("Something-went-wrong-Please-try-again"),
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setIsOnSubmit(false);
    }
  });

  const mappingDataToEdit = () => {
    if (
      editAllDataOfCategory &&
      editAllDataOfCategory.length > 0 &&
      scopeCategoryDocumentData
    ) {
      const selectedDocument = editAllDataOfCategory.find(
        (doc) =>
          doc.scopeDocumentId === scopeCategoryDocumentData?.scopeDocumentId
      );
      const remainingDocuments = editAllDataOfCategory.filter(
        (doc) =>
          doc.scopeDocumentId !== scopeCategoryDocumentData?.scopeDocumentId
      );
      const formattedData = remainingDocuments.map((document, index) => ({
        documentLink: document?.documentLink || null,
        categoryName: document?.categoryName || null,
        scopeDocumentId: document?.scopeDocumentId || null,
        categoryId: document?.categoryId || null,
        documentName: document?.documentName || null,
        documentId: document?.documentId || null,
        index: index + 1,
      }));

      if (selectedDocument) {
        formattedData.unshift({
          documentLink: selectedDocument?.documentLink || null,
          categoryName: selectedDocument?.categoryName || null,
          scopeDocumentId: selectedDocument?.scopeDocumentId || null,
          categoryId: selectedDocument?.categoryId || null,
          documentName: selectedDocument?.documentName || null,
          documentId: selectedDocument?.documentId || null,
          index: 0,
        });
      }
      setCategoriesDocumentData({
        id: "edit",
        value: [formattedData],
      });
      const firstUrlDocument = formattedData.find((doc) => doc.documentLink);
      if (!formattedData[0]?.documentId && formattedData[0]?.documentLink) {
        setSelectedUrl({
          value: firstUrlDocument?.documentLink || null,
          index: firstUrlDocument ? firstUrlDocument.index : null,
        });
      }
      if (formattedData[0]?.documentId) {
        setSelectedDocToDownload(formattedData[0]?.documentId);
      }
    }
  };
  const getDocumentClass = (doucomentOfCategory) => {
    let extension;
    if (categoriesDocumentData?.[0].length === 1) {
      extension = categoriesDocumentData?.[0]?.[0].documentName
        ?.split(".")
        .pop()
        ?.toLowerCase();
    } else {
      extension = doucomentOfCategory?.documentName
        ?.split(".")
        .pop()
        ?.toLowerCase();
    }
    const isImage = [
      "png",
      "jpg",
      "jpeg",
      "gif",
      "bmp",
      "tiff",
      "webp",
      "svg",
    ].includes(extension);

    const isVideo = ["mp4", "webm", "ogg"].includes(extension);

    return clsx(
      styles.containerCardOfDoucoment,
      {
        [styles.noBackgroundWithImage]:
          doucomentOfCategory?.documentId && isImage,
      },
      {
        [styles.noBackgroundWithImageSelctedUser]:
          doucomentOfCategory?.documentId &&
          isImage &&
          doucomentOfCategory.index === selectedCardByUser,
      },
      {
        [styles.urlsStyle]: doucomentOfCategory?.documentLink,
      },
      {
        [styles.urlsStyleSelectedUser]:
          doucomentOfCategory?.documentLink &&
          doucomentOfCategory.index === selectedCardByUser,
      },
      {
        [styles.backgroundWithoutImage]:
          doucomentOfCategory?.documentId && !isImage && !isVideo,
      },
      {
        [styles.backgroundWithoutImageSelectedUser]:
          doucomentOfCategory?.documentId &&
          !isImage &&
          !isVideo &&
          doucomentOfCategory.index === selectedCardByUser,
      },
      {
        [styles.styleVedio]: doucomentOfCategory?.documentId && isVideo,
      },
      {
        [styles.vedioStyleSelectedUser]:
          doucomentOfCategory?.documentId &&
          isVideo &&
          doucomentOfCategory.index === selectedCardByUser,
      },
      {
        [styles.oneItemEditImageVedio]:
          categoriesDocumentData?.[0]?.length === 1,
      }
    );
  };

  const handleOnClickDownload = (documentId) => {
    if (documentId) {
      try {
        const link = document.createElement("a");
        link.setAttribute("download", documentId);
        link.href = getDownloadableLink(documentId);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setSelectedDocToDownload(null);
      } catch (error) {
        console.error("Download failed:", error);
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: "Failed-to-download-the-document-Please-try-again",
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } else {
      return;
    }
  };
  const handleOnCardClick = ({ documentLink, documentId, index }) => {
    setSelectedCardByUser(index);
    if (index !== selectedUrl.index) {
      setSelectedUrl({
        value: null,
        index: null,
      });
    }
    if (documentLink) {
      handleEditLink({
        index,
        documentLink,
      });
    } else if (documentId) {
      setSelectedDocToDownload(documentId);
    } else {
      return;
    }
  };
  const handleAddMoreLinks = () => {
    if(!newValueUrl.value || 
      newValueUrl.index === null || 
      newValueUrl.value.trim() === ""){
        return
     }
    const linkToValidate = newValueUrl?.value;
    const isLinkExistBefore =
      moreLinkArray.findIndex((link) => link === linkToValidate) !== -1;
    const isValidUrl =
      getIsValidURL(linkToValidate) || getIsValidDataURL(linkToValidate);
    if (isValidUrl && !isLinkExistBefore) {
      setMoreLinkArray((prevLinks) => [...prevLinks, newValueUrl]);
      setNewValueUrl({
        value: null,
        index: null,
      });
    } else {
      setIsUrlValid(true);
      setNewValueUrl({
        value: null,
        index: null,
      });
      return;
    }
  };
  const handleDeleteLink = (index) => {
    setMoreLinkArray((prevLinks) =>
      prevLinks.filter((item) => item?.index !== index)
    );
  };
  const restFiled = () => {
    setSelectedCardByUser(0);
    setIsUrlValid(false);
    setSelectedUrl({
      value: null,
      index: null,
    });
    setNewValueUrl({
      value: null,
      index: null,
    });
    setMoreLinkArray([])
  };
  const isInputDisabled = () =>
    selectedUrl?.index === null && editDocument && editImage;

  useEffect(() => {
    mappingDataToEdit();
  }, [editAllDataOfCategory]);

  useEffect(() => {
    fetchImages();
  }, [categoriesDocumentData]);

  useEffect(() => {
    handleAddDoc();
  }, [uploadedFileId]);
  return (
    <CustomDialog
      open={open}
      onClose={() => {
        onClose();
        setCategoriesDocumentData({
          id: "edit",
          value: [[]],
        });
        restFiled();
      }}
      title={translate("Edit-document")}
      confirmText={translate("Save-change")}
      onConfirm={() => {
        updateScopeDocument();
      }}
      isDisabled={categoriesDocumentData[0].length === 0 || isOnSubmit}
      cancelText={translate("Cancle")}
      width={"688px"}
      subtitle={translate(
        "Create-and-schedule-new-activity-to-enhance-engagement-with-your-contacts-and-leads"
      )}
      content={
        <Box className={styles.container_content}>
          <Box className={styles.container_Fileds_Doucoment}>
            <Box className={styles.urlFiledSection}>
              <Box className={styles.container_Link_Filed}>
                <Box className={styles.container_Title}>
                  <Typography className={styles.titleItem}>
                    {translate("Link")}
                  </Typography>
                </Box>
                <Box className={styles.containerInput}>
                  <CustomInput
                    inputContainerOverrideStyles={styles.inputFullWidth}
                    placeholder={translate("Enter-Url-Link")}
                    hasSearchIcon={false}
                    value={
                      selectedUrl?.value !== null
                        ? selectedUrl?.value || null
                        : newValueUrl?.value || null
                    }
                    handleOnBlur={(event) => {
                      validUrl();
                    }}
                    onChange={(e) => {
                      if (selectedUrl.index === null) {
                        handleAddNewValueUrlChange(e.target.value);
                      } else {
                        handleEditValueUrlChange(e.target.value);
                      }
                    }}
                    errorMessage={translate("Invalid_Url")}
                    hasError={isUrlValid}
                    disabled={isInputDisabled()}
                    submitted={isUrlValid}
                  />
                  <CustomButton
                    size="sm"
                    variant="text"
                    color="tertiaryGray"
                    style={{ display: "flex", gap: "12px" }}
                    startIcon={
                      <PlusIcon
                        width="20"
                        height="20"
                        fill={palette.button.tertiaryGray_fg}
                      />
                    }
                    onClick={() => {
                      handleAddMoreLinks();
                    }}
                    disabled={
                      selectedUrl.value !== null
                    }
                  >
                    {translate("Add-another")}
                  </CustomButton>
                </Box>
              </Box>
              {moreLinkArray?.length > 0 && (
                <CustomCard
                  boxShadow="xs"
                  borderRadius="xl"
                  borderColor="secondary"
                  classes={styles.MoreLinkesContainer}
                >
                  <Box className={styles.HeaderTableSection}>
                    <Typography className={styles.TitleItemTable}>
                      {translate("Link")}
                    </Typography>
                    <Typography className={styles.TitleItemTable}>
                      {translate("Action")}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box className={styles.BodyTableSection}>
                    {moreLinkArray.map((link, index) => (
                      <Box key={link?.index} className={styles.rowMoreLinkes}>
                        <Box className={styles.containerLink_Name}>
                          <Typography className={styles.LinkName}>
                            <a
                              href={link?.value}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.HyperLinkItem}
                            >
                              {link?.value}
                            </a>
                          </Typography>
                        </Box>
                        <Box className={styles.containerDelete_Icon}>
                          <DeleteIcon
                            width="20"
                            height="20"
                            fill={palette.button.tertiaryGray_fg}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteLink(link?.index)}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </CustomCard>
              )}
              <Divider className={styles.divider} />
            </Box>

            <Box className={styles.containerAttatchmentAndImages}>
              <Box className={styles.container_Attatchment_Filed}>
                <Box className={styles.container_Title}>
                  <Typography className={styles.titleItem}>
                    {translate("Attachment")}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.containerAttachmentUploadAndData}>
                <DropzoneComponent
                  accept="image/*,
                                 video/*,
                                 application/pdf,
                                 application/msword,
                                 application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                 application/vnd.ms-excel,
                                 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                 text/plain,
                                 application/vnd.adobe.figma,
                                 application/illustrator,
                                 application/photoshop,
                                 application/vnd.adobe.indesign,
                                 application/aftereffects,
                                 audio/mp3,
                                 audio/wav,
                                 video/mp4,
                                 video/mpeg,
                                 video/avi,
                                 video/mkv,
                                 text/html,
                                 text/css,
                                 application/rss+xml,
                                 application/sql,
                                 application/javascript,
                                 application/json,
                                 text/x-java-source,
                                 application/xml,
                                 application/x-msdownload,
                                 application/x-dmg,
                                 application/zip,
                                 application/x-rar-compressed"
                  title={translate("SVG-PNG-JPG-or-GIF-max-800x400px")}
                  onFilesAccepted={handleFilesAccepted}
                  setUploadedFileId={setUploadedFileId}
                />

                <Box className={styles.containerOneItemSection}>
                  {categoriesDocumentData?.[0]?.length === 1 ? (
                    <Box className={styles.fullcoloum}>
                      <Box
                        key={
                          categoriesDocumentData?.[0]?.[0]?.documentId ||
                          categoriesDocumentData?.[0]?.[0]?.documentLink
                        }
                        className={getDocumentClass(
                          categoriesDocumentData?.[0]
                        )}
                        onClick={() => {
                          handleOnCardClick({
                            documentLink:
                              categoriesDocumentData?.[0]?.[0]?.documentLink,
                            documentId:
                              categoriesDocumentData?.[0]?.[0]?.documentId,
                            index: categoriesDocumentData?.[0]?.[0]?.index,
                          });
                        }}
                        style={styles.cardDocoumentContainer}
                      >
                        {/* Render Images */}
                        {categoriesDocumentData?.[0]?.[0]?.documentId &&
                        [
                          "png",
                          "jpg",
                          "jpeg",
                          "gif",
                          "bmp",
                          "tiff",
                          "webp",
                          "svg",
                        ].includes(
                          categoriesDocumentData?.[0]?.[0]?.documentName
                            ?.split(".")
                            .pop()
                            ?.toLowerCase()
                        ) ? (
                          <Box className={styles.ImageWrapperOneImage}>
                            <img
                              src={
                                imageUrls[
                                  categoriesDocumentData?.[0]?.[0]?.documentId
                                ]
                              }
                              alt={
                                categoriesDocumentData?.[0]?.[0]?.documentName
                              }
                              className={styles.imagePreviewOneItem}
                            />
                            <Box className={styles.containerDeleteIcon}>
                              <DeleteIcon
                                width="20"
                                height="20"
                                fill={palette.background.paper}
                                onClick={() =>
                                  handleDeleteDoc({
                                    index:
                                      categoriesDocumentData?.[0]?.[0]?.index,
                                    selectedUrlIndex: selectedUrl.index,
                                  })
                                }
                                className={styles.deleteIcon}
                              />
                            </Box>
                          </Box>
                        ) : categoriesDocumentData?.[0]?.[0]?.documentId &&
                          ["mp4", "webm", "ogg"].includes(
                            categoriesDocumentData?.[0]?.[0]?.documentName
                              ?.split(".")
                              .pop()
                              ?.toLowerCase()
                          ) ? (
                          <Box className={styles.VediowrapperOneItem}>
                            <video
                              className={styles.videoPreviewOneItem}
                              height="60"
                              width="60"
                              autoPlay
                              src={
                                imageUrls[
                                  categoriesDocumentData?.[0]?.[0]?.documentId
                                ]
                              }
                            >
                              <source
                                src={
                                  imageUrls[
                                    categoriesDocumentData?.[0]?.[0]?.documentId
                                  ]
                                }
                                type={`video/${categoriesDocumentData?.[0]?.[0]?.documentName
                                  ?.split(".")
                                  .pop()
                                  ?.toLowerCase()}`}
                              />
                              Your browser does not support the video tag.
                            </video>
                            <Box className={styles.containerDeleteIcon}>
                              <DeleteIcon
                                width="20"
                                height="20"
                                fill={palette.background.paper}
                                onClick={() =>
                                  handleDeleteDoc({
                                    index:
                                      categoriesDocumentData?.[0]?.[0]?.index,
                                    selectedUrlIndex: selectedUrl.index,
                                  })
                                }
                                className={styles.deleteIcon}
                              />
                            </Box>
                          </Box>
                        ) : (
                          <>
                            <Box className={styles.continerIconFileAndName}>
                              {GetFileIconbyDocumentName({
                                documentName:
                                  categoriesDocumentData?.[0]?.[0]?.documentName
                                    ?.split(".")
                                    .pop()
                                    .toLowerCase(),
                                height: "60",
                                width: "60",
                              })}
                              <Typography className={styles.styleDocName}>
                                {categoriesDocumentData?.[0]?.[0]?.documentName}
                              </Typography>
                            </Box>
                            <Box className={styles.containerDeleteIcon}>
                              <DeleteIcon
                                width="20"
                                height="20"
                                fill={palette.background.paper}
                                onClick={() =>
                                  handleDeleteDoc({
                                    index:
                                      categoriesDocumentData?.[0]?.[0]?.index,
                                    selectedUrlIndex: selectedUrl.index,
                                  })
                                }
                                className={styles.deleteIcon}
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Box className={styles.columnContainer}>
                        {categoriesDocumentData[0]
                          ?.filter((_, index) => index % 2 === 0)
                          .map((doucomentOfCategory, index) => (
                            <Box
                              key={
                                doucomentOfCategory?.documentId ||
                                doucomentOfCategory?.documentLink
                              }
                              className={getDocumentClass(doucomentOfCategory)}
                              onClick={() => {
                                handleOnCardClick({
                                  documentLink:
                                    doucomentOfCategory?.documentLink,
                                  documentId: doucomentOfCategory?.documentId,
                                  index: doucomentOfCategory?.index,
                                });
                              }}
                              style={styles.cardDocoumentContainer}
                            >
                              {/* Render Images */}
                              {doucomentOfCategory?.documentId &&
                              [
                                "png",
                                "jpg",
                                "jpeg",
                                "gif",
                                "bmp",
                                "tiff",
                                "webp",
                                "svg",
                              ].includes(
                                doucomentOfCategory?.documentName
                                  ?.split(".")
                                  .pop()
                                  ?.toLowerCase()
                              ) ? (
                                <Box className={styles.ImageWrapper}>
                                  <img
                                    src={
                                      imageUrls[doucomentOfCategory?.documentId]
                                    }
                                    alt={doucomentOfCategory?.documentName}
                                    className={styles.imagePreview}
                                  />
                                  <Box className={styles.containerImage01Icon}>
                                    <Image01Icon
                                      width="28"
                                      height="28"
                                      fill={palette.foreground.senary}
                                      className={styles.Image01Icon}
                                    />
                                  </Box>
                                  <Box className={styles.containerDeleteIcon}>
                                    <DeleteIcon
                                      width="20"
                                      height="20"
                                      fill={palette.background.paper}
                                      onClick={() =>
                                        handleDeleteDoc({
                                          index: doucomentOfCategory?.index,
                                          selectedUrlIndex: selectedUrl.index,
                                        })
                                      }
                                      className={styles.deleteIcon}
                                    />
                                  </Box>
                                </Box>
                              ) : doucomentOfCategory?.documentLink ? (
                                <Box className={styles.containerdocumentLink}>
                                  <Box
                                    className={styles.containerAttatchAndUrl}
                                  >
                                    <Box
                                      className={
                                        styles.containerAttatchmentIcon
                                      }
                                    >
                                      <AttatchmentIcon
                                        width="28"
                                        height="28"
                                        fill={palette.foreground.white}
                                      />
                                    </Box>
                                    <Typography
                                      className={styles.documentLinkTitle}
                                    >
                                      {doucomentOfCategory?.documentLink}
                                    </Typography>
                                  </Box>
                                  <Box className={styles.containerDeleteIcon}>
                                    <DeleteIcon
                                      width="20"
                                      height="20"
                                      fill={palette.background.paper}
                                      onClick={() =>
                                        handleDeleteDoc({
                                          index: doucomentOfCategory?.index,
                                          selectedUrlIndex: selectedUrl.index,
                                        })
                                      }
                                      className={styles.deleteIcon}
                                    />
                                  </Box>
                                </Box>
                              ) : doucomentOfCategory?.documentId &&
                                ["mp4", "webm", "ogg"].includes(
                                  doucomentOfCategory.documentName
                                    ?.split(".")
                                    .pop()
                                    ?.toLowerCase()
                                ) ? (
                                <Box className={styles.Vediowrapper}>
                                  <video
                                    className={styles.videoPreview}
                                    height="60"
                                    width="60"
                                  >
                                    <source
                                      src={
                                        imageUrls[
                                          doucomentOfCategory?.documentId
                                        ]
                                      }
                                      type={`video/${doucomentOfCategory?.documentName
                                        ?.split(".")
                                        .pop()
                                        ?.toLowerCase()}`}
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                  <Box
                                    className={styles.containerVideoCreatorIcon}
                                  >
                                    <VideoCreatorIcon
                                      width="28"
                                      height="28"
                                      fill={palette.foreground.senary}
                                      className={styles.VideoCreatorIcon}
                                    />
                                  </Box>
                                  <Box className={styles.containerDeleteIcon}>
                                    <DeleteIcon
                                      width="20"
                                      height="20"
                                      fill={palette.background.paper}
                                      onClick={() =>
                                        handleDeleteDoc({
                                          index: doucomentOfCategory?.index,
                                          selectedUrlIndex: selectedUrl.index,
                                        })
                                      }
                                      className={styles.deleteIcon}
                                    />
                                  </Box>
                                </Box>
                              ) : (
                                <>
                                  <Box
                                    className={styles.continerIconFileAndName}
                                  >
                                    {GetFileIconbyDocumentName({
                                      documentName:
                                        doucomentOfCategory?.documentName
                                          ?.split(".")
                                          .pop()
                                          .toLowerCase(),
                                      height: "60",
                                      width: "60",
                                    })}
                                    <Typography className={styles.styleDocName}>
                                      {doucomentOfCategory?.documentName}
                                    </Typography>
                                  </Box>
                                  <Box className={styles.containerDeleteIcon}>
                                    <DeleteIcon
                                      width="20"
                                      height="20"
                                      fill={palette.background.paper}
                                      onClick={() =>
                                        handleDeleteDoc({
                                          index: doucomentOfCategory?.index,
                                          selectedUrlIndex: selectedUrl.index,
                                        })
                                      }
                                      className={styles.deleteIcon}
                                    />
                                  </Box>
                                </>
                              )}
                            </Box>
                          ))}
                      </Box>
                      <Box className={styles.columnContainer}>
                        {categoriesDocumentData[0]
                          ?.filter((_, index) => index % 2 !== 0)
                          .map((doucomentOfCategory, index) => (
                            <Box
                              key={
                                doucomentOfCategory?.documentId ||
                                doucomentOfCategory?.documentLink
                              }
                              className={getDocumentClass(doucomentOfCategory)}
                              onClick={() => {
                                handleOnCardClick({
                                  documentLink:
                                    doucomentOfCategory?.documentLink,
                                  documentId: doucomentOfCategory?.documentId,
                                  index: doucomentOfCategory?.index,
                                });
                              }}
                            >
                              {doucomentOfCategory?.documentId &&
                              [
                                "png",
                                "jpg",
                                "jpeg",
                                "gif",
                                "bmp",
                                "tiff",
                                "webp",
                                "svg",
                              ].includes(
                                doucomentOfCategory?.documentName
                                  ?.split(".")
                                  .pop()
                                  ?.toLowerCase()
                              ) ? (
                                // image
                                <Box className={styles.ImageWrapper}>
                                  <img
                                    src={
                                      imageUrls[doucomentOfCategory?.documentId]
                                    }
                                    alt={doucomentOfCategory?.documentName}
                                    className={styles.imagePreview}
                                  />
                                  <Box className={styles.containerImage01Icon}>
                                    <Image01Icon
                                      width="28"
                                      height="28"
                                      fill={palette.foreground.senary}
                                      className={styles.Image01Icon}
                                    />
                                  </Box>
                                  <Box className={styles.containerDeleteIcon}>
                                    <DeleteIcon
                                      width="20"
                                      height="20"
                                      fill={palette.background.paper}
                                      onClick={() =>
                                        handleDeleteDoc({
                                          index: doucomentOfCategory?.index,
                                          selectedUrlIndex: selectedUrl.index,
                                        })
                                      }
                                      className={styles.deleteIcon}
                                    />
                                  </Box>
                                </Box>
                              ) : doucomentOfCategory?.documentLink ? (
                                //link
                                <Box className={styles.containerdocumentLink}>
                                  <Box
                                    className={styles.containerAttatchAndUrl}
                                  >
                                    <Box
                                      className={
                                        styles.containerAttatchmentIcon
                                      }
                                    >
                                      <AttatchmentIcon
                                        width="28"
                                        height="28"
                                        fill={palette.foreground.white}
                                      />
                                    </Box>
                                    <Typography
                                      className={styles.documentLinkTitle}
                                    >
                                      {doucomentOfCategory?.documentLink}
                                    </Typography>
                                  </Box>
                                  <Box className={styles.containerDeleteIcon}>
                                    <DeleteIcon
                                      width="20"
                                      height="20"
                                      fill={palette.background.paper}
                                      onClick={() =>
                                        handleDeleteDoc({
                                          index: doucomentOfCategory?.index,
                                          selectedUrlIndex: selectedUrl.index,
                                        })
                                      }
                                      className={styles.deleteIcon}
                                    />
                                  </Box>
                                </Box>
                              ) : doucomentOfCategory?.documentId &&
                                ["mp4", "webm", "ogg"].includes(
                                  doucomentOfCategory.documentName
                                    ?.split(".")
                                    .pop()
                                    ?.toLowerCase()
                                ) ? (
                                // vedio
                                <Box className={styles.Vediowrapper}>
                                  <video
                                    className={styles.videoPreview}
                                    height="60"
                                    width="60"
                                  >
                                    <source
                                      src={
                                        imageUrls[
                                          doucomentOfCategory?.documentId
                                        ]
                                      }
                                      type={`video/${doucomentOfCategory?.documentName
                                        ?.split(".")
                                        .pop()
                                        ?.toLowerCase()}`}
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                  <Box
                                    className={styles.containerVideoCreatorIcon}
                                  >
                                    <VideoCreatorIcon
                                      width="28"
                                      height="28"
                                      fill={palette.foreground.senary}
                                      className={styles.VideoCreatorIcon}
                                    />
                                  </Box>
                                  <Box className={styles.containerDeleteIcon}>
                                    <DeleteIcon
                                      width="20"
                                      height="20"
                                      fill={palette.background.paper}
                                      onClick={() =>
                                        handleDeleteDoc({
                                          index: doucomentOfCategory?.index,
                                          selectedUrlIndex: selectedUrl.index,
                                        })
                                      }
                                      className={styles.deleteIcon}
                                    />
                                  </Box>
                                </Box>
                              ) : (
                                <>
                                  <Box
                                    className={styles.continerIconFileAndName}
                                  >
                                    {GetFileIconbyDocumentName({
                                      documentName:
                                        doucomentOfCategory?.documentName
                                          ?.split(".")
                                          .pop()
                                          .toLowerCase(),
                                      height: "60",
                                      width: "60",
                                    })}
                                    <Typography className={styles.styleDocName}>
                                      {doucomentOfCategory?.documentName}
                                    </Typography>
                                  </Box>
                                  <Box className={styles.containerDeleteIcon}>
                                    <DeleteIcon
                                      width="20"
                                      height="20"
                                      fill={palette.background.paper}
                                      onClick={() =>
                                        handleDeleteDoc({
                                          index: doucomentOfCategory?.index,
                                          selectedUrlIndex: selectedUrl.index,
                                        })
                                      }
                                      className={styles.deleteIcon}
                                    />
                                  </Box>
                                </>
                              )}
                            </Box>
                          ))}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      }
      moreAction={
        <CustomButton
          boxShadow="none"
          size="lg"
          variant="text"
          color="tertiaryGray"
          startIcon={
            <Download01
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          }
          onClick={() => {
            handleOnClickDownload(selectedDocToDownload);
          }}
          style={{ marginInlineEnd: "auto" }}
        >
          {translate("Download")}
        </CustomButton>
      }
    />
  );
}
export default ContactEditAllItemsRelatedCategoryDialog;
