import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Spinner,
  Tables,
  PhonesComponent,
  Inputs,
} from "../../../../../Components";
import { TableFilterTypesEnum } from "../../../../../Enums";
import { GetParams } from "../../../../../Helper";
import { GetDeveloperAgentsAPI } from "../../../../../Services";

export const DeveloperProfileAgentsOverviewComponent = ({
  id,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [developerId, setDeveloperId] = useState(+GetParams("id"));
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const searchTimer = useRef(null);
  const [agentsOverview, setAgentsOverview] = useState({
    result: [],
    totalCount: 0,
  });

  const [filter, setFilter] = useState({
    developerId: +GetParams("id"),
    pageSize: +localStorage.getItem("Pagination") || 25,
    pageIndex: 0,
    agentName: null,
    staffId: null,
    mobile: null,
    email: null,
  });
  const [filterValues, setFilterValues] = useState({});
  const [tableFilterData, setTableFilterData] = useState([]);

  const [helperText, setHelperText] = useState({
    email: "",
    mobile: "",
  });
  const [values, setValues] = useState({
    email: "",
    mobile: "",
  });
  const HeaderDataTable = [
    {
      id: 1,

      label: "agent-name",
      input: "agentName",
      isHiddenFilter: false,
    },
    {
      id: 2,
      label: "staff-id",
      input: "staffId",
      isHiddenFilter: false,
    },
    {
      id: 3,
      label: "branch-name",
      input: "branchName",
      isHiddenFilter: true,
    },
    {
      id: 4,
      label: "mobile",
      input: "mobile",
      isHiddenFilter: false,
    },
    {
      id: 5,
      label: "email",
      input: "email",
      isHiddenFilter: false,
    },

    {
      id: 6,
      label: "number-of-transacted-units",
      input: "numberOfTransactedUnits",
      isHiddenFilter: true,
    },
    {
      id: 7,
      label: "specialty-scope",
      input: "specialtyScope",
      isHiddenFilter: true,
    },
    {
      id: 8,
      label: "transaction-value",
      input: "transactionValue",
      isHiddenFilter: true,
    },
  ];
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const GetAllDeveloperAgents = useCallback(async (searchObj) => {
    setIsLoading(true);
    const res = await GetDeveloperAgentsAPI(
      searchObj
        ? {
            ...searchObj,
            pageIndex: filter.pageIndex + 1,
          }
        : { ...filter, pageIndex: filter.pageIndex + 1 }
    );
    if (!(res && res.status && res.status !== 200)) {
      setAgentsOverview({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAgentsOverview({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (filterValues && Object.keys(filterValues).length > 0) {
      Object.values(filterValues).map((item, index) => {
        setFilter((i) => ({
          ...i,
          [item.displayPath]: item.value,
        }));
      });
    }
  }, [filterValues]);

  useEffect(() => {
    GetAllDeveloperAgents();
  }, [GetAllDeveloperAgents]);

  useEffect(() => {
    setTableFilterData(
      HeaderDataTable.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
      }))
    );
  }, []);

  const onFilterValuesChanged = (newValue) => {
    const localFilterDto = filter || {};
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.displayPath])
            localFilterDto[item.displayPath] =
              item?.value && item.value !== "" ? item.value : null;
          else if (item.value)
            localFilterDto[item.displayPath] =
              item?.value && item.value !== "" ? item.value : null;
        });
    }
    setFilter({ ...localFilterDto, pageIndex: 0 });
    setValues({
      ...values,
      email: localFilterDto.email || "",
      mobile: localFilterDto.mobile || "",
    });
  };

  return (
    <div className="w-100">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="developers-tab">
        <div className="header-section-Contact-Activity">
          <div className="filter-section px-2">
            <div className="developers-tab-filters-section">
              <div className="d-inline-flex flex-h-between w-100 mb-2">
                <div className="w-50 px-2 p-relative">
                  <Inputs
                    idRef="agentNameRef"
                    value={filter.agentName || ""}
                    inputPlaceholder={t(`${translationPath}enter-agent-name`)}
                    beforeIconClasses="mdi mdi-magnify mdi-24px c-gray-primary"
                    onInputChanged={(event) => {
                      let newValue =
                        (event.target.value &&
                          event.target.value !== "" &&
                          event.target.value) ||
                        null;
                        setFilter({
                          ...filter,
                          agentName: newValue || null,
                        });
                      setFilterValues({
                        ...filterValues,
                        agentName: {
                          value: newValue,
                          operator: 1,
                          displayPath: "agentName",
                          searchableKey: "agentName",
                        },
                      });
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
                <div className="w-50 px-2 p-relative">
                  <Inputs
                    idRef="staffIdRef"
                    value={filter.staffId || ""}
                    inputPlaceholder={t(`${translationPath}enter-staffId`)}
                    beforeIconClasses="mdi mdi-magnify mdi-24px c-gray-primary"
                    onInputChanged={(event) => { 
                      let  newValue =  (event.target.value && event.target.value !== "" && event.target.value) || null;
                        setFilter({
                          ...filter,
                          staffId: newValue || null,
                        });
                      setFilterValues({
                        ...filterValues,
                        staffId: {
                          value: newValue,
                          operator: 1,
                          displayPath: "staffId",
                          searchableKey: "staffId",
                        },
                      });
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
              </div>
              <div className="d-inline-flex flex-h-between w-100 mb-2">
                <div className="w-50 px-2 p-relative">
                  <PhonesComponent
                    idRef={`mobileRef`}
                    value={values.mobile || ""}
                    helperText={
                      (helperText?.mobile !== "" && t(helperText?.mobile)) || ""
                    }
                    error={helperText?.mobile !== ""}
                    inputPlaceholder={"enter-mobile-number"}
                    onInputChanged={(value) => {
                      let newValue = value;
                      newValue = newValue.replaceAll(" ", "");
                      if(newValue && newValue !== '' && !newValue.includes('+'))
                        newValue = '+' + newValue ;
                      if (
                        (newValue !== "" && newValue.length < 7) ||
                        (newValue !== "" && newValue.length > 14)
                      ) {
                        setHelperText((item) => ({
                          ...item,
                          mobile: t("please-enter-vaild-mobile-number"),
                        }));
                      } else {
                        setHelperText((item) => ({ ...item, mobile: "" }));
                      }
                      setValues((item) => ({
                        ...item,
                        mobile: value,
                      }));
                      
                        setFilter((item) => ({
                          ...item,
                          mobile: (newValue !== '' ?  newValue :  null ) ,
                        }));
                        setFilterValues({
                          ...filterValues,
                          mobile: {
                            value: (newValue !== '' ?  newValue :  null ),
                            operator: 1,
                            displayPath: "mobile",
                            searchableKey: "mobile",
                          },
                        });
                    }}
                    // onKeyUp={(e) => {
                    //   let { value } = e.target;
                    //   value = value.replaceAll(" ", "");
                    //   if (searchTimer.current)
                    //     clearTimeout(searchTimer.current);
                    //   searchTimer.current = setTimeout(() => {
                    //     setFilter((item) => ({
                    //       ...item,
                    //       mobile: value,
                    //     }));
                    //     setFilterValues({
                    //       ...filterValues,
                    //       mobile: {
                    //         value: value,
                    //         operator: 1,
                    //         displayPath: "mobile",
                    //         searchableKey: "mobile",
                    //       },
                    //     });
                    //   }, 700);
                    // }}
                    // onKeyDown={() => {
                    //   if (searchTimer) clearTimeout(searchTimer.current);
                    // }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>{" "}
                <div className="w-50 px-2 p-relative">
                  <Inputs
                    wrapperClasses="w-100"
                    inputPlaceholder={"email-address"}
                    idRef={`email-adressRef`}
                    value={values.email}
                    helperText={
                      (helperText?.email !== "" && t(helperText?.email)) || ""
                    }
                    error={helperText?.email !== ""}
                    onInputChanged={(e) => {
                      let { value } = e?.target;
                      const itemRegex = new RegExp(
                        /^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|\*{8})$/
                      );
                      if (
                        typeof value === "string" &&
                        value !== "" &&
                        !itemRegex.test(value)
                      ) {
                        setHelperText((item) => ({
                          ...item,
                          email: t("please-fill-email-in-correct-way"),
                        }));
                      } else {
                        setHelperText((item) => ({
                          ...item,
                          email: "",
                        }));
                      }
                      setValues((item) => ({
                        ...item,
                        email: value,
                      }));
                    
                        setFilter((item) => ({
                          ...item,
                          email:  (value !== '' ?  value :  null ),
                        }));
                        setFilterValues({
                          ...filterValues,
                          email: {
                            value: (value !== '' ?  value :  null ),
                            operator: 1,
                            displayPath: "email",
                            searchableKey: "email",
                          },
                        });
                    }}
                    // onKeyUp={(e) => {
                    //   let { value } = e.target;
                    //   if (searchTimer.current)
                    //     clearTimeout(searchTimer.current);
                    //   searchTimer.current = setTimeout(() => {
                    //     if (helperText.email !== "") return;
                    //     setFilter((item) => ({
                    //       ...item,
                    //       email: value,
                    //     }));
                    //     setFilterValues({
                    //       ...filterValues,
                    //       email: {
                    //         value: value,
                    //         operator: 1,
                    //         displayPath: "email",
                    //         searchableKey: "email",
                    //       },
                    //     });
                    //   }, 700);
                    // }}
                    // onKeyDown={() => {
                    //   if (searchTimer) clearTimeout(searchTimer.current);
                    // }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 px-4 d-flex-center fj-end mb-2">
          <span className="">
            <ButtonBase
              onClick={() => {
                setIsClearFiltersClicked(false);
                GetAllDeveloperAgents({ ...filter, pageIndex: 0 });
              }}
              id="filterBtn"
              className={`px-2 btns theme-solid`}
            >
              <span className={`"mdi mdi-arrow-expand-left m-1`} />
              {t(`${translationPath}apply-filters`)}
            </ButtonBase>
            <ButtonBase
              onClick={() => {
                const clearFilter = {
                  ...filter,
                  pageIndex: 0,
                  agentName: null,
                  staffId: null,
                  mobile: null,
                  email: null,
                };
                setFilter(clearFilter);
                setValues((item) => ({
                  ...item,
                  email: "",
                  mobile: "",
                }));
                setFilterValues({});
                setIsClearFiltersClicked(true);
                setHelperText((item) => ({
                  ...item,
                  email: "",
                  mobile: "",
                }));
                GetAllDeveloperAgents(clearFilter);
              }}
              id="onClearedAllFiltersref"
              className="btns px-2 theme-solid bg-danger clear-all-btn"
            >
              <span className="mdi mdi-filter-remove m-1" />
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
          </span>
        </div>

        <div className="w-100 px-3">
          <Tables
            data={agentsOverview.result}
            headerData={HeaderDataTable}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            dateFormat="DD, MMM, YYYY"
            defaultActions={[]}
            filterData={tableFilterData}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            totalItems={agentsOverview.totalCount}
            isWithFilter
            onFilterValuesChanged={onFilterValuesChanged}
            isClearFiltersClicked={isClearFiltersClicked}
            setIsClearFiltersClicked={setIsClearFiltersClicked}
            filterValues={filterValues}
          />
        </div>
      </div>
    </div>
  );
};

DeveloperProfileAgentsOverviewComponent.propTypes = {
  id: PropTypes.number.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
