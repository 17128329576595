import React, { useContext, useState } from "react";
import { Box, Typography, FormControlLabel, Divider } from "@material-ui/core";
import {
  CustomButton,
  CustomDialog,
  CustomInput,
  CustomRadioButton,
  CustomSelect,
} from "../../../../../Components";
import { useSelectedTheme } from "../../../../../Hooks";
import { CreateFavoriteFolders, DeleteFavoriteFolder, StoreFavoriteContacts } from "../../../../../Services/FavoriteFoldersServices";
import { ContactLayoutContext } from "../../../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import { useVerticalNav } from "../../../../../Contexts/VerticalNavContext";

// Icons
import { Download01, TrashOne } from "../../../../../assets/icons";

// Styles
import useStyles from "./styles";

function AddToFavouriteDialog({ open, onClose, favoriteFolders, setFavoriteFolders }) {
  const styles = useStyles();

  const [selectedFolder, setSelectedFolder] = useState(undefined);
  const [isCreateNew, setIsCreateNew] = useState(false);

  const [favoriteName, setFavoriteName] = useState("");
  const [favoriteTag, setFavoriteTag] = useState("");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { setAlertBoxContent } = useVerticalNav();

  const { actionableItems, setActionableItems, setContactsData } = useContext(ContactLayoutContext);

  const handleCreateFolder = async () => {
    try {
      await CreateFavoriteFolders({
        name: favoriteName,
        tags: favoriteTag,
        favoriteFolderType: 3
      });

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Favorite Folder Created Successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });

      setFavoriteFolders((prev) => {
        return [
          ...prev,
          {
            favoriteFolderId: prev.length + 1,
            name: favoriteName,
            tags: favoriteTag,
          },
        ];
      });

      setFavoriteName("");
      setFavoriteTag("");

    } catch (error) {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Create Favorite Folder!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setIsCreateNew(false);
    }
  }

  const handleConfirm = async () => {
    try {
      await StoreFavoriteContacts({
        folderId: selectedFolder,
        favoriteContactIds: actionableItems?.selectedIds,
      });

      setActionableItems({
        selectedIds: [],
        action: null,
      })
      
      setIsCreateNew(false);
      setSelectedFolder(undefined);

      setContactsData((prev) => {
          return {
            ...prev,
            result: prev.result.map((contact) => {
              if (actionableItems?.selectedIds.includes(contact.id)) {
                return {
                  ...contact,
                  isFavorite: true,
                };
              }

              return contact;
            }),
          };
        }
      );
      
      onClose();

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Contacts Added to Favorite Folder Successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } catch (error) {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Add Contacts to Favorite Folder!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    }
  }

  const handleDeleteFolder = async (id) => {
    try {
      await DeleteFavoriteFolder(id);

      setFavoriteFolders((prev) => {
        return prev.filter((favorite) => favorite.favoriteFolderId !== id);
      });

      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Favorite Folder Deleted Successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });

      selectedFolder === id && setSelectedFolder(undefined);
    } catch (error) {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Delete Favorite Folder!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    }
  }

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        onClose();
        setIsCreateNew(false);
        setSelectedFolder(undefined);
      }}
      onConfirm={() => {
        if (isCreateNew) {
          handleCreateFolder();
        } else {
          handleConfirm();
        }
      }}
      title="Add to favourite"
      confirmText={isCreateNew ? "Save" : "Done"}
      isDisabled={isCreateNew ? (!favoriteName || !favoriteTag) : !selectedFolder}
      cancelText="Discard"
      width="480px"
      paperClassName={styles.customPaper}
      content={
        <Box>
          <CustomSelect
            options={favoriteFolders}
            onValueChange={(value) => {
              setSelectedFolder(value);
            }}
            emptyItem={{
              id: 0,
              name: "My favourite",
              isDisabled: false,
            }}
            variant="default"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.favoriteFolderId}
            width="100%"
            customSelectClasses={styles.customSelect}
            defaultValue={selectedFolder ?? ""}
          />
          <Box>
            <Typography variant="body2" className={styles.selectionHeading}>
              Recent collections:
            </Typography>

            <Box className={styles.favouriteCollections}>
              {favoriteFolders?.slice(-3).map((favorite) => {
                return (
                  <Box
                    key={favorite?.favoriteFolderId}
                    className={styles.radioWrapper}
                  >
                    <FormControlLabel
                      className={styles.radioControlLabel}
                      control={
                        <CustomRadioButton
                          checked={
                            selectedFolder === favorite?.favoriteFolderId
                          }
                          value="option2"
                          onChange={(e) => {
                            setSelectedFolder(favorite?.favoriteFolderId);
                          }}
                        />
                      }
                      label={
                        <Box>
                          <Typography
                            className={styles.favouriteName}
                            variant="body2"
                          >
                            {favorite.name}
                          </Typography>
                          <Typography
                            className={styles.favouriteTag}
                            variant="body2"
                          >
                            {favorite.tags}
                          </Typography>
                        </Box>
                      }
                    />

                    <TrashOne
                      width="20"
                      height="20"
                      fill={palette.button.tertiaryGray_fg}
                      className={styles.trashIcon}
                      onClick={() => {
                        handleDeleteFolder(favorite?.favoriteFolderId);
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
            {isCreateNew && (
              <Box>
                <Divider className={styles.divider} />

                <CustomInput
                  placeholder={"Enter collection name"}
                  type="text"
                  value={favoriteName}
                  hasSearchIcon={false}
                  onChange={(e) => {
                    setFavoriteName(e.target.value);
                  }}
                  style={{ marginBottom: "16px" }}
                  inputContainerOverrideStyles={styles.customInput}
                />

                <CustomInput
                  placeholder={"Enter tags"}
                  type="text"
                  value={favoriteTag}
                  hasSearchIcon={false}
                  onChange={(e) => {
                    setFavoriteTag(e.target.value);
                  }}
                  style={{ marginBottom: "12px" }}
                  inputContainerOverrideStyles={styles.customInput}
                />
              </Box>
            )}
          </Box>
        </Box>
      }
      moreAction={
        <CustomButton
          boxShadow="none"
          size="lg"
          variant="text"
          color="tertiaryGray"
          startIcon={
            <Download01
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          }
          onClick={() => {
            console.log("more action clicked");
            setIsCreateNew((prev) => !prev);
          }}
          style={{ marginInlineEnd: "auto" }}
        >
          Create a new collection
        </CustomButton>
      }
    />
  );
}

export default AddToFavouriteDialog;
