export const PaymentPlansLocale = {
  en: {
    payment_plans: 'Payment Plans',
    payment_plan_configuration: 'Payment Plan Configuration',
    payment_plan_configuration_text:
      'Configure, manage, and customize payment plans to align with specific developer needs or general application across properties. Ensure flexible and tailored payment options to optimize engagement and client satisfaction.',
    add_new_payment_plan: 'Add New Payment Plan',
    edit_payment_plan: 'Edit Payment Plan',
    add_payment_plan: 'Add Payment Plan',
    status: 'Status',
    specific_developer: 'Specific Developer',
    master_developer: 'Master Developer',
    secondary_developer: 'Secondary Developer',
    payment_plan_name: 'Payment Plan Name',
    plan_type: 'Plan Type',
    description: 'Description',
    attachment: 'Attachment',
    down_payment: 'Down Payment',
    handover_payment: 'Handover Payment',
    installment: 'Installment',
    frequency: 'Frequency',
    number_of_installment: 'Number of Installments',
    months: 'Months',
    by_fixed_date: 'By Fixed Date',
    select_installment_date: 'Select the Installment Date',
    add_another: 'Add Another',
    post_handover_installment: 'Post-Handover Installment',
    by_duration: 'By Duration',
    by_specific_date: 'By Specific Date',
    select_timeframe:
      'Select the timeframe (months or years) to pay the entered percentage.',
    active: 'Active',
    inactive: 'Inactive',
    by_month: 'By Month',
    by_month_text:
      'Set how often payments are made (e.g., 1 for monthly, 2 for every 2 months), and so on.',
    installment_based_on_booking_date: 'Installment based on booking date',
    plan_name: 'Plan Name',
    monthly_installment: 'Monthly Installment',
    custom_installment: 'Custom Installment',
    post_handover: 'Post Handover',
    installmentAmountPercentageMin:
      'Amount percentage should be greater than 1',
    installmentAmountPercentageMax: 'Amount percentage should be less than 99',
    duration: 'Duration',
    number_of_installment_min:
      'Number of installments should be greater than 1',
    amount_percentage: 'Amount Percentage',
    total_installments: 'Total Installments',
    total_installments_error: 'Total Installments should be exactly 100%',
    delete_payment_plan: 'Delete Payment Plan',
    confirmation_text: 'Are you sure you want to delete this payment plan?',
    confirm: 'Confirm',
    error_occurred: 'Something went wrong, please try again',
    deleted_successfully: 'Deleted successfully',
    payment_plan_title: 'Payment Plan',
    payment_plan_text:
      'Select from predefined payment plans or create a custom plan to offer tailored financing solutions, enhancing client engagement and flexibility.',
    select: 'Select',
    selected: 'Selected',
    installments: 'Installments',
    add_custom: 'Add new custom plan',
    selected_successfully: 'Payment plan has been selected',
    by_booking_date_text:
      'Enter the number of months to pay the selected percentage.',
    number_of_frequency: 'Number of Frequency',
    number_of_installment: 'Number of Installments',
    valueMustBePositive: 'Value must be positive',
    string_decimal: 'Value must be a whole number',
  },
  ar: {
    payment_plans: 'خطط الدفع',
    payment_plan_configuration: 'إعداد خطة الدفع',
    payment_plan_configuration_text:
      'قم بتكوين وإدارة وتخصيص خطط الدفع لتتناسب مع احتياجات المطور المحددة أو للتطبيق العام عبر العقارات. تأكد من توفير خيارات دفع مرنة ومخصصة لتحسين المشاركة ورضا العملاء.',
    add_new_payment_plan: 'إضافة خطة دفع جديدة',
    edit_payment_plan: 'تعديل خطة الدفع',
    add_payment_plan: 'إضافة خطة دفع',
    status: 'الحالة',
    specific_developer: 'المطور المحدد',
    master_developer: 'المطور الرئيسي',
    secondary_developer: 'المطور الثانوي',
    payment_plan_name: 'اسم خطة الدفع',
    plan_type: 'نوع الخطة',
    description: 'الوصف',
    attachment: 'المرفق',
    down_payment: 'الدفعة الأولى',
    handover_payment: 'دفعة التسليم',
    installment: 'القسط',
    frequency: 'التكرار',
    number_of_installment: 'عدد الأقساط',
    months: 'الشهور',
    by_fixed_date: 'بتاريخ محدد',
    select_installment_date: 'حدد تاريخ القسط',
    add_another: 'أضف آخر',
    post_handover_installment: 'قسط ما بعد التسليم',
    by_duration: 'بناءً على المدة',
    by_specific_date: 'بناءً على تاريخ محدد',
    select_timeframe:
      'حدد الإطار الزمني (بالأشهر أو السنوات) لدفع النسبة المدخلة.',

    active: 'نشط',
    inactive: 'غير نشط',
    by_month: 'حسب الشهر',
    by_month_text: 'حدد عدد مرات الدفع (مثلاً، 1 شهريًا، 2 لكل شهرين)، وهكذا.',
    installment_based_on_booking_date: 'القسط بناءً على تاريخ الحجز',
    plan_name: 'اسم الخطة',
    monthly_installment: 'القسط الشهري',
    custom_installment: 'قسط مخصص',
    post_handover: 'ما بعد التسليم',
    installmentAmountPercentageMin: 'يجب أن تكون نسبة المبلغ أكبر من 1',
    installmentAmountPercentageMax: 'يجب أن تكون نسبة المبلغ أقل من 99',
    duration: 'المدة',
    number_of_installment_min: 'يجب أن يكون عدد الأقساط أكبر من 1',
    amount_percentage: 'نسبة المبلغ',
    total_installments: 'إجمالي الأقساط',
    total_installments_error: 'يجب أن يكون إجمالي الأقساط 100٪ بالضبط',
    delete_payment_plan: 'حذف خطة الدفع',
    confirmation_text: 'هل أنت متأكد أنك تريد حذف خطة الدفع هذه؟',
    confirm: 'تأكيد',
    error_occurred: 'حدث خطأ ما، يرجى المحاولة مرة أخرى',
    deleted_successfully: 'تم الحذف بنجاح',
    payment_plan_title: 'خطة الدفع',
    payment_plan_text:
      'اختر من بين خطط الدفع المحددة مسبقًا أو أنشئ خطة مخصصة لتقديم حلول تمويل مصممة خصيصًا، مما يعزز تفاعل العملاء والمرونة.',
    select: 'اختر',
    selected: 'تم الاختيار',
    installments: 'أقساط',
    add_custom: 'إضافة خطة مخصصة جديدة',
    selected_successfully: 'تم اختيار خطة الدفع',
    by_booking_date_text: 'أدخل عدد الأشهر لدفع النسبة المحددة.',
    number_of_frequency: 'عدد التكرارات',
    number_of_installment: 'عدد الأقساط',
    valueMustBePositive: 'يجب أن تكون القيمة موجبة',
    string_decimal: 'يجب أن تكون القيمة عددًا صحيحًا',
  },
  ru: {
    payment_plans: 'Платежные планы',
    payment_plan_configuration: 'Настройка плана оплаты',
    payment_plan_configuration_text:
      'Настройте, управляйте и адаптируйте планы оплаты в соответствии с потребностями конкретного застройщика или для общего применения на объектах. Обеспечьте гибкие и персонализированные варианты оплаты для повышения вовлеченности и удовлетворенности клиентов.',
    add_new_payment_plan: 'Добавить новый план оплаты',
    edit_payment_plan: 'Редактировать план оплаты',
    add_payment_plan: 'Добавить план оплаты',
    status: 'Статус',
    specific_developer: 'Конкретный застройщик',
    master_developer: 'Главный застройщик',
    secondary_developer: 'Второстепенный застройщик',
    payment_plan_name: 'Название плана оплаты',
    plan_type: 'Тип плана',
    description: 'Описание',
    attachment: 'Вложение',
    down_payment: 'Первоначальный взнос',
    handover_payment: 'Оплата при передаче',
    installment: 'Взнос',
    frequency: 'Частота',
    number_of_installment: 'Количество взносов',
    months: 'Месяцы',
    by_fixed_date: 'К фиксированной дате',
    select_installment_date: 'Выберите дату взноса',
    add_another: 'Добавить ещё один',
    post_handover_installment: 'Взнос после передачи',
    by_duration: 'По длительности',
    by_specific_date: 'По конкретной дате',
    select_timeframe:
      'Выберите временные рамки (месяцы или годы) для оплаты введённого процента.',
    active: 'Активный',
    inactive: 'Неактивный',
    by_month: 'По месяцам',
    by_month_text:
      'Укажите, как часто совершаются платежи (например, 1 — ежемесячно, 2 — каждые 2 месяца) и так далее.',
    installment_based_on_booking_date: 'Взнос на основе даты бронирования',
    plan_name: 'Название плана',
    monthly_installment: 'Ежемесячный взнос',
    custom_installment: 'Пользовательский взнос',
    post_handover: 'После передачи',
    installmentAmountPercentageMin: 'Процент суммы должен быть больше 1',
    installmentAmountPercentageMax: 'Процент суммы должен быть меньше 99',
    duration: 'Длительность',
    number_of_installment_min: 'Количество взносов должно быть больше 1',
    amount_percentage: 'Процент суммы',
    total_installments: 'Общее количество взносов',
    total_installments_error: 'Общая сумма взносов должна быть ровно 100%',
    delete_payment_plan: 'Удалить план платежей',
    confirmation_text: 'Вы уверены, что хотите удалить этот план платежей?',
    confirm: 'Подтвердить',
    error_occurred: 'Произошла ошибка, пожалуйста, попробуйте снова',
    deleted_successfully: 'Успешно удалено',
    payment_plan_title: 'План платежей',
    payment_plan_text:
      'Выберите из заранее определенных планов платежей или создайте индивидуальный план, чтобы предложить адаптированные финансовые решения, улучшая вовлеченность и гибкость клиентов.',
    select: 'Выбрать',
    selected: 'Выбрано',
    installments: 'Взносы',
    add_custom: 'Добавить новый индивидуальный план',
    selected_successfully: 'План платежей был выбран',
    by_booking_date_text:
      'Введите количество месяцев для оплаты выбранного процента.',
    number_of_frequency: 'Количество повторений',
    number_of_installment: 'Количество взносов',
    valueMustBePositive: 'Значение должно быть положительным',
    string_decimal: 'Значение должно быть целым числом',
  },
  cn: {
    payment_plans: '付款计划',
    payment_plan_configuration: '付款计划配置',
    payment_plan_configuration_text:
      '配置、管理和定制付款计划，以符合特定开发商需求或一般适用于物业。确保提供灵活且量身定制的付款选项，以优化参与度和客户满意度。',
    add_new_payment_plan: '添加新付款计划',
    edit_payment_plan: '编辑付款计划',
    add_payment_plan: '添加付款计划',
    status: '状态',
    specific_developer: '特定开发商',
    master_developer: '主开发商',
    secondary_developer: '次开发商',
    payment_plan_name: '付款计划名称',
    plan_type: '计划类型',
    description: '描述',
    attachment: '附件',
    down_payment: '首付款',
    handover_payment: '交接付款',
    installment: '分期付款',
    frequency: '频率',
    number_of_installment: '分期付款次数',
    months: '月份',
    by_fixed_date: '按固定日期',
    select_installment_date: '选择分期付款日期',
    add_another: '添加另一个',
    post_handover_installment: '交接后分期付款',
    by_duration: '按时长',
    by_specific_date: '按特定日期',
    select_timeframe: '选择时间框架（按月或年）以支付输入的百分比。',
    active: '活跃',
    inactive: '非活跃',
    by_month: '按月',
    by_month_text:
      '设置付款频率（例如，1表示每月付款，2表示每两个月付款），依此类推。',
    installment_based_on_booking_date: '基于预订日期的分期付款',
    plan_name: '计划名称',
    monthly_installment: '每月分期付款',
    custom_installment: '自定义分期付款',
    post_handover: '交接后',
    installmentAmountPercentageMin: '金额百分比应大于1',
    installmentAmountPercentageMax: '金额百分比应小于99',
    duration: '持续时间',
    number_of_installment_min: '分期付款次数应大于1',
    amount_percentage: '金额百分比',
    total_installments: '总分期付款',
    total_installments_error: '总分期付款应恰好为100%',
    delete_payment_plan: '删除付款计划',
    confirmation_text: '您确定要删除此付款计划吗？',
    confirm: '确认',
    error_occurred: '发生错误，请重试',
    deleted_successfully: '删除成功',
    payment_plan_title: '付款计划',
    payment_plan_text:
      '从预定义的付款计划中选择或创建自定义计划，以提供量身定制的融资解决方案，增强客户参与度和灵活性。',
    select: '选择',
    selected: '已选择',
    installments: '分期付款',
    add_custom: '添加新的自定义计划',
    selected_successfully: '付款计划已被选择',
    by_booking_date_text: '输入支付所选百分比的月份数。',
    number_of_frequency: '频率次数',
    number_of_installment: '分期付款次数',
    valueMustBePositive: '值必须为正数',
    string_decimal: '值必须是整数',
  },
};

export const PaymentPlansLocalePath = 'PaymentPlans';
