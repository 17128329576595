import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerFileds: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    containerFiledItem: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
    },
    sectionLable: {
      display: "flex",
      flexDirection: "column",
    },
    lable: {
      maxWidth: "280px",
      minWidth: "160px",
      fontSize:'14px',
      fontWeight:'600',
      lineHeight:'20px',
      color:theme.palette.text.secondary
    },
    subLable: {
      maxWidth: "280px",
      minWidth: "160px",
      fontSize:'14px',
      fontWeight:'400',
      lineHeight:'20px',
      color:theme.palette.text.tertiary
    },
    divider: {
      backgroundColor: theme.palette.border.gray_200,
    },containerAutocomplete:{
        width:'100%'
    }
  };
});
