import { UnitInvoicePaymentDue } from '../../../UnitsView/UnitsReservationView/Sections/UnitInvoicePaymentDue';
import {
  Details,
  ReferenceDetails,
  Earnings,
  Documents,
  ChequeRequestsView,
} from '../Sections';

export const SalesTransactionsProfileTabsData = [
  {
    label: 'details',
    component: Details,

  },
  {
    label: 'reference-details',
    component: ReferenceDetails,
  
  },
  {
    label: 'earnings',
    component: Earnings,
  },
  {
    label: 'invoices-payments-due',
    component: UnitInvoicePaymentDue,
 
  },
  {
    label: 'cheque-requests',
    component: ChequeRequestsView,
   
  },
  {
    label: 'documents',
    component: Documents,
  
  },
];
