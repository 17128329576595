import React, { useCallback, useEffect, useRef, useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  AutocompleteComponent,
  Inputs,
  PaginationComponent,
  Spinner,
  Tables,
  PermissionsComponent,
} from '../../../../Components';
import { useTitle } from '../../../../Hooks';
import { bottomBoxComponentUpdate, GlobalHistory , returnPropsByPermissions  } from '../../../../Helper';
import { GetAllLandmarkDetails, lookupItemsGetId, lookupItemsGet } from '../../../../Services';
import { StaticLookupsIds } from '../../../../assets/json/StaticLookupsIds';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { LocationsPermissions, LookupsPermissions , LandmarksPermissions } from '../../../../Permissions';
import { useDisabledLookupContext } from '../../../../Contexts/DisabledLookupContext';

export const LandmarkView = () => {
  const parentTranslationPath = 'LocationView';
  const translationPath = '';
  const LandmarkCategoriesLookupName = 'LandmarkCategories';
  const disabled = useDisabledLookupContext()

  const { t } = useTranslation(parentTranslationPath);
  useTitle(t('Shared:Landmarks'));
  const searchTimer = useRef(null);
  const dispatch = useDispatch();

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useReducer(reducer, {
    countries: [],
    cities: [],
    districts: [],
    communities: [],
    subCommunities: [],
    landmarkCategories: [],
  });
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    landmarkName: null,
    categoryId: null,
    countryId: StaticLookupsIds.UAECities,
    cityId: null,
    districtId: null,
    communityId: null,
    subCommunityId: null,
  });

  const [selected, setSelected] = useState({
    country: {
      lookupItemId: StaticLookupsIds.UAECities,
      lookupItemName: 'United Arab Emirates',
    },
    city: null,
    district: null,
    community: null,
    subCommunity: null,
    category: null,
  });
  const [landmarksData, setLandmarksData] = useState({
    result: [],
    totalCount: 0,
  });

  const getAllLandmarkDetails = async () => {
    setIsLoading(true);
    if(returnPropsByPermissions(LandmarksPermissions.ViewAllLandmarks.permissionsId))
    {
    const response = await GetAllLandmarkDetails({
      ...filter,
      pageIndex: filter.pageIndex + 1,
    });

    if (!(response && response.status && response.status !== 200)) {
      setLandmarksData({
        result: response?.result || [],
        totalCount: response?.totalCount || 0,
      });
    } else {
      setLandmarksData({
        result: [],
        totalCount: 0,
      });
    }
   }
    setIsLoading(false);
  };

  const getAddressLookupsById = async (lookupTypeId, lookupParentId) => {
    const response = await lookupItemsGetId({
      lookupTypeId,
      lookupParentId,
    });
    if (!(response && response.status && response.status !== 200)) {
      if (lookupTypeId === StaticLookupsIds.Country)
        setData({ id: 'countries', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.Cities)
        setData({ id: 'cities', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.District)
        setData({ id: 'districts', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.Community)
        setData({ id: 'communities', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.SubCommunity)
        setData({ id: 'subCommunities', value: response || [] });
    }
  };

  const getLookupsByName = async (lookupTypeName, searchedItem) => {
    const response = await lookupItemsGet({
      ...filter,
      lookupTypeName,
      searchedItem,
    });
    if (!(response && response.status && response.status !== 200)) {
      if (lookupTypeName === LandmarkCategoriesLookupName)
        setData({ id: 'landmarkCategories', value: response?.result || [] });
    }
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((prevState) => ({ ...prevState, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((prevState) => ({ ...prevState, pageIndex: 0, pageSize }));
  };

  const tableActionClicked = useCallback((item) => {
    dispatch(ActiveItemActions.activeItemRequest(item));
    GlobalHistory.push(`/home/Landmarks/edit?id=${item.landmarkId}`);
  });

  const addLandmarkHandler = () => {
    GlobalHistory.push(`/home/Landmarks/add`);
  };

  useEffect(() => {
    getAddressLookupsById(StaticLookupsIds.Country);
    getAddressLookupsById(StaticLookupsIds.Cities, selected.country.lookupItemId);
  }, []);

  useEffect(() => {
    getAllLandmarkDetails();
  }, [filter]);

  useEffect(() => {
    if(returnPropsByPermissions(LandmarksPermissions.ViewAllLandmarks.permissionsId))
    { bottomBoxComponentUpdate(
        <PaginationComponent
           pageIndex={filter.pageIndex}
           pageSize={filter.pageSize}
           totalCount={landmarksData.totalCount}
           onPageIndexChanged={onPageIndexChanged}
           onPageSizeChanged={onPageSizeChanged}
        />
       );}
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  return (
    <div className='view-wrapper landmarksView-wrapper'>
      <Spinner isActive={isLoading.landmarks} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section px-1'>
              <PermissionsComponent
                permissionsList={Object.values(LandmarksPermissions)}
                permissionsId={LandmarksPermissions.CreateLandmarks.permissionsId}>
                <ButtonBase
                  onClick={addLandmarkHandler}
                  className='btns theme-solid bg-primary'
                  disabled={disabled}
                >
                  <span className='mdi mdi-plus' />
                  <span className='px-1'>{t(`${translationPath}add-new-landmark`)}</span>
                </ButtonBase>
              </PermissionsComponent>
            </div>
            <PermissionsComponent
                permissionsList={Object.values(LandmarksPermissions)}
                permissionsId={LandmarksPermissions.ViewAllLandmarks.permissionsId}>
            <div className='section autocomplete-section'>
              <div className='d-flex-column px-2 w-100 p-relative'>
                <div className='w-100 p-relative'>
                  <Inputs
                    idRef='CountrySearchRef'
                    label='filterLandmarks'
                    beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                    onInputChanged ={(e) => {
                      const value = e?.target?.value;
                      if (searchTimer.current) clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        setFilter((prevState) => ({
                          ...prevState,
                          landmarkName: value,
                        }));
                      }, 500);
                    }}
                    inputPlaceholder='search-Landmarks'
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              </div>
            </div>
            </PermissionsComponent>
          </div>
          <PermissionsComponent
                permissionsList={Object.values(LandmarksPermissions)}
                permissionsId={LandmarksPermissions.ViewAllLandmarks.permissionsId}>

          <form className='images-gallery-filter-wrapper px-2 mx-1'>
            <span className='title-text'>{t(`${translationPath}filter`)}</span>
            <div className='filter-item '>
              <AutocompleteComponent
                idRef='filterCountryRef'
                inputPlaceholder={t(`${translationPath}filterCountry`)}
                selectedValues={selected.country}
                multiple={false}
                data={data.countries || []}
                getOptionSelected={(option) =>
                  option.lookupItemId === selected.country.lookupItemId
                }
                displayLabel={(option) => option.lookupItemName || ''}
                withoutSearchButton
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const countryId = newValue && newValue.lookupItemId;

                  setFilter((prevState) => ({
                    ...prevState,
                    countryId: countryId,
                    cityId: null,
                    districtId: null,
                    communityId: null,
                    subCommunityId: null,
                  }));

                  setSelected({
                    ...selected,
                    country: newValue,
                    city: null,
                    district: null,
                    community: null,
                    subCommunity: null,
                  });
                  if (countryId) getAddressLookupsById(StaticLookupsIds.Cities, countryId);
                }}
              />
            </div>
            <div className='filter-item '>
              <AutocompleteComponent
                idRef='cityfilterRef'
                inputPlaceholder={t(`${translationPath}cityfilter`)}
                selectedValues={selected.city}
                multiple={false}
                data={data.cities || []}
                displayLabel={(option) => option.lookupItemName || ''}
                getOptionSelected={(option) => option.lookupItemId === selected.city.lookupItemId}
                withoutSearchButton
                isWithError
                isDisabled={!selected?.country}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const cityId = newValue && newValue.lookupItemId;

                  setFilter((prevState) => ({
                    ...prevState,
                    cityId: cityId,
                    districtId: null,
                    communityId: null,
                    subCommunityId: null,
                  }));

                  setSelected({
                    ...selected,
                    city: newValue,
                    district: null,
                    community: null,
                    subCommunity: null,
                  });
                  if (cityId) getAddressLookupsById(StaticLookupsIds.District, cityId);
                }}
              />
            </div>
            <div className='filter-item '>
              <AutocompleteComponent
                idRef='filterDistrictRef'
                inputPlaceholder={t(`${translationPath}filterDistrict`)}
                selectedValues={selected.district}
                multiple={false}
                data={data.districts || []}
                displayLabel={(option) => option.lookupItemName || ''}
                withoutSearchButton
                getOptionSelected={(option) =>
                  option.lookupItemId === selected.district.lookupItemId
                }
                isWithError
                isDisabled={!selected?.city}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const districtId = newValue && newValue.lookupItemId;

                  setFilter((prevState) => ({
                    ...prevState,
                    districtId: districtId,
                    communityId: null,
                    subCommunityId: null,
                  }));

                  setSelected({
                    ...selected,
                    district: newValue,
                    community: null,
                    subCommunity: null,
                  });
                  if (districtId) getAddressLookupsById(StaticLookupsIds.Community, districtId);
                }}
              />
            </div>
            <div className='filter-item ml-4'>
              <AutocompleteComponent
                idRef='filterCommunitiesRef'
                inputPlaceholder={t(`${translationPath}filterCommunities`)}
                selectedValues={selected.community}
                multiple={false}
                data={data.communities || []}
                displayLabel={(option) => option.lookupItemName || ''}
                withoutSearchButton
                isWithError
                isDisabled={!selected?.district}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const communityId = newValue && newValue.lookupItemId;

                  setFilter((prevState) => ({
                    ...prevState,
                    communityId: communityId,
                    subCommunityId: null,
                  }));

                  setSelected({
                    ...selected,
                    community: newValue,
                    subCommunity: null,
                  });
                  if (communityId)
                    getAddressLookupsById(StaticLookupsIds.SubCommunity, communityId);
                }}
              />
            </div>
            <div className='filter-item '>
              <AutocompleteComponent
                idRef='filterSubCommunitiesRef'
                inputPlaceholder={t(`${translationPath}filterSubCommunities`)}
                selectedValues={selected.subCommunity}
                multiple={false}
                data={data.subCommunities || []}
                displayLabel={(option) => option.lookupItemName || ''}
                withoutSearchButton
                isWithError
                isDisabled={!selected?.community}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const subCommunityId = newValue && newValue.lookupItemId;

                  setFilter((prevState) => ({
                    ...prevState,
                    subCommunityId: subCommunityId,
                  }));

                  setSelected({
                    ...selected,
                    subCommunity: newValue,
                  });
                }}
              />
            </div>
            <div className='filter-item '>
              <AutocompleteComponent
                idRef='filterCategoryIdRef'
                inputPlaceholder={t(`${translationPath}filterCategory`)}
                selectedValues={selected.category}
                multiple={false}
                data={data.landmarkCategories || []}
                onOpen={() => {
                  if (data.landmarkCategories?.length == 0)
                    getLookupsByName(LandmarkCategoriesLookupName);
                }}
                displayLabel={(option) => option.lookupItemName || ''}
                withoutSearchButton
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const categoryId = newValue && newValue.lookupItemId;

                  setFilter((prevState) => ({
                    ...prevState,
                    categoryId,
                  }));

                  setSelected({
                    ...selected,
                    category: newValue,
                  });
                }}
                onInputKeyUp={(e) => {
                  const searchedValue = e?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getLookupsByName(LandmarkCategoriesLookupName, searchedValue);
                  }, 500);
                }}
              />
            </div>
          </form>
          </PermissionsComponent>
        </div>
        <div>
        <PermissionsComponent
                permissionsList={Object.values(LandmarksPermissions)}
                permissionsId={LandmarksPermissions.ViewAllLandmarks.permissionsId}>
          <div className='w-100 px-2  '>
            <Tables
              data={landmarksData.result || []}
              headerData={[
                {
                  id: 1,
                  label: `${translationPath}landmark`,
                  input: 'landmarkEnglishName',
                },
                {
                  id: 2,
                  label: `${translationPath}category`,
                  input: 'categoryName',
                },
                {
                  id: 3,
                  label: `${translationPath}Creator`,
                  input: 'createdBy',
                },
                {
                  id: 4,
                  label: t(`${translationPath}Created`),
                  input: 'createdOn',
                  component: (item) => (
                    <>
                      {(item && item.createdOn && moment(item.createdOn).format('YYYY-MM-DD')) ||
                        'N/A'}
                    </>
                  ),
                },
                {
                  id: 5,
                  label: t(`${translationPath}Action`),
                  component: (item) => (
                    <>
                      <PermissionsComponent
                        permissionsList={Object.values(LandmarksPermissions)}
                        permissionsId={LandmarksPermissions.EditLandmarks.permissionsId}>
                        <Button
                          onClick={() => {
                            tableActionClicked(item);
                          }}
                          disabled
                          id='export-ref'
                          className='MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn  btns-icon theme-solid bg-secondary'>
                          <span className='MuiButton-label'>
                            <span className='table-action-icon  mdi mdi-lead-pencil' />
                          </span>
                        </Button>
                      </PermissionsComponent>
                    </>
                  ),
                },
              ]}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              totalItems={landmarksData?.totalCount || 0}
              defaultActions={[]}
              itemsPerPage={filter.pageSize}
              activePage={filter.pageIndex}
            />
          </div>
          </PermissionsComponent>
        </div>
      </div>
    </div>
  );
};
