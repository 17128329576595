import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  fieldContainer: {
    display: "flex",
    gap: "32px",
    padding: "16px 0 0",

    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      padding: "0 0 16px 0",
    },
    "& label": {
      width: "160px",
      fontSize: "14px",
      fontWeight: 600,
      [theme.breakpoints.down("426")]: {
        width: "100px",
      },
    },
    "& p": {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      margin: 0,
      maxWidth: "448px",
      textWrap: "pretty",
    },
    [theme.breakpoints.down("426")]: {
      flexWrap: "wrap",
    },
  },
  flex: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  textAreaInput: {
    width: "100%",
    minHeight: "154px",
    borderColor: theme.palette.border.primary,
    background: theme.palette.background.primary,
    borderRadius: "8px",
    padding: "12px 14px",
    fontSize: "16px",
    lineHeight: "24px",
    resize: "none",
    color: theme.palette.text.primary,
    fontWeight: 500,
    "&::placeholder": {
      color: theme.palette.text.placeholder,
      fontWeight: 400,
    },
  },
  customSelect: {
    width: "100%",
  },
}));
