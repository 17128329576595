import React from "react";
import PropTypes from "prop-types";
import { TextareaAutosize } from "@material-ui/core";

//styles
import useStyles from "./styles";

const CustomTextArea = ({ maxRows, minRows, placeholder, onInputChange }) => {
  const styles = useStyles();

  const handleInputChange = (event) => {
    if (onInputChange) {
      onInputChange(event.target.value);
    }
  };

  return (
    <TextareaAutosize
      maxRows={maxRows}
      minRows={minRows}
      placeholder={placeholder}
      className={styles.textAreaInput}
      onChange={handleInputChange}
    />
  );
};

CustomTextArea.propTypes = {
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func,
};

CustomTextArea.defaultProps = {
  maxRows: 3,
  minRows: 1,
  placeholder: "Type your text here...",
  onInputChange: ()=>{},
};

export default CustomTextArea;