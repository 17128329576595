export const PropertyManagementListPermissions = {
  ViewandsearchinPropertyManagementProperties: {
    permissionsId: 'b5bf8291-1d44-4513-c9cb-08d94f66fc61',
    permissionsName: 'View and search in  Property Management Properties ',
    componentsId: null,
    components: null,
  },
  ViewContacts: {
    components: null,
    componentsId: null,
    permissionsId: '0049d9e9-2c19-4ecc-9300-08d967db92be',
    permissionsName: 'View Contacts',
  },
  ViewPropertyImageLocation: {
    components: null,
    componentsId: null,
    permissionsId: '44ac4698-fc19-4350-9301-08d967db92be',
    permissionsName: 'view property image location',
  },
  ViewAllCompanyFinance: {
    components: null,
    componentsId: null,
    permissionsId: '920228df-4165-48e5-9303-08d967db92be',
    permissionsName: 'View all company finance',
  },
  AddNewCompanyFinance: {
    components: null,
    componentsId: null,
    permissionsId: '1ed13f16-e0db-44b8-9304-08d967db92be',
    permissionsName: 'add new company finance',
  },
  ViewPropertyMarketingDetails: {
    components: null,
    componentsId: null,
    permissionsId: '8597f450-b1ab-4400-9305-08d967db92be',
    permissionsName: 'View property marketing details',
  },

  ViewOperatingCostDetails: {
    components: null,
    componentsId: null,
    permissionsId: '91ce750b-eacc-4e09-9306-08d967db92be',
    permissionsName: 'View Operating Cost Details',
  },

  ViewAllPortolios: {
    components: null,
    componentsId: null,
    permissionsId: '73073118-a274-42ef-9307-08d967db92be',
    permissionsName: 'View All portolios',
  },

  ViewAllContact: {
    components: null,
    componentsId: null,
    permissionsId: '0c003367-cce2-4003-9308-08d967db92be',
    permissionsName: 'view all contact',
  },
  AddNewOperatingCost: {
    components: null,
    componentsId: null,
    permissionsId: '793f875e-2018-4136-9309-08d967db92be',
    permissionsName: 'add new operating cost',
  },
  viewContacts: {
    components: null,
    componentsId: null,
    permissionsId: 'f052a0d1-606d-4a1d-930a-08d967db92be',
    permissionsName: 'view contacts',
  },

  AddNewProperty: {
    components: null,
    componentsId: null,
    permissionsId: '5982153e-dffb-4cb0-930b-08d967db92be',
    permissionsName: 'add new property',
  },
  UploadProperty: {
    components: null,
    componentsId: null,
    permissionsId: 'bd4c94bc-e4d0-4d73-930c-08d967db92be',
    permissionsName: 'upload property',
  },

  ImportProperty: {
    components: null,
    componentsId: null,
    permissionsId: 'a3ff5c79-8a56-4442-930d-08d967db92be',
    permissionsName: 'Import property',
  },
  ViewPropertySpecificationDetails: {
    permissionsId: '91ae4d33-1c94-4ac5-0d55-08d96c602a6b',
    permissionsName: 'View property Specification details',
    componentsId: null,
    components: null,
  },
  ViewLatitudeAndLongitudeforProperty: {
    permissionsId: '1aded475-1c23-41f6-0d56-08d96c602a6b',
    permissionsName: 'View Latitude And Longitude for property',
    componentsId: null,
    components: null,
  },
  UpdateProperty: {
    permissionsId: '4ab6b7db-3cc3-42a6-0d57-08d96c602a6b',
    permissionsName: 'update property',
    componentsId: null,
    components: null,
  },
  ViewPropertyDetails: {
    components: null,
    componentsId: null,
    permissionsId: '5aa3b88b-c6cd-4529-0d53-08d96c602a6b',
    permissionsName: 'View property details',
  },
  ViewPropertyMPI: {
    permissionsId: '40041fb6-ec55-49ef-f469-08dc3da1d8ed',
    permissionsName: 'View Property MPI',
    description: null,
    componentsId: null,
    components: null,
  },
  AddPropertyPaymentPlan: {
    permissionsId: 'cc6b59e7-fffb-4eca-2e51-08db604be055',
    permissionsName: 'Add Property Payment Plan',
    description: null,
    componentsId: null,
    components: null,
  },
  EditPropertyPaymentPlan: {
    permissionsId: '43e6022a-1f54-4435-2e52-08db604be055',
    permissionsName: 'Edit Property Payment Plan',
    description: null,
    componentsId: null,
    components: null,
  },
  DeletePropertyPaymentPlan: {
    permissionsId: 'f543d6de-e931-4fe4-2e53-08db604be055',
    permissionsName: 'Delete Property Payment Plan',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewPropertyPaymentPlans: {
    permissionsId: '28f18b58-a6ae-4d70-703d-08dbf65badbd',
    permissionsName: 'View Property Payment Plans',
    description: null,
    componentsId: null,
    components: null,
  },
};
