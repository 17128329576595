import { TransactionsDetails, ContactDetails } from '../Sections';

export const LeasingTransactionsDetailsTabs = [
  {
    label: 'transaction-details',
    component: TransactionsDetails,
  },
  {
    label: 'contact-details',
    component: ContactDetails,
  },
];
