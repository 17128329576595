import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  SelectComponet,
} from "../../../../../Components";
import { GetAllFormFieldsByFormId } from "../../../../../Services";
import { FormsIdsEnum } from "../../../../../Enums";
import { AddCondationLeadForm } from "./AddCondationLeadForm/AddCondationLeadForm";


export const CreateLeadForm = ({LeadFormData, setLeadFormData}) => {
  const parentTranslationPath = "WorkFlow";
  const translationPath = "";

  // const [inputList, setInputList] = useState([
  //   { formId: null, searchKey: null, value: "", operator: null, operand: null },
  // ]);

  // const [stateForm, setStateForm] = useState([]);

  // // Fetch API data for multiple IDs (68, 69) 
  // const fetchFormFields = useCallback(async () => {
  //   const ids = [31];
  //   const promises = ids.map((id) => GetAllFormFieldsByFormId(id));
  //   const results = await Promise.all(promises);

  //   const formattedResults = results.map((result, index) => ({
  //     id: ids[index],
  //     result: result || [],
  //   }));

  //   setStateForm(formattedResults);
  // }, []);

  // useEffect(() => {
  //   fetchFormFields();
  // }, [fetchFormFields]);



  return (
    <div className="FilterSectionView-view-wrapers">
      <div className="mian-FilterSectionView">
         <AddCondationLeadForm  LeadFormData={LeadFormData} setLeadFormData={setLeadFormData} />
      </div>
    </div>
  );
};
