import React from 'react';

function LocationIcon({ fill, restProps }) {
  return (
    <svg
      viewBox='0 0 20 20'
      width='20'
      height='20'
      fill={fill ?? ''}
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <path
        d='M9.433.687c-1.575.145-3.048.725-4.216 1.661-.319.256-.91.845-1.173 1.169-.852 1.05-1.418 2.351-1.632 3.75-.071.462-.079 1.498-.015 1.95.265 1.871 1.184 3.667 2.913 5.687.668.781 1.192 1.333 2.689 2.833C9.59 19.332 9.568 19.315 10 19.315c.432 0 .41.017 2.001-1.578 1.497-1.5 2.021-2.052 2.689-2.833 1.734-2.027 2.667-3.854 2.913-5.704.064-.474.056-1.472-.015-1.933-.213-1.397-.78-2.7-1.632-3.75a10.44 10.44 0 0 0-1.173-1.169A7.884 7.884 0 0 0 11.1.751C10.757.699 9.73.66 9.433.687m1.518 2.058a5.47 5.47 0 0 1 1.649.56c.537.276.953.582 1.4 1.029.865.865 1.383 1.876 1.605 3.133.027.151.043.494.042.866-.001.508-.014.678-.076.963-.357 1.661-1.31 3.201-3.295 5.321-.68.725-2.234 2.283-2.278 2.283-.044 0-1.584-1.547-2.274-2.283-2.468-2.637-3.472-4.577-3.38-6.534.07-1.476.634-2.74 1.688-3.783.849-.839 1.96-1.4 3.101-1.564l.35-.05c.206-.029 1.21.012 1.468.059m-1.49 2.14a3.485 3.485 0 0 0-1.926.984 3.477 3.477 0 0 0 0 4.929 3.48 3.48 0 0 0 4.93 0 3.478 3.478 0 0 0 0-4.929 3.444 3.444 0 0 0-1.957-.986 3.093 3.093 0 0 0-1.047.002m.922 1.994c.25.066.442.176.643.366.31.293.474.668.474 1.085 0 .399-.135.737-.412 1.029a1.46 1.46 0 0 1-1.083.474 1.41 1.41 0 0 1-1.04-.421 1.475 1.475 0 0 1 0-2.158c.305-.291.603-.413 1.018-.417.129-.002.309.017.4.042'
        fill-rule='evenodd'
        fill={fill ?? ''}
      />
    </svg>
  );
}

export default LocationIcon;
