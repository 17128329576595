import React, { useEffect, useMemo, useRef, useState } from 'react';
import LabeledField from '../../InquiryRotationView/InquirySchemeManagement/components/LabeledField';
import { DatePickerComponent, Inputs } from '../../../../Components';
import { Button, FormControlLabel } from '@material-ui/core';
import IOSSwitch from '../../InquiryRotationView/InquirySchemeManagement/components/IOSSwitch';
import { LookupSelect } from '../../ActivitiesTypeView/ActivitiesTypeManagementView/DialogManagementViewComponent/DialogManagementConrtolComponent/LookupSelect';
import { useTranslation } from 'react-i18next';
import { PaymentPlansLocalePath } from '../i18n/PaymentPlansLocale';
import { Add } from '@material-ui/icons';
import { GetLookupItemsByLookupTypeName } from '../../../../Services';
import {
  generateUniqueKey,
  getErrorByName,
  showError,
} from '../../../../Helper';
import { PropertyPaymentPlan } from '../../../../Enums';
import moment from 'moment';
import Joi from 'joi';
import { config } from '../../../../config';

const initialInstallment = (frequencyId) => ({
  installmentTypeId: config.InstallmentTypes.PostHandoverInstallment,
  amountPercentage: '',
  frequencyId,
  frequencyValue: '',
  id: crypto.randomUUID(),
});
const PostInstallmentItem = ({ dispatch }) => {
  const { t } = useTranslation(PaymentPlansLocalePath);
  const [installment, setInstallment] = useState(initialInstallment);
  const [frequencies, setFrequencies] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [key, setKey] = useState(generateUniqueKey);
  const onFrequencyToggleHandler = (e) => {
    e.persist();
    setInstallment((prevInstallment) => ({
      ...prevInstallment,
      frequencyId: +e.target.value,
    }));
  };
  const dateFrequencyId = useMemo(
    () =>
      frequencies.find(
        (frequency) => frequency.lookupItemName.toLowerCase() === 'date'
      )?.lookupItemId,
    [frequencies]
  );
  const monthFrequencyId = useMemo(
    () =>
      frequencies.find(
        (frequency) => frequency.lookupItemName.toLowerCase() === 'month'
      )?.lookupItemId,
    [frequencies]
  );
  const yearFrequencyId = useMemo(
    () =>
      frequencies.find(
        (frequency) => frequency.lookupItemName.toLowerCase() === 'year'
      )?.lookupItemId,
    [frequencies]
  );

  const isDuration = useMemo(
    () => [yearFrequencyId, monthFrequencyId].includes(installment.frequencyId),
    [frequencies, yearFrequencyId, monthFrequencyId, installment.frequencyId]
  );

  const updateInstallmentHandler = (field, value) => {
    setInstallment((prevInstallment) => ({
      ...prevInstallment,
      [field]: value,
    }));
  };
  const installmentSchema = Joi.object({
    installmentTypeId: Joi.number().required(),
    amountPercentage: Joi.number()
      .required()
      .min(1)
      .max(99)
      .messages({
        'number.min': t('installmentAmountPercentageMin'),
        'number.max': t('installmentAmountPercentageMax'),
        'number.base': t('is-required', {
          field: t('amount_percentage'),
          ns: 'InquiryRotation',
        }),
      }),
    frequencyId: Joi.number().required(),
    frequencyValue: Joi.string()
      .required()
      .custom((value, helper) => {
        if (+value) {
          if (!Number.isInteger(+value)) {
            return helper.error('string.decimal');
          } else if (+value < 1) {
            return helper.error('string.negative');
          }
        }
      })
      .messages({
        'string.required': t('is-required', {
          field: t('duration'),
          ns: 'InquiryRotation',
        }),
        'string.base': t('is-required', {
          field: t('duration'),
          ns: 'InquiryRotation',
        }),
        'string.empty': t('is-required', {
          field: t('duration'),
          ns: 'InquiryRotation',
        }),
        'string.negative': t('valueMustBePositive'),
        'string.decimal': t('string_decimal'),
      }),
  }).validate(installment, { allowUnknown: true, abortEarly: false });

  const onAddHandler = () => {
    setIsSubmitted(true);
    if (installmentSchema.error) {
      return;
    }
    installment.amountPercentage = +installment.amountPercentage / 100;
    dispatch({ field: 'postHandoverInstallment', value: installment });
    setInstallment(initialInstallment(monthFrequencyId));
    setIsSubmitted(false);
    setKey(generateUniqueKey());
  };

  useEffect(() => {
    const getFrequencies = async () => {
      try {
        const res = await GetLookupItemsByLookupTypeName({
          lookUpName: PropertyPaymentPlan.Frequency.lookupTypeName,
          pageIndex: 1,
          pageSize: 100,
        });
        updateInstallmentHandler(
          'frequencyId',
          res.result.find(
            (item) => item.lookupItemName.toLowerCase() === 'month'
          )?.lookupItemId
        );
        setFrequencies(res.result);
      } catch (error) {
        showError('error');
      }
    };
    getFrequencies();
  }, []);

  return (
    <div key={key}>
      <LabeledField
        className='gap-32'
        label={t('post_handover_installment')}
        component={
          <Inputs
            startAdornment='%'
            inputPlaceholder={'(e.g., 25%)'}
            onInputChanged={(e) =>
              updateInstallmentHandler('amountPercentage', e.target.value)
            }
            value={installment.amountPercentage}
            isWithError
            helperText={
              getErrorByName(installmentSchema, 'amountPercentage').message
            }
            error={getErrorByName(installmentSchema, 'amountPercentage').error}
            isSubmitted={isSubmitted}
          />
        }
      />
      <LabeledField
        className='gap-32'
        label={t('frequency')}
        component={
          <div className='flex gap-12 flex-col fa-start px-3'>
            <FormControlLabel
              control={<IOSSwitch />}
              label={t('by_duration')}
              labelPlacement='end'
              value={monthFrequencyId}
              checked={[monthFrequencyId, yearFrequencyId].includes(
                installment.frequencyId
              )}
              onChange={onFrequencyToggleHandler}
            />
            <FormControlLabel
              control={<IOSSwitch />}
              label={t('by_specific_date')}
              value={dateFrequencyId}
              labelPlacement='end'
              checked={installment.frequencyId === dateFrequencyId}
              onChange={onFrequencyToggleHandler}
            />
          </div>
        }
      />
      {isDuration && (
        <LabeledField
          className='gap-32'
          label={t('by_duration')}
          component={
            <div className='flex- flex-col gap-6'>
              <Inputs
                type={'number'}
                startAdornment={
                  <LookupSelect
                    lookupName={'frequency'}
                    className={'m-0'}
                    onSelectChanged={(value) =>
                      updateInstallmentHandler('frequencyId', value)
                    }
                    excludeLookupByName={['date', 'day', 'completion']}
                    value={installment.frequencyId}
                    renderValue={(_value, item) => (
                      <strong>{item?.lookupItemName}</strong>
                    )}
                  />
                }
                inputPlaceholder={'(e.g., 12 months)'}
                value={installment.frequencyValue}
                onInputChanged={(e) =>
                  updateInstallmentHandler('frequencyValue', e.target.value)
                }
                isWithError
                helperText={
                  isSubmitted &&
                  getErrorByName(installmentSchema, 'frequencyValue').message
                }
                error={
                  isSubmitted &&
                  getErrorByName(installmentSchema, 'frequencyValue').error
                }
                isSubmitted={isSubmitted}
              />
              <span className='helper-text'>{t('select_timeframe')}</span>
            </div>
          }
        />
      )}
      {!isDuration && (
        <LabeledField
          className='gap-32'
          label={t('by_specific_date')}
          component={
            <div className='flex- flex-col gap-6'>
              <DatePickerComponent
                onDateChanged={(e) =>
                  updateInstallmentHandler(
                    'frequencyValue',
                    new Date(e._d).toLocaleDateString()
                  )
                }
                value={moment(installment.frequencyValue)}
                helperText={
                  getErrorByName(installmentSchema, 'frequencyValue').message
                }
                error={
                  getErrorByName(installmentSchema, 'frequencyValue').error
                }
                isSubmitted={isSubmitted}
              />
              <span className='helper-text'>
                {t('select_installment_date')}
              </span>
            </div>
          }
        />
      )}
      <LabeledField
        hideDivider
        className='gap-32'
        component={
          <Button className=' capitalize flex gap-2' onClick={onAddHandler}>
            {' '}
            <Add />
            <span>{t('add_another')}</span>
          </Button>
        }
      />
    </div>
  );
};

export default PostInstallmentItem;
