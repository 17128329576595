import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
  },
  inputContainer: {
    minHeight: "44px",
    display: "flex",
    width: "100%",
    maxWidth: "400px",
    flexShrink: 0,
    padding: "9px 14px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: theme.palette.background.paper,
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
    border: `1px solid ${theme.palette.border.primary}`,

    "& input": {
      border: "none",
      boxShadow: "none",
      outline: "none",
      fontSize: "16px",
      width: "100%",
      backgroundColor: "transparent",
      lineHeight: "20px",
      color: theme.palette.text.primary,
      fontWeight: 500,

      "&::placeholder": {
        color: theme.palette.text.placeholder,
        fontWeight: 400,
      },
    },
  },
  inputContainerFocused: {
    boxShadow: theme.shadows[8],
    border: `1px solid ${theme.palette.border.brand}`,
  },
  inputContainerNoBorder: {
    border: "none",
    boxShadow: "none",
    padding: 0,
  },
  inputContainerError: {
    border: ` 1px solid ${theme.palette.border.error}`,
    boxShadow: theme.shadows[1],
  },
  errorMessage: {
    color: theme.palette.text.error_primary,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
}));