import React from "react";
import { CustomButton, CustomDialog } from "../../../../../../Components";
import { useSelectedTheme, useTranslate } from "../../../../../../Hooks";
import { Download01 } from "../../../../../../assets/icons";
import { CustomDoucomentViewer } from "..";
import { Box } from "@material-ui/core";
import { getDownloadableLink } from "../../../../../../Helper";

//styles
import useStyles from "./styles";

function ContactPreviewDocumentsDialog({ OnOpen, onClose, documentItem }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");
  const {
    theme: { palette },
  } = useSelectedTheme();

  const downloadDocument = (item) => {
    if (!item) {
      return;
    }
    try {
      const link = document.createElement("a");
      link.setAttribute("download", item.documentName);
      link.href = getDownloadableLink(item.documentId);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  return (
    <CustomDialog
      open={OnOpen}
      onClose={() => {
        onClose();
      }}
      title={translate("Preview-attachment")}
      subtitle={translate("View-and-edit-attach-files")}
      cancelText={translate("Discard")}
      width={"1024px"}
      content={
        <Box className={styles.containerDoc}>
          <CustomDoucomentViewer
            documentUrl={getDownloadableLink(documentItem?.documentId)}
            width={"100%"}
            height={"100%"}
            document={documentItem}
          />
        </Box>
      }
      moreAction={
        <CustomButton
          boxShadow="none"
          size="lg"
          variant="text"
          color="tertiaryGray"
          startIcon={
            <Download01
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          }
          onClick={() => {
            downloadDocument(documentItem);
          }}
          style={{ marginInlineEnd: "auto" }}
        >
          {translate("Download")}
        </CustomButton>
      }
    />
  );
}
export default ContactPreviewDocumentsDialog;
