export const LeadsStatusEnum = {
  lookupTypeId: 33,
  Open: {
    key: 'Open',
    value: 'open',
    status: 457,
  },
  Closed: {
    key: 'Closed',
    value: 'closed',
    status: 458,
  },


};

export const LeadStatusEnum = {
  Open: {
    value: 'open',
    key: 457,
  },
  Closed: {
    value: 'closed',
    key: 458,
  },

  
};
