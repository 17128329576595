import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase, DialogActions } from '@material-ui/core';
import { CheckboxesComponent, DialogComponent, Spinner } from '../../../../../../Components';
import { GetAllUnitLeadOwners, RunSystemTemplateForUnit } from '../../../../../../Services';
import { GetParams, getDownloadableLink, showError } from '../../../../../../Helper';
import { config } from '../../../../../../config';
import { ShareTemplatesDialog } from '../../../../../../SharedComponents/ShareTemplatesDialog/ShareTemplatesDialog';
export const UnitTemplateDialogPreviewDialog = ({
  activeItem,
  isOpen,
  isOpenChanged,
  parentTranslationPath,
  translationPath,
  maintitleText,
  unitID,
  unitData,
  unitIDsList,
  unitOwner,
  leaseUnitOwner,
  isPreviewOnly,
  inquiryId,
  isLease,
 }) => {
  const { t } = useTranslation('UnitsProfileManagementView');
  const maxHeight = '137px';
  const mediaprint = '@page {margin: 2mm;}@media print {.page-header,.page-header-space {height: 137px;max-height: 137px;}.page-footer,.page-footer-space {height: 137px;max-height: 137px;}.page-footer {position: fixed;bottom: 0;width: 100%;}.page-header {position: fixed;top: 0mm;width: 100%;}.page {page-break-after: always;}thead {display: table-header-group;}tfoot {display: table-footer-group;}button {display: none;}body {margin: 0;}}';
  /// / old style const mediaprint = '@media print {footer {position: fixed !important;bottom: 0 !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: 1cm 1cm 1cm 1cm !important;}}@media print {header {position: fixed !important;top: 0 !important;left: 0 !important;right: 0 !important;text-align:center !important;width: 100% !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: .5cm !important;}}';
  const state =
  {
    templateId: activeItem || config.MultiUnitsTemplateId,
    unitId: unitID || null,
    unitIds: unitIDsList || null,
    leadId: null,
    contactId: null,
    activityId: null,
    userId: null
  };
  const [Content, setContent] = useState('');
  const [HeaderAndFooter, setHeaderAndFooter] = useState(
    {
      documentsFooterImageId: null,
      documentsHeaderImageId: null
    }
  );
  const [isCheckedHeader, setIsCheckedHeader] = useState(false);
  const [isCheckedfooter, setIsCheckedfooter] = useState(false);
  const [leaseLeadOwner, setLeaseLeadOwner] = useState(false);
  const [leadOwner, setLeadOwner] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [withsize, setwithsize] = useState('lg');
  const [IsLoadingRunSystemTemplate, setIsLoadingRunSystemTemplate] = useState(false);

  const onSizeClickedFun = useCallback(async (size) => {
    if (size === 'lg')
      setwithsize('xl');
    else setwithsize('lg');
  }, []);

  const RunSystemTemplateHtmlFis = useCallback(async (files, check) => {
    setIsLoadingRunSystemTemplate(true);
    const res = await RunSystemTemplateForUnit(files);

    if (!(res && res.status && res.status !== 200)) {
      setContent(res && res.templateContent);
      if (!check) {
      setHeaderAndFooter({
        documentsFooterImageId: (res && res.documentsFooterImageId) || null,
        documentsHeaderImageId: (res && res.documentsHeaderImageId) || null,
      });
    }
      const localFile = new File([res.templateContent], `${'malek'}.pdf` || 'unit-template-file.pdf', {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(localFile);
      setFileUrl(url);
      const content = document.getElementById('divcontents');
      const pri = document.getElementById('ifmcontentstoprint').contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
    } else showError(t(`${translationPath}RunSystemTemplate-failed`));

    setIsLoadingRunSystemTemplate(false);
  }, []);
  const getUnitLeadOwner = useCallback(async (id) => {
    const res = await GetAllUnitLeadOwners(id);
    if (!(res && res.status && res.status !== 200)) {
      setLeadOwner(res.leadOwner);
      setLeaseLeadOwner(res.leaseLeadOwner);
    } else {
      setLeadOwner(null);
      setLeaseLeadOwner(null);
    }
  }, []);
  const printPartOfPage = async () => {
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.close();
    pri.focus();
    pri.print();
  };
  useEffect(() => {
    getUnitLeadOwner(unitID);
  }, []);
  useEffect(() => {
    RunSystemTemplateHtmlFis(state);
  }, []);

  return (
    <DialogComponent
      titleText={t(maintitleText,{ns:'TemplatesView'})}
      maxWidth={withsize}
      reSize
      dialogContent={(
        <div className='templates-preview-dialog'>
          <div style={{ position: 'relative' }}>
            <Spinner isActive={IsLoadingRunSystemTemplate} />
            <div className='PrintorsavePDF'>
              {/* <ButtonBase
                className='btns theme-outline mb-2 '
              >
                <span className='mdi mdi-printer' />
                {t(`${translationPath}Printorsave`)}
                <span className='mdi mdi-file-pdf-box' />
              </ButtonBase> */}
            </div>
            <div id='divcontents' style={{ display: 'none' }}>
              {/* {HeaderAndFooter.documentsHeaderImageId !== null && (
                <header className='header'>
                  <img alt='HeaderPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsHeaderImageId) || null} style={{ width: '100%', maxHeight }} />
                </header>
              )} */}
              <style>
                {mediaprint}
              </style>
              {isCheckedHeader && HeaderAndFooter.documentsHeaderImageId !== null && (
                <div className='page-header'>
                  <img alt='FooterPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsHeaderImageId) || null} style={{ width: '100%', maxHeight }} />
                </div>
              )}

              <table>

                {isCheckedHeader && HeaderAndFooter.documentsHeaderImageId !== null && (
                  <thead>
                    <tr>
                      <td>
                        <div className='page-header-space' />
                      </td>
                    </tr>
                  </thead>
                )}

                <tbody>
                  <tr>
                    <td>
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: Content }}
                      />
                    </td>
                  </tr>
                </tbody>
                {isCheckedfooter && HeaderAndFooter.documentsFooterImageId !== null && (
                  <tfoot>
                    <tr>
                      <td>
                        <div className='page-footer-space' />
                      </td>
                    </tr>
                  </tfoot>
                )}

              </table>
              {isCheckedfooter && HeaderAndFooter.documentsFooterImageId !== null && (
                <div className='page-footer'>
                  <img alt='FooterPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsFooterImageId) || null} style={{ width: '100%', maxHeight }} />
                </div>

              )}

              {/* {HeaderAndFooter.documentsFooterImageId !== null && (
                <footer>
                  <img alt='FooterPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsFooterImageId) || null} style={{ width: '100%', maxHeight }} />
                </footer>
              )} */}
            </div>
            <iframe
              id='ifmcontentstoprint'
              title='ifmcontentstoprint'
              style={{ minHeight: 500 }}
              width='100%'
              height='100%'
            />
          </div>
          <div className='print-icon-wwww'>
              <ButtonBase
              className='print-button'
              onClick={() => {
                printPartOfPage();
              }}
            >
              <span className='print-icon' />
            </ButtonBase> 
          </div>
          <DialogActions>
            <div className='unit-template-action-btns'>
            {!isPreviewOnly? <CheckboxesComponent
                idRef='isCheckedHeader'
                translationPathForData={translationPath}
                translationPath={translationPath}
                label='With Header'
                singleChecked={isCheckedHeader}
                onSelectedCheckboxClicked={() => {
                  setIsCheckedHeader(!isCheckedHeader);
                  RunSystemTemplateHtmlFis(state, true);
                }}
              />: null}
              {!isPreviewOnly? <CheckboxesComponent
                idRef='isCheckedfooter'
                translationPathForData={translationPath}
                translationPath={translationPath}
                label='With footer'
                singleChecked={isCheckedfooter}
                onSelectedCheckboxClicked={() => {
                  setIsCheckedfooter(!isCheckedfooter);
                  RunSystemTemplateHtmlFis(state, true);
                }}
              />: null}
            </div>
            <div className='unit-template-action-btns'>
            {!isPreviewOnly? <ButtonBase
                className='MuiButtonBase-root btns bg-share'
                onClick={() => setIsShareDialogOpen(true)}
              >
                {t(`${translationPath}share-template`)}
                <span className='mdi mdi-share-variant' />
              </ButtonBase>: null}
            {!isPreviewOnly?<ButtonBase
                className='MuiButtonBase-root btns theme-solid '
                onClick={() => RunSystemTemplateHtmlFis(state)}
              >
                {t(`${translationPath}view-template`)}
              </ButtonBase>: null}
            </div>
          </DialogActions>

          {isShareDialogOpen ?
            <ShareTemplatesDialog
              unitId={unitID || null}
              unitIds={unitIDsList || null}
              templateId={activeItem || config.MultiUnitsTemplateId}
              owners={{
                unitOwner,
                leaseUnitOwner,
                leadOwner,
                leaseLeadOwner,
              }}
              open={isShareDialogOpen}
              onSave={() => {
                setIsShareDialogOpen(false);
              }}
              close={() => setIsShareDialogOpen(false)}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              unitOperationType={unitData?.operationType}
              shareWithSeekerLeadsOnly
              inquiryId={inquiryId}
              isLease={isLease}
            /> : null
          }
        </div>
      )}
      // saveType='button'onSaveClicked={() => RunSystemTemplateHtmlFis(state)}      saveText='view template'
      isOpen={isOpen}
      onSizeClicked={() => onSizeClickedFun(withsize)}
      onCloseClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};

UnitTemplateDialogPreviewDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  maintitleText: PropTypes.string,
  unitID: PropTypes.number,
  unitData: PropTypes.object,
  isPreviewOnly:  PropTypes.bool
};
UnitTemplateDialogPreviewDialog.defaultProps = {
  activeItem: null,
  maintitleText: 'preview-template',
  unitID: +GetParams('id'),
  unitData: {},
  isPreviewOnly:  false,
};
