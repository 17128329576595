import React, { useCallback, useState } from "react";
import { Box } from "@material-ui/core";
import Joi from "joi";
import {
  CustomBadge,
  CustomBreadCrumb,
  CustomButton,
  CustomCard,
  CustomInput,
  PageTitle,
} from "../../../Components";
import { useSelectedTheme, useTranslate } from "../../../Hooks";
import { getErrorByName } from "../../../Helper";
import {
  atLeastOneNumber,
  charactersLength,
  containSpecialCharacter,
  containUpper,
} from "../../../Utils";
import { ChangePasswordAPI } from "../../../Services";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

// Styles
import useStyles from "./Styles";

//Icons
import { CheckCircleIcon, Key01Icon } from "../../../assets/icons";

export default function NewChangePasswordView() {
  const { translate } = useTranslate("HeaderView");
  const { setAlertBoxContent } = useVerticalNav();
  const styles = useStyles();

  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const fieldNames = ["oldPassword", "newPassword", "confirmPassword"];
  const [isLoading, setIsLoading] = useState(false);

  const {
    theme: { palette },
  } = useSelectedTheme();

  const schema = Joi.object({
    oldPassword:
      Joi.string().required().messages({
        "string.empty": translate("changePassWordView.password-is-required"),
      }) || Joi.any(),
    newPassword:
      Joi.string()
        .required()
        .regex(atLeastOneNumber)
        .regex(containSpecialCharacter)
        .regex(containUpper)
        .regex(charactersLength)
        .messages({
          "string.empty":  translate("changePassWordView.password-is-required"),
          "string.pattern.base": translate("changePassWordView.weak-password"),
        }) || Joi.any(),
    confirmPassword:
      Joi.string()
        .required()
        .valid(Joi.ref("newPassword"))
        .messages({
          "any.only": translate(`changePassWordView.password-not-matched`),
          "string.empty": translate(`changePassWordView.confirm-password-is-required`),
        }) || Joi.any(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const [isSubmitted, setIsSubmitted] = useState(false);



  const ChangePassword = useCallback(async () => {
    setIsLoading(true);
    const res = await ChangePasswordAPI(state);
    if (!(res && res.status && res.status !== 200)) {
      setAlertBoxContent({
        display: true,
        variant: "success",
        title: "Favorite Folder Created Successfully!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      setIsLoading(false);
    } else {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to Create Favorite Folder!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      setIsLoading(false);
    }
  });

  const handleChange = (field) => (e) => {
    setState((prev) => ({ ...prev, [field]: e.currentTarget.value }));
    setIsSubmitted((prev) => ({ ...prev, [field]: true }));
  };

  return (
    <Box>
      <CustomBreadCrumb
        breadcrumbs={[]}
        containerClasses={styles.breadCrumbContainer}
      >
        <CustomBadge
          Style={{
            padding: "4px 8px",
            borderRadius: "6px",
            lineHeight: "20px",
          }}
          label={"Change Password"}
          BackgroundColor={palette.breadcrumbs.brand_bg_hover}
          BorderColor={palette.breadcrumbs.brand_bg_hover}
          Color={palette.breadcrumbs.brand_fg_hover}
        />
      </CustomBreadCrumb>

      <PageTitle
        title={translate("changePassWordView.Change-password")}
        subTitle={translate("changePassWordView.change-password-subtitle")}
      ></PageTitle>

      <CustomCard>
        <Box className={styles.mainWrapper}>
          <Box className={styles.mainSection}>
            <Box className={styles.imageWrapper}></Box>
            <Box className={styles.mainContainer}>
              <Box className={styles.ChangePasswordWrapper}>
                <Box className={styles.formHeader}>
                  <Box className={styles.IconWrapper}>
                    <Key01Icon
                      width="28"
                      height="28"
                      fill={palette.foreground.secondary}
                    />
                  </Box>
                  <span className={styles.title}>
                    {translate("changePassWordView.change-password-title")}
                  </span>
                  <span className={styles.subTitle}>
                    {translate(
                      "changePassWordView.change-password-instructions"
                    )}
                  </span>
                </Box>

                <Box className={styles.formWrapper}>
                  <Box className={styles.formWrapper}>
                    <Box className={styles.formSectionFields}>
                      {fieldNames.map((field) => (
                        <Box className={styles.inputWrapper} key={field}>
                          <span className={styles.inputLabel}>
                            {translate(`changePassWordView.${field}`)}
                          </span>
                          <CustomInput
                            placeholder={translate(`changePassWordView.${field}_placeholder`)}
                            hasSearchIcon={false}
                            type="password"
                            inputContainerOverrideStyles={styles.inputField}
                            value={state[field]}
                            onChange={handleChange(field)}
                            hasError={getErrorByName(schema, field).error}
                            errorMessage={getErrorByName(schema, field).message}
                            submitted ={isSubmitted[field]}

                          />
                        </Box>
                      ))}
                    </Box>
                    <Box className={styles.checksWrapper}>
                      <Box className={styles.checkText}>
                        <CheckCircleIcon
                          width="20"
                          height="20"
                          fill={
                            atLeastOneNumber.test(state.newPassword)
                              ? palette.foreground.brandPrimary
                              : palette.foreground.senary
                          }
                        />
                        <span className={styles.helperText}>
                          {translate("changePassWordView.one-number")}
                        </span>
                      </Box>
                      <Box className={styles.checkText}>
                        <CheckCircleIcon
                          width="20"
                          height="20"
                          fill={
                            charactersLength.test(state.newPassword)
                              ? palette.foreground.brandPrimary
                              : palette.foreground.senary
                          }
                        />
                        <span className={styles.helperText}>
                          {translate("changePassWordView.8-20-characters")}
                        </span>
                      </Box>
                      <Box className={styles.checkText}>
                        <CheckCircleIcon
                          width="20"
                          height="20"
                          fill={
                            containUpper.test(state.newPassword)
                              ? palette.foreground.brandPrimary
                              : palette.foreground.senary
                          }
                        />
                        <span className={styles.helperText}>
                          {translate("changePassWordView.one-uppercase-letter")}
                        </span>
                      </Box>
                      <Box className={styles.checkText}>
                        <CheckCircleIcon
                          width="20"
                          height="20"
                          fill={
                            containSpecialCharacter.test(state.newPassword)
                              ? palette.foreground.brandPrimary
                              : palette.foreground.senary
                          }
                        />
                        <span className={styles.helperText}>
                          {translate("changePassWordView.special-character")}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={styles.formAction}>
                    <CustomButton
                      boxShadow="xs"
                      size="lg"
                      variant="contained"
                      color="primary"
                      style={{ width: "100%" }}
                      onClick={ChangePassword}
                      disabled={schema.error || isLoading}
                    >
                      {translate("changePassWordView.reset-password")}
                    </CustomButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomCard>
    </Box>
  );
}
