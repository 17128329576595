import React, { useCallback, useEffect, useState } from "react";
import {
  CustomAutocomplete,
  CustomDialog,
} from "../../../../../../../../Components";
import { Box, Typography, Divider } from "@material-ui/core";
import { useTranslate } from "../../../../../../../../Hooks";
import {
  lookupItemsGetId,
  OrganizationUserSearch,
} from "../../../../../../../../Services";
import { AgentRoleEnum } from "../../../../../../../../Enums";
import { useDebouncedAction } from "../../../../../../../../Hooks/DebouncedAction";

//styles
import useStyles from "./styles";

function ContactCloneLeadsDialog({
  isOpen,
  onClose,
  onSave,
  setIsLoading,
  totalCloneLeads,
  checkedCards,
  checkedCardsIds,
}) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");

  const [data, setData] = useState({
    campaignLanguages: [],
    mediaTypes: [],
    mediaDetails: [],
    users: [],
    referredBy: [],
    referredTo: [],
    methodOfContact: [],
    campaignTypes: [],
  });
  const [Dto, setDto] = useState({
    mediaDetailsId: "",
    methodOfContactId: "",
    referredBy: "",
    referredTo: "",
  });
  const [selectedValues, setSelectedVlaues] = useState({
    methodOfContact: null,
    referredBy: null,
    referredTo: null,
  });
  const [SelectedValue, setSelectedValue] = useState([]);

  const onSubmitHandler = () => {
    onSave({
        cloneLeadsData: checkedCardsIds.map((leadId) => ({
          leadId,
          ...Dto,
        })),
      });
  };
  const getAllMediaDetails = useCallback(async (mediaTypeId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: 1240,
      lookupParentId: mediaTypeId,
    });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, mediaDetails: res }));
    else setData((item) => ({ ...item, mediaDetails: [] }));
  }, []);

  const getAllMethodOfContact = async () => {
    const res = await lookupItemsGetId({ lookupTypeId: 35 });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, methodOfContact: res }));
    else setData((item) => ({ ...item, methodOfContact: [] }));
  };

  const getAllReferred = useCallback(async (searchValue) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2,
      userTypeId:
        checkedCards?.[0]?.leadClass.toLowerCase() === "buyer"
          ? AgentRoleEnum.SaleAgent.value
          : (checkedCards[0].leadClass.toLowerCase() === "tenant" &&
              AgentRoleEnum.LeaseAgent.value) ||
            "",
    });
    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, referredTo: res && res.result }));
    else setData((item) => ({ ...item, referredTo: [] }));
  }, []);

  function findObjectInArraySales(array) {
    return array.find(
      (item) =>
        item.lookupItemName === "Existing Client - Sales" ||
        item.lookupItemId === 57269
    );
  }
  function findObjectInArrayLeasing(array) {
    return array.find(
      (item) =>
        item.lookupItemName === "Existing Client - Leasing" ||
        item.lookupItemId === 57270
    );
  }
  const getAllReferredBy = useCallback(async (searchValue) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2,
    });

    if (!(res && res.status && res.status !== 200))
      setData((item) => ({ ...item, referredBy: res && res.result }));
    else setData((item) => ({ ...item, referredBy: [] }));
  }, []);

  const debouncedGetAllReferred = useDebouncedAction(getAllReferred, 700);
  const debouncedGetAllReferredBy = useDebouncedAction(getAllReferredBy, 700);

  useEffect(() => {
    getAllMediaDetails();
    getAllMethodOfContact();
    getAllReferred();
    getAllReferredBy();
  }, []);

  useEffect(() => {
    if (data?.mediaDetails?.length > 0) {
      if (
        checkedCards?.[0]?.leadClass.toLowerCase() === "buyer" ||
        checkedCards?.[0]?.leadClass.toLowerCase() === "seller"
      ) {
        const result = findObjectInArraySales(data.mediaDetails);
        setSelectedValue(result);
        setDto((item) => ({
          ...item,
          mediaDetailsId: (result && +result.lookupItemId) || "",
        }));
      } else if (
        checkedCards?.[0]?.leadClass.toLowerCase() === "tenant" ||
        checkedCards?.[0]?.leadClass.toLowerCase() === "landlord"
      ) {
        const result = findObjectInArrayLeasing(data.mediaDetails);
        setSelectedValue(result);
        setDto((item) => ({
          ...item,
          mediaDetailsId: (result && +result.lookupItemId) || "",
        }));
      }
    }
  }, [data.mediaDetails]);
  return (
    <CustomDialog
      open={isOpen}
      onClose={() => {
        onClose();
        setSelectedVlaues({
          methodOfContact: null,
          referredBy: null,
          referredTo: null,
        });
      }}
      title={translate("Clone-lead")}
      subtitle={translate("Are-you-sure-you-want-to-clone-these-leads-Total-number-of-leads")  +`  ${totalCloneLeads}`}
      onConfirm={() => {
        onSubmitHandler();
      }}
      width={"688px"}
      confirmText={translate("Submit")}
      cancelText={translate("Cancle")}
      isDisabled={!(Dto.methodOfContactId !== '' && Dto.referredBy !== '' && Dto.mediaDetailsId !== '')}
      content={
        <Box className={styles.containerFileds}>
          <Box className={styles.containerFiledItem}>
            <Box className={styles.sectionLable}>
              <Typography className={styles.lable}>
                {translate("Media-details*")}
              </Typography>
              <Typography className={styles.subLable}>
                {translate("Lead-source")}
              </Typography>
            </Box>
            <Box className={styles.containerAutocomplete}>
              <CustomAutocomplete
                placeholder={translate("Select")}
                onChange={(e, value) => {
                  setSelectedValue(value);
                  setDto((item) => ({
                    ...item,
                    mediaDetailsId: (value && +value.lookupItemId) || "",
                  }));
                }}
                options={data.mediaDetails || []}
                value={SelectedValue || null}
                getOptionLabel={(option) => option?.lookupItemName || ""}
                renderOption={(option) => option?.lookupItemName || ""}
              />
            </Box>
          </Box>

          <Divider className={styles.divider} />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.lable}>
              {translate("Method-of-contact*")}
            </Typography>

            <Box className={styles.containerAutocomplete}>
              <CustomAutocomplete
                variant="default"
                placeholder={translate("Select")}
                onChange={(e, value) => {
                  setDto((item) => ({
                    ...item,
                    methodOfContactId: +value?.lookupItemId || "",
                  }));
                  setSelectedVlaues((item) => ({
                    ...item,
                    methodOfContact: value || null,
                  }));
                }}

                options={data.methodOfContact || []}
                value={selectedValues?.methodOfContact || null}
                getOptionLabel={(option) => option?.lookupItemName || ""}
                renderOption={(option) => option?.lookupItemName || ""}
              />
            </Box>
          </Box>
          <Divider className={styles.divider} />
          <Box className={styles.containerFiledItem}>
            <Typography className={styles.lable}>
              {translate("Referred-by*")}
            </Typography>

            <Box className={styles.containerAutocomplete}>
              <CustomAutocomplete
                variant="default"
                placeholder={translate("Select")}
                onChange={(e, value) => {
                  setDto((item) => ({ ...item, referredBy: value?.id || "" }));
                  setSelectedVlaues((item) => ({
                    ...item,
                    referredBy: value || null,
                  }));
                }}
                onInputKeyUp={(e) => {
                    const value = e?.target?.value || null;
                    debouncedGetAllReferredBy(value);
                  }}
                options={data?.referredBy || []}
                value={selectedValues?.referredBy || null}
                getOptionLabel={(option) => option?.fullName || ""}
                renderOption={(option) => option?.fullName || ""}
              />
            </Box>
          </Box>
          <Divider className={styles.divider} />
          <Box className={styles.containerFiledItem}>
            <Typography className={styles.lable}>
              {translate("Assign-to-agent")}
            </Typography>

            <Box className={styles.containerAutocomplete}>
              <CustomAutocomplete
                variant="default"
                placeholder={translate("Select")}
                onChange={(e, value) => {
                  setDto((item) => ({ ...item, referredTo: value?.id || "" }));
                  setSelectedVlaues((item) => ({
                    ...item,
                    referredTo: value || null,
                  }));
                }}
                onInputKeyUp={(e) => {
                    const value = e?.target?.value || null;
                    debouncedGetAllReferred(value);
                  }}
                options={data?.referredTo || []}
                value={selectedValues?.referredTo || null}
                getOptionLabel={(option) => option?.fullName || ""}
                renderOption={(option) => option?.fullName || ""}
              />
            </Box>
          </Box>
        </Box>
      }
    />
  );
}
export default ContactCloneLeadsDialog;